/* Button component for all buttons on the site */

import React from "react";
import styles from "./Button.module.css";

const Button = ({ type = "header", specifyButton = null, name = "name", onClick }) => {
  // function scrollDown() {
  //     if (specifyButton === "home")
  //     {
  //         window.scrollTo({
  //             top: 0,
  //             behavior: "smooth",
  //         });
  //     }
  // };

  return (
    <div className={styles.border}>
      <a
        className={`${styles.customButton} ${styles[type]} ${styles[specifyButton]}`}
        href="mailto:support@lienlibrary.com">
        {name}
      </a>
    </div>
  );
};

export default Button;
