import React from "react";
import { List, Divider } from "semantic-ui-react";

//Styles
import styles from "./styles.module.css";

function ProfileDetailList({ user }) {
  const { name, email, phone, address, website } = user;

  return (
    <List style={{ width: "50%" }}>
      {name && (
        <>
          <List.Item
            icon="user"
            content={name}
            className={styles.listItem}
            style={{ fontSize: "1.1em", textTransform: "capitalize" }}
          />
          <Divider />
        </>
      )}
      {email && (
        <>
          <List.Item icon="mail" content={email} className={styles.listItem} style={{ fontSize: "1.1em" }} />
          <Divider />
        </>
      )}
      {phone && (
        <>
          <List.Item
            icon="phone"
            content={phone}
            className={styles.listItem}
            style={{ fontSize: "1.1em", textTransform: "capitalize" }}
          />
          <Divider />
        </>
      )}
      {address && (
        <>
          <List.Item
            icon="home"
            content={address}
            className={styles.listItem}
            style={{ fontSize: "1.1em", textTransform: "capitalize" }}
          />
          <Divider />
        </>
      )}
      {website && (
        <>
          <List.Item
            icon="world"
            content={website}
            className={styles.listItem}
            style={{ fontSize: "1.1em", textTransform: "capitalize" }}
          />
          <Divider />
        </>
      )}
    </List>
  );
}

export default ProfileDetailList;
