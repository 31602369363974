import { forwardRef } from "react";
import { Grid } from "semantic-ui-react";
import styles from "./body.module.css";
import small_blob1 from "../assets/Symbol.png";
import img1 from "../assets/Image.png";
import small_blob2 from "../assets/Symbol (1).png";
import img2 from "../assets/Image (1).png";
import small_blob3 from "../assets/Symbol (2).png";
import img3 from "../assets/Image (2).png";

const Body = forwardRef((props, ref) => {
  /*body panels*/

  // const handleClick = () => {
  //   ref.current?.scrollIntoView({ behavior: 'smooth' });
  // };

  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('');
  // const [number, setNumber] = useState('');
  // const [message, setMessage] = useState('');

  // const submit = () => {
  //   fetch('http://localhost:3000/submit', {
  //     method: 'POST', // or 'PUT'
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     mode: 'no-cors', // no-cors, *cors, same-origin
  //     body: JSON.stringify({
  //       data: {
  //         name: name,
  //         email: email,
  //         phone: number,
  //         message: message
  //       }
  //     })
  //   })

  //   console.log(name)
  //   console.log(email)
  //   console.log(number)
  //   console.log(message)

  //   setName('');
  //   setEmail('');
  //   setNumber('');
  //   setMessage('');
  // }

  return (
    <div className={styles.canvas}>
      <style>@import url('https://fonts.googleapis.com/css2?family=Rambla&display=swap');</style>

      <Grid centered className={styles.grid}>
        {/*panel 1*/}
        <div className={`${styles.rectangle} ${styles.rectangle1}`}>
          <img
            src={small_blob1}
            className={`${styles.small_blob} ${styles.left_small_blob}`}
            alt="small_blob1"
          />
          <img src={img1} className={`${styles.img1} ${styles.panelimg}`} alt="img1" />
          <p className={`${styles.subheader1} ${styles.subheader}`}>Municipal liens done easy</p>
          <p className={`${styles.paragraph} ${styles.paragraph1}`}>
            Government often gets overwhelmed by the work it takes to produce lien reports. Finish
            what would usually take days within just a few minutes.
          </p>
        </div>

        <div className={`${styles.divider} ${styles.divider1}`}></div>

        {/*panel 2*/}
        <div className={`${styles.rectangle} ${styles.rectangle2}`}>
          <img
            src={small_blob2}
            className={`${styles.small_blob} ${styles.right_small_blob}`}
            alt="blob2"
          />
          <img src={img2} className={`${styles.img2} ${styles.panelimg}`} alt="img2" />
          <p className={`${styles.subheader2} ${styles.subheader}`}>Start negotiations sooner</p>
          <p className={`${styles.paragraph} ${styles.paragraph2}`}>
            Being able to produce lien reports quickly also allows you to deliver faster to your
            clients and get negotiations underway.
          </p>
        </div>

        <div className={`${styles.divider} ${styles.divider2}`}></div>

        {/*panel 3*/}
        <div className={`${styles.rectangle} ${styles.rectangle3}`}>
          <img
            src={small_blob3}
            className={`${styles.small_blob} ${styles.left_small_blob}`}
            alt="small_blob3"
          />
          <img src={img3} className={`${styles.img3} ${styles.panelimg}`} alt="img3" />
          <p className={`${styles.subheader3} ${styles.subheader}`}>Digitize the process</p>
          <p className={`${styles.paragraph} ${styles.paragraph3}`}>
            Our software allow governments to process lien payments online, eliminating the
            old-school method of paying by check.
          </p>
          <a href="mailto:support@lienlibrary.com" className={styles.button}>
            Request Demo
          </a>
        </div>
        {/* <div className={styles.cover}></div> */}

        {/*request demo section*/}
        {/* <div className={styles.requestdemo} ref={ref}>
          <div className={styles.form}>
            <div className={styles.formHeader}>To schedule a product demo with one of our consultants,
              please fill in your contact details</div>
            <form>
              <input placeholder=" Name" required onChange={event => setName(event.target.value)} value={name} className={`${styles.formtext} ${styles.formName}`} />
              <input placeholder=" Email" required onChange={event => setEmail(event.target.value)} value={email} className={`${styles.formtext} ${styles.formEmail}`} />
              <input placeholder=" Phone Number" required onChange={event => setNumber(event.target.value)} value={number} className={`${styles.formtext} ${styles.formNumber}`} />
              <textarea placeholder=" Type your message here. . ." required onChange={event => setMessage(event.target.value)} value={message} className={`${styles.formtext} ${styles.formUserText}`} />
              <button className={styles.formButton} onClick={submit}>Submit</button>
            </form>
          </div>
        </div> */}
      </Grid>
      <p className={`${styles.paragraph} ${styles.paragraph4}`}>Designed by our Hercules Team</p>
    </div>
  );
});

export default Body;
