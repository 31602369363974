const headerLinks = {
  public: [
    {
      name: "Home",
      path: "/",
      icon: "home",
    },
    {
      name: "About Us",
      path: "/about-us",
      icon: "users",
    },
    {
      name: "Browse",
      path: "/browsev2",
      icon: "search",
    },
    // {
    //   name: "BrowseV2",
    //   path: "/browsev2",
    //   icon: "search",
    // },
    // {
    //   name: "Pricing",
    //   path: "/pricing",
    //   icon: "tags",
    // },
    {
      name: "For Government",
      path: "/for-government",
      icon: "building",
    },
    {
      name: "Login",
      path: "/login",
      icon: "sign-in",
    },
    {
      name: "Register",
      path: "/register",
      icon: "edit",
    },
  ],
  private: [
    {
      name: "Home",
      path: "/",
      icon: "home",
    },
    {
      name: "About Us",
      path: "/about-us",
      icon: "users",
    },
    {
      name: "Browse",
      path: "/browsev2",
      icon: "search",
    },
    // {
    //   name: "Pricing",
    //   path: "/pricing",
    //   icon: "tags",
    // },
    {
      name: "For Government",
      path: "/for-government",
      icon: "building",
    },
  ],
};

const footerLinks = [
  {
    name: "Terms and Conditions",
    path: "/terms-and-conditions",
  },
  {
    name: "Privacy Policy",
    path: "/privacy-policy",
  },
];

const dashboardLinks = [
  {
    name: "Profile",
    path: "/profile",
    icon: "user",
  },
  // {
  //   name: "My Properties",
  //   path: "/my-properties",
  //   icon: "home",
  // },
  {
    name: "Reports",
    path: "/reports",
    icon: "file alternate",
  },
  {
    name: "Mitigations",
    path: "/mitigations",
    icon: "eye",
  },
  {
    name: "Payments",
    path: "/payments",
    icon: "credit card",
  },
  {
    name: "Alarm Registration",
    path: "/alarm",
    icon: "bell",
  },
  // {
  //   name: "Favorites",
  //   path: "/favorites",
  //   icon: "heart",
  // },
  // {
  //   name: "Payment Info",
  //   path: "/payment-info",
  //   icon: "credit card",
  // },
  {
    name: "Cart",
    path: "/cart",
    icon: "cart",
  },
  {
    name: "Log out",
    path: "/logout",
    icon: "log out",
  },
];

export { headerLinks, footerLinks, dashboardLinks };
