import axios from "axios";
import config from "./config";

// axios default configs
const _Api = axios.create({
  baseURL: config.baseURL,
  headers: {
    common: {
      Authorization: "Bearer ",
      userId: "",
    },
  },
});

export default _Api;
