import React from "react";
import "./Footer.css";
// import logo from '../../assets/images/LienLibraryLogo.webp';
// import instagram from '../../FinalLimasAssets/Instagram.svg';
// import Facebook from '../../FinalLimasAssets/Facebook.svg';
// import Linkedln from '../../FinalLimasAssets/LinkedIn.svg';
// import TikTok from '../../FinalLimasAssets/TikTok.svg';
// import Twitter from '../../FinalLimasAssets/Twitter.svg';
import Footer from "../../../../components/Footer";

export const LimasFooter = ({ reqDemoRef, aboutUsRef }) => {
  // const reqDemHandleClick = () => {
  //   reqDemoRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };

  // const aboutUsHandleClick = () => {
  //     aboutUsRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };

  // const homeHandleClick = () => {
  //   window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //   });
  // };

  return (
    <div className="footer">
      <Footer />
    </div>
  );
};

export default LimasFooter;
