import React from "react";
import PropTypes from "prop-types";
import { Card, Image } from "semantic-ui-react";

export default function TeamMemberCard({ fullname, title, profile }) {
  return (
    <Card fluid>
      <Image src={profile} />
      <Card.Content>
        <Card.Header>{fullname}</Card.Header>
        <Card.Description>{title}</Card.Description>
      </Card.Content>
    </Card>
  );
}

TeamMemberCard.propTypes = {
  fullname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
};

TeamMemberCard.defaultProps = {
  fullname: null,
  title: null,
  profile: null,
};
