import {
  LOGIN,
  CHECK_SESSION,
  LOGOUT,
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  REHYDRATE,
} from "../actions/types";
import { filter } from "lodash";

export default function favorites(state = { favoriteList: [] }, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
      return { ...state, favoriteList: payload.data.user.favoritesProperties };

    case REHYDRATE:
      return { ...state, favoriteList: payload.data.user.favoritesProperties };

    case CHECK_SESSION:
      return { ...state, favoriteList: payload.data.favorites.favoriteList };

    case LOGOUT:
      return { ...state, favoriteList: [] };

    case ADD_FAVORITE:
      return { ...state, favoriteList: [...state.favoriteList, payload.data] };

    case REMOVE_FAVORITE:
      const array = filter(
        state.favoriteList,
        ({ propertyId }) => propertyId !== payload.data
      );
      return { ...state, favoriteList: array };

    default:
      return state;
  }
}
