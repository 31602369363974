import React from "react";

//Components
import Screen from "../../components/Screen";
import SearchBar from "../../components/SearchBar";
import Text from "../../components/Text";

//Styles
import styles from "./styles.module.css";

const Home = () => {
  return (
    <Screen
      withBackground
      fixedHeight
      meta="Find your dream home with a complete lien search from your local government using your title company and realtor">
      <div className={styles.banner}>
        <Text className={styles.text}>{"Get ready to experience our new website. Launching soon!"}</Text>
        <Text className={styles.bannerText}>{"Discover Liens On Your Property Today"}</Text>
        <Text className={styles.bannerText}>{"You Can Lien On Us"}</Text>
      </div>
      <div className={styles.searchBarContainer}>
        <SearchBar />
      </div>
    </Screen>
  );
};

export default Home;
