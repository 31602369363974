import React, { PureComponent } from "react";
import { Container } from "semantic-ui-react";
import { isEmpty, map } from "lodash";

//Redux
import { connect } from "react-redux";

//Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Dashboard from "../../components/Dashboard";
import FavoritesCard from "../../components/FavoritesCard";

//Styles
import styles from "./styles.module.css";

class Favorites extends PureComponent {
  renderFavorites = (array) => {
    if (!isEmpty(array)) {
      const favorites = map(array, (item, index) => {
        const stripped = index % 2 === 0;
        return <FavoritesCard key={index} item={item} stripped={stripped} />;
      });
      return favorites;
    } else {
      return null;
    }
  };

  render() {
    const { favoriteList } = this.props;
    return (
      <div className={styles.screen}>
        <Header searchBar={true} />
        <div className={styles.body}>
          <Container className={styles.container}>
            <Dashboard />
            <div className={styles.content}>{this.renderFavorites(favoriteList)}</div>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps({ favorites }) {
  return { favoriteList: favorites.favoriteList };
}

export default connect(mapStateToProps)(Favorites);
