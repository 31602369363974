// React
import React from "react";
import { useLocation } from "react-router-dom";
import { Grid, Checkbox } from "semantic-ui-react";
import { get } from "lodash";

//Redux
import { connect } from "react-redux";
//import { requestAlarm } from "../../redux/actions/alarms";

//Components
import Screen from "../../components/Screen";
import CityHeader from "../../components/CityHeader";
import Text from "../../components/Text";
import Box from "../../components/Box";
import AccordionBox from "../../components/AccordionBox";

// Utils
import { getDateLabel } from "../../utils/format";

//Styles
import styles from "./styles.module.css";

const AlarmRegistrationRequest = ({ dispatch, userId }) => {
  const location = useLocation();
  const property = get(location, "state", {});

  // useEffect(() => {
  //   dispatch(getAlarms(userId));
  // }, []);

  return (
    <Screen searchBar meta="Connect with your local government to register security alarms digitally">
      <div className={styles.content}>
        <div className={styles.document}>
          <CityHeader cityObj={property.city} />
          <Text className={styles.title}>Alarm Registration</Text>
          <Box title={`Order`}>
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column width={7} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Folio: "}</Text>
                    <Text className={styles.text}>{get(property, "folio", "")}</Text>
                  </div>
                </Grid.Column>
                <Grid.Column width={9} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Address: "}</Text>
                    <Text className={styles.addressText}>{get(property, "address", "")}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column width={16} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Legal Description: "}</Text>
                    <Text className={styles.text}>{get(property, "legalDescription", "")}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
              {/* <Grid.Row columns={3}>
                <Grid.Column width={6} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Permit #: "}</Text>
                    <Text className={styles.text}>{getDateLabel(currentReport.createdAt)}</Text>
                  </div>
                </Grid.Column>
                <Grid.Column width={5} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Issued On: "}</Text>
                    <Text className={styles.text}>{getDateLabel(get(currentReport, "user.name", ""))}</Text>
                  </div>
                </Grid.Column>
                <Grid.Column width={5} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Expired On: "}</Text>
                    <Text className={styles.text}>{getDateLabel(get(currentReport, "user.name", ""))}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row> */}
            </Grid>
          </Box>
          <AccordionBox title="Applicant / Permittee">
            <Grid stackable>
              <Grid.Row columns={3}>
                <Grid.Column width={6} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Name: "}</Text>
                    <Text className={styles.text}>{get(property, "", "")}</Text>
                  </div>
                </Grid.Column>
                <Grid.Column width={6} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Business Name: "}</Text>
                    <Text className={styles.text}>{get(property, "", "")}</Text>
                  </div>
                </Grid.Column>
                <Grid.Column width={4} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Role: "}</Text>
                    <Text className={styles.addressText}>{get(property, "", "")}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={6} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Property Type: "}</Text>
                    <Text className={styles.text}>{get(property, "", "")}</Text>
                  </div>
                </Grid.Column>
                <Grid.Column width={10} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Mailing Address: "}</Text>
                    <Text className={styles.text}>{get(property, "", "")}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </AccordionBox>
          <AccordionBox title="Security Alarm Information">
            <Grid stackable>
              <Grid.Row columns={3}>
                <Grid.Column width={5} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Service Company: "}</Text>
                    <Text className={styles.text}>{get(property, "", "")}</Text>
                  </div>
                </Grid.Column>
                <Grid.Column width={4} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Phone Number: "}</Text>
                    <Text className={styles.text}>{get(property, "", "")}</Text>
                  </div>
                </Grid.Column>
                <Grid.Column width={7} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Mailing Address: "}</Text>
                    <Text className={styles.addressText}>{get(property, "", "")}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3}>
                <Grid.Column width={5} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Alarm Monitoring Company: "}</Text>
                    <Text className={styles.text}>{get(property, "", "")}</Text>
                  </div>
                </Grid.Column>
                <Grid.Column width={4} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Phone Number: "}</Text>
                    <Text className={styles.text}>{get(property, "", "")}</Text>
                  </div>
                </Grid.Column>
                <Grid.Column width={7} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Mailing Address: "}</Text>
                    <Text className={styles.addressText}>{get(property, "", "")}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column width={16} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Date Installed: "}</Text>
                    <Text className={styles.text}>{getDateLabel(get(property, "", ""))}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column width={16} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Checkbox className={styles.boldText} label="Fire" />
                    <Text className={styles.text}>{getDateLabel(get(property, "", ""))}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column width={16} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Checkbox className={styles.boldText} label="Burglar" />
                    <Text className={styles.text}>{getDateLabel(get(property, "", ""))}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column width={16} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Checkbox className={styles.boldText} label="Robbery / Panic" />
                    <Text className={styles.text}>{getDateLabel(get(property, "", ""))}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column width={16} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Checkbox className={styles.boldText} label="Duress" />
                    <Text className={styles.text}>{getDateLabel(get(property, "", ""))}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column width={16} className={styles.gridColumn}>
                  <div className={styles.textRow}>
                    <Checkbox className={styles.boldText} label="Medical" />
                    <Text className={styles.text}>{getDateLabel(get(property, "", ""))}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </AccordionBox>
        </div>
      </div>
    </Screen>
  );
};

function mapStateToProps({ user }) {
  return { userId: user.id };
}

export default connect(mapStateToProps)(AlarmRegistrationRequest);
