import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import { API_FAILURE, ADD_CART, REMOVE_CART, PAY_CART } from "./types";

function requestSuccess(type, data = null) {
  return { type, payload: { data } };
}

function requestFail(errorCode) {
  return { type: API_FAILURE, payload: { data: errorCode } };
}

export function addCart(obj) {
  console.log(obj);
  return (dispatch) => {
    dispatch(apiStart());
    Api.post("/cartUserProperty", obj)
      .then((resp) => {
        if (resp.data.success) {
          dispatch(requestSuccess(ADD_CART, resp.data.data));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(resp.data.message));
        }
      })
      .catch((error) => {
        console.log('MESSAGE')
        dispatch(apiError(error));
        dispatch(apiEnd());
      });
  };
}

export function payCard(cartItems) {
  return (dispatch) => {
    dispatch(apiStart());
    Api.post(`/userPayments`, cartItems)
      .then((resp) => {
        if (resp.data.success) {
          dispatch(requestSuccess(PAY_CART, []));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(resp.data.message));
        }
      })
      .catch((error) => {
        dispatch(apiError(error));
        dispatch(apiEnd());
      });
  };
}

export function removeToCart(cartId, propertyId) {
  return (dispatch) => {
    dispatch(apiStart());
    Api.delete(`/cart/${cartId}`)
      .then((resp) => {
        if (resp.data.success) {
          dispatch(requestSuccess(REMOVE_CART, propertyId));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(resp.data.message));
        }
      })
      .catch((error) => {
        dispatch(apiError(error));
        dispatch(apiEnd());
      });
  };
}
