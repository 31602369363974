import React, { useEffect } from "react";

//Components
import Screen from "../../components/Screen";
import Text from "../../components/Text";

//Styles
import styles from "./styles.module.css";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Screen title={"Terms and Conditions"} meta="Before buying or selling your next home request a lien search is a smart thing to do and your title company can help you." >
      <div className={styles.whiteBox}>
        <Text className={styles.dateText}>{"Updated on July 26, 2022"}</Text>
        <Text className={styles.paragraph}>
          {
            "Please read these Terms of Service carefully as they contain important information about your legal rights, remedies and obligations. By accessing or using the Lien Library Platform, you agree to comply with and be bound by these Terms of Service."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "Welcome to Lien Library Inc. (dba Lien Library)! (a.k.a. “we” or “us” or the “Company”). We are excited to have you as user and member of the community. The following terms and conditions (collectively, these “Terms of Service”) apply to your use of www.Lien Library.com including any content, functionality and services offered on or via www.Lien Library.com (the “Website”). The Terms of Service also include our Privacy Policy, that you can review here: "
          }
          <Text as={"a"} className={styles.link} href={"/privacy-policy"}>
            {"www.lienlibrary.com/privacy-policy."}
          </Text>
        </Text>
        <Text className={styles.paragraph}>
          {
            "We want to keep our relationship with you as lean and informal as possible, but please read the Terms of Service carefully before you start using Lien Library, because by using the Website you accept and agree to be bound and abide by these Terms of Service, including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, partners, customers, merchants, and/ or contributors of content."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website, or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes. Should you disagree with some of the provisions herein, you can either leave the Website which will be unfortunate, or contact us at "
          }
          <Text as={"a"} className={styles.link} href={"mailto:info@lienlibrary.com"}>
            {"info@lienlibrary.com."}
          </Text>
        </Text>
        <Text className={styles.subTitle}>{"Changes to the Terms of Service, or the Website"}</Text>
        <Text className={styles.paragraph}>
          {
            "Lien Library is a work in progress, meaning that a lot will change in the near future. We reserve the right to update the Website and these Terms of Service from time to time, at our discretion. We will make sure to announce any major change in a prominent way, but since “major” is a very subjective concept, you can always monitor our changes. Of course, this document is public online on "
          }
          <Text as={"a"} className={styles.link} href={"/"}>
            {"www.lienlibrary.com"}
          </Text>
          {", and you will be able to see the changes for any new version."}
          <Text as={"a"} className={styles.highlight}>
            {" Your continued use of the Website following the publishing of updated Terms of Service means that you accept and agree to the changes."}
          </Text>
        </Text>
        <Text className={styles.subTitle}>{"Accessing the Website, Security and Privacy"}</Text>
        <Text className={styles.paragraph}>
          {
            "We are working hard on improving Lien Library, but we can’t guarantee that the Website will be up and running 24/7. We also reserve the right to suspend or restrict access to some features to users. In any case, we will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period, nor for any data loss (refer to Products or Services section below)."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "To access certain features of the Website you have to register by entering your email or logging in through Facebook or logging in through Google and choosing a password as part of our security procedures. You must treat such information as confidential, not disclosing it to any third party and only using Lien Library in person. There is a password reset procedure in case you forget your password, but please notify us of any breach of security. We highly recommend to choose a strong password and that you log out from your account at the end of every session."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "It is a condition of your use of the Website and mobile applications that all the information you provide is correct, current and complete. In the future, you may be asked to provide certain registration details or other information. As custom for internet websites we reserve the right to disable any user account, at any time in our sole discretion for any or no reason, including, if in our opinion you have failed to comply with any provision of these Terms of Service."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "We use SSL encrypted browsing for all logged-in users, but we cannot guarantee that all use will be secure. We also do not guarantee that the Website or any content provided on the Website is error free. We manage your personal data according to our Privacy Policy."
          }
        </Text>
        <Text className={styles.subTitle}>{"Lien Search and Mitigation Services"}</Text>

        <Text className={styles.paragraph}>
          {
            "Lien search Authorization and Services. The “Services” provided under these Terms and Conditions will be those set forth in any mutually agreed services schedule, Lien Library quotation (“Quote”), order form, statement of work or other document executed by the Lien Library and Client referencing these Terms and Conditions (each, a “Services Schedule”). The Services may include (a) configuration, implementation, training or other consultation related to a Lien Library product or service that Client has received rights to use under a separate agreement, and/or (b) strategic, advisory, design and/or other consulting services related to Lien Search and Mitigation. Each Services Schedule shall be governed by these Terms and Conditions. Client warrants that the individual executing the Services Schedule is a duly authorized representative of Client."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "Payment for Services. Services fees are invoiced in full upon receipt of an order, with payment terms upon receipt delivery, except as otherwise specified on the applicable Services Schedule. The fees specified in the Services Schedule are the total fees and charges for the Services and will not be increased during the term of the Services Schedule except as the parties may agree in writing. There are contents to be included in the report is considered as an additional feature regardless what the municipalities charges are for these contents.Client is responsible for all applicable federal, state and local sales, use or other taxes due on the Services rendered hereunder, except for taxes based on Lien Library’ income. If any change affects the time or cost of performance under the applicable Services Schedule, an adjustment in the time and/or compensation to be paid thereunder shall be agreed to in writing by the parties before such modification shall be effective. Lien Library shall be reimbursed for the travel expenses specified in the Services Schedule, if any. If dates Lien Library is scheduled to be onsite are changed or cancelled by Client within fourteen (14) days of the scheduled date, Client shall be assessed a cancellation fee of $1,000 in addition to any travel related fees and penalties."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "Term and Termination. These Terms and Conditions shall remain in effect from the effective date specified in the Services Schedule through the completion of the Services contemplated in the Services Schedule. Each Services Schedule, with respect to the Services, shall become effective on the effective date specified in the applicable Services Schedule (“Services Effective Date”) and shall expire on the date that Services are completed thereunder (“Services Completion Date”). Either party may terminate the Services Schedule upon written notice given to the other party, if the other party materially breaches the Services Schedule and fails to cure such breach within thirty (30) days following receipt of notice describing the breach. Upon termination by Client for Lien Library’ material breach, Client shall be liable only for payment for the Services rendered through the termination date, and shall receive from Lien Library a pro-rata refund of any unused, prepaid fees for Services. There is a convenience fee for every lien search request and a service fee for mitigation services. Also, Client is agreed to pay a ten percent fee off the total amount that the lien is reduced after settlement with the municipality."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "Provision of Services. Lien Library will perform Services based on a schedule mutually agreed to by the parties. Services will expire unless the Services are scheduled and delivered within six (6) months from the date they were ordered. Services will ordinarily be performed by a Lien Library service representative or other authorized representative of Lien Library. Client agrees, however, that Lien Library, in its sole discretion, may provide the Services through a third party representative."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "Access. Client shall provide Lien Library with access to data, materials, software and hardware as reasonably required for Lien Library to perform the Services (“Client Materials”). Client hereby grants Lien Library a limited right to use such Client Materials solely for the purpose of performing Services hereunder. Client represents and warrants that it has all rights necessary in the Client Materials to provide them to Lien Library for such purpose. Client shall provide Lien Library with safe access to Client’s premises as reasonably required for Lien Library to perform the Services, if onsite performance of Services is needed and agreed to by Client. Lien Library personnel shall comply with the reasonable written rules and regulations of Client related to use of its premises, provided that such written rules and regulations are provided to Lien Library prior to commencement of the Services. Lien Library shall not be responsible for failures or delays in performing Services due to Client’s failure or delay to provide access to Client Materials or Client premises or due to Client-imposed or government-imposed security requirements."
          }
        </Text>
        <Text className={styles.subTitle}>{"Intellectual Property Rights and Use Guidelines"}</Text>
        <Text className={styles.paragraph}>
          {
            "The Website, and its original content, features and functionality are owned by Lien Library and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. Lien Library is about making it easy to get a lien search, not copying Website, design or code. So you agree to not copy, modify, create derivative works of, publicly display, publicly perform, republish, any of our copyrighted material, except to the extent permitted by the Website itself. If you have doubts about whether and how to use of material on the Website, please address your concerns to: "
          }
          <Text as={"a"} className={styles.link} href={"mailto:info@lienlibrary.com"}>
            {"info@lienlibrary.com."}
          </Text>
        </Text>
        <Text className={styles.paragraph}>
          {
            "For purposes of these Terms of Service, the term “Content” includes, without limitation, information, data, text, photographs, videos, audio clips, written posts and comments, software, scripts, graphics, and interactive features generated, provided, or otherwise made accessible on or through the Website. For the purposes of these Terms of Service, “Content” also includes all User Content (as defined below)."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "All Content added, created, uploaded, submitted, distributed, or posted to the Website by users (collectively “User Content”), whether publicly posted or privately transmitted, is the sole responsibility of the person who originated such User Content. You represent that all User Content provided by you is accurate, complete, up-to-date, and in compliance with all applicable laws, rules and regulations. You acknowledge that all Content, including User Content, accessed by you using the Website, is at your own risk and you will be solely responsible for any damage or loss to you or any other party resulting therefrom. We do not guarantee that any Content you access on or through the Website, is or will continue to be accurate."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "By submitting public User Content through the Website, you hereby do and shall grant us a worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable and transferable license to use, edit, modify, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit the User Content in connection with the Website, and our (and our successors’ and assigns’) businesses, including without limitation for promoting and redistributing part or all of the Website, (and derivative works thereof) in any media formats and through any media channels (including, without limitation, third party websites and feeds). You also hereby do and shall grant each user of the Website and mobile applications a non-exclusive, perpetual license to access your User Content through the Website, and to use, edit, modify, reproduce, distribute, prepare derivative works of, display and perform such User Content. For clarity, the foregoing license grants to us and our users does not affect your other ownership or license rights in your User Content, including the right to grant additional licenses to your User Content, unless otherwise agreed in writing. You represent and warrant that you have all rights to grant such licenses to us without infringement or violation of any third party rights, including without limitation, any privacy rights, publicity rights, copyrights, trademarks, contract rights, or any other intellectual property or proprietary rights."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "We do not guarantee that any Content will be made available on the Website. We reserve the right to, but do not have any obligation to, (i) remove, edit or modify any Content in our sole discretion, at any time, without notice to you and for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such Content or if we are concerned that you may have violated these Terms of Service), or for no reason at all and (ii) to remove or block any Content from the Website."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "You are permitted to use the Website, for your personal, non-commercial use, or legitimate business purposes, provided that your activities are lawful and in accordance with these Terms of Service. Prohibited uses include violation of laws and regulations, hacking the Website, in any manner, or violating the Content Standards set below."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "No right, title or interest in or to the Website, or any content on the site is transferred to you, and all rights not expressly granted are reserved. Any use of the Website, not expressly permitted by these Terms of Service is a breach of these Terms of Service and can lead to account termination."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "We encourage your feedback, in the form of reviews, comments, and suggestions or recommendations for modifications, improvements or changes to the Services or the Site that you may choose in your sole discretion to provide us from time to time (“Feedback”). When you provide Feedback, you grant us, under all right, title and interest in and to the Feedback, a non-exclusive, royalty-free, worldwide, transferable, sub-licensable, irrevocable, perpetual license to use that Feedback or to incorporate it into the Website, or other products or services."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "The Website and mobile applications may contain Content specifically provided by us, our partners or our users and such Content is protected by copyrights, trademarks, service marks, patents, trade secrets or other proprietary rights and laws. You shall abide by and maintain all copyright notices, information, and restrictions contained in any Content accessed through the Website."
          }
        </Text>
        <Text className={styles.subTitle}>{"User Contributions and Content Standards. Special Disclaimer for Legal Content"}</Text>
        <Text className={styles.paragraph}>
          {
            "Lien Library contains user generated content, and also may contain message boards, personal web pages, forums or other interactive features that allow you to upload, post, submit, publish, display or transmit to other Users Content on or through the Website. All User Content must be lawful, not spammy, and clear of virus or other malware. More specifically, but without limiting the foregoing, it must comply with the following Content Standards: "
          }
        </Text>
        <Text className={styles.bullet}>
          {"• Not contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable."}
        </Text>
        <Text className={styles.bullet}>
          {
            "• Not promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age."
          }
        </Text>
        <Text className={styles.bullet}>{"• Not infringe any patent, trademark, trade secret, copyright or other intellectual property rights of any other person."}</Text>
        <Text className={styles.bullet}>
          {
            "• Not violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Service."
          }
        </Text>
        <Text className={styles.bullet}>{"• Not be likely to deceive any person."}</Text>
        <Text className={styles.bullet}>{"• Not promote any illegal activity, or advocate, promote or assist any unlawful act."}</Text>
        <Text className={styles.bullet}>{"• Not cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person."}</Text>
        <Text className={styles.bullet}>{"• Not be used to impersonate any person, or to misrepresent your identity or affiliation with any person or organization."}</Text>
        <Text className={styles.bullet}>{"• Not involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising."}</Text>
        <Text className={styles.bullet}>{"• Not give the impression that they emanate from us or any other person or entity, if this is not the case."}</Text>
        <Text className={styles.lastBullet}>
          {"• Not used, if public, as test empty documents with no actual content, or typing exercises: please respect our community and help us minimize the clutter!"}
        </Text>
        <Text className={styles.paragraph}>
          {
            "User Content is not intended to be legal advice nor form an attorney-client relationship, nor among the users, nor between the users and Lien Library. Use of the Website, should never be understood to be replacing use of a qualified attorney, and Lien Library’s relationship to all documents and transactions completed using the Website, is that of a trusted, disinterested third party."
          }
        </Text>
        <Text className={styles.subTitle}>{"Law and Copyright Infringement"}</Text>
        <Text className={styles.paragraph}>
          {
            "We do not undertake to review all material before it is posted on the Website, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party. In the unlikely event we receive a disclosure request from an authorized party, we reserve the right to disclose user identities when required to do so by the law, including in response to a law enforcement request supported by a valid court order. You waive and hold harmless the Company from any claims resulting from any action taken by the Company during or as a result of its investigations and from any actions taken as a consequence of investigations by either the Company or law enforcement authorities."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "If you believe that any User Content violate your copyright, please follow the procedure set forth by article 512(c) of the DMCA and provide us a written takedown notice including the following information:"
          }
        </Text>
        <Text className={styles.bullet}>
          {"• A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed."}
        </Text>
        <Text className={styles.bullet}>
          {
            "• Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site."
          }
        </Text>
        <Text className={styles.bullet}>
          {
            "• Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material."
          }
        </Text>
        <Text className={styles.bullet}>{"• Information reasonably sufficient for us to contact you, such as email, address, telephone number."}</Text>
        <Text className={styles.bullet}>
          {"• A statement that you a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law."}
        </Text>
        <Text className={styles.lastBullet}>
          {
            "• A statement that the information in the notification is accurate, and under penalty of perjury, that you is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed."
          }
        </Text>
        <Text className={styles.paragraph}>
          {"The notice should be addressed to "}
          <Text as={"a"} className={styles.link} href={"mailto:info@lienlibrary.com"}>
            {"info@lienlibrary.com."}
          </Text>
        </Text>
        <Text className={styles.subTitle}>{"Disclaimer of Warranties, Limitations of Liability, and Indemnification"}</Text>
        <Text className={styles.paragraph}>
          {
            "Your use of Lien Library is at your sole risk. The service is provided “as is” and “as available”. We disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. We are not liable for damages or personal injury, direct or consequential, resulting from your use of the Website, or the lien mitigation request and the lien search requesting from the municipality through www.lienlibrary.com and you agree to defend, indemnify and hold us harmless from any claims, losses, injuries, deaths, liability costs, and expenses (including but not limited to attorney’s fees) arising from your violation of any third-party’s rights. You acknowledge that you have only a limited, non-exclusive, non-transferable license to use the Website. Because the Website are not error or bug free, you agree that you will use it carefully and avoid using it ways which might result in any loss of your or any third party’s property or information."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "LIEN LIBRARY shall not be liable for payoffs and/or property findings provided to us by Associations, Sub Associations, government offices (including, but not limited to, code enforcement, building department, and utility offices), and/or Attorneys’ offices that contain errors and omissions. Subject to the preceding sentence, LIEN LIBRARY shall be responsible for and liable to Client for losses associated with any Tax and Lien Information which was incorrectly reported on any Full Search Certificate issued by LIEN LIBRARY to Client (hereinafter an “Erroneous Certificate”). Within thirty (30) days of receipt of written notice from Client of the issuance of an Erroneous Certificate by LIEN LIBRARY (which written notice shall be accompanied by a copy of the Erroneous Certificate issued to Client, Corrected information, and an itemization of the losses, penalties, interest and court costs incurred by the Client as a result of the issuance of such Erroneous Certificate), LIEN LIBRARY shall determine if the error was cause d by inaccurate information provided by Associations, Sub Associations, government offices (including, but not limited to, code enforcement, building department, and utility offices), and/or Attorneys’ offices or if the error was due to LIEN LIBRARY inaccurately reporting on the Full Search Certificate. If the error was due to LIEN LIBRARY incorrectly reporting on the Full Search Certificate (due to no errors from Associations, Sub Associations, government offices (including, but not limited to, code enforcement, building department, and utility offices), and/or Attorneys’ offices) LIEN LIBRARY agrees to pay to Client the costs incurred by Client as a result of the Erroneous Certificate within one hundred eighty (180) days after LIEN LIBRARY receives written notice of the Erroneous Certificate."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "The Erroneous Certificate liability provided in this Section, hereof, shall include any errors made by employees of LIEN LIBRARY in the opening of an Order and locating the Property; provided, however, LIEN LIBRARY shall not be liable to Client if the error is the result of Client providing or selecting incorrect Required Data."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "The Erroneous Certificate liability provided in this Section, hereof, shall exclude, and Client shall be solely responsible for, any loss or claim resulting from the failure of Client to read or take into account information clearly disclosed in the Full Search Certificate issued to Client by LIEN LIBRARY."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "IN NO EVENT, WHETHER IN CONTRACT, TORT(INCLUDING NEGLIGENCE), STRICT LIABILITY, WARRANTY, OR OTHERWISE, SHALL EITHER PARTY BE LIABLE TO THE OTHER PARTY OR TO ANY THIRD PARTY FOR INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF REVENUE, LOST PROFITS, LOSS OF REPUTATION, INJURY TO PROPERTY, COST OF CAPITAL, COST OF SUBSTITUTE PROPERTY, OR CLAIMS FOR SUCH DAMAGE."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "Disclaimer with Respect to Bulk Property Owners: Please be advised that the property data is a Bulk Property data (i.e. a property owner, folio, address who/which owns numerous parcels in the subject jurisdiction). Consequently, LIEN LIBRARY has not performed a name search with respect to the Bulk Property Owner. As such, LIEN LIBRARY strongly recommends that you perform a traditional title search of the subject property (if you have not already done so) to determine if issues associated with other parcels owned by the property owner have resulted in the recordation of a lien that might encumber the subject property."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "LIEN LIBRARY only searches the Address, and Parcel ID provide on the order form. If you would like additional Addresses, Parcel ID’s searched please be advised that there will be an additional fee associated with this service. This information needs to be provided or selected on LIEN LIBRARY in order for us to move forward with any additional searches."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free. We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable. You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you. You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided ‘as is’ and ‘as available’ for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement"
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "In no case shall Lien Library, our directors, officers, employees, affiliates, agents, contractors, interns, partners, suppliers, service providers or licensors be liable for any injury, death, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service, online or at a municipality, or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, any injury or death when taking a Lien Library, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "The term “Affiliate” referred to herein, is an entity that, directly or indirectly, controls, or is under the control of, or is under common control with Lien Library Inc, where control means having more than fifty percent (50%) voting stock or other ownership interest or the majority of voting rights of such entity."
          }
        </Text>
        <Text className={styles.subTitle}>{"Municipality Disclaimer of Liability"}</Text>
        <Text className={styles.paragraph}>
          {
            "I understand that the municipality and its associates shall not be liable to anyone for any losses, damages, or injuries of any nature whatsoever, howsoever occurring within the premises or outside the premises from any activities run by the municipality. In addition, regardless of billing instructions, I acknowledge all charges as personal indebtedness and will be responsible for full payment on the account. Current owner of the property is liable to pay any outstanding lien."
          }
        </Text>
        <Text className={styles.subTitle}>{"Geographic and Age Restrictions"}</Text>
        <Text className={styles.paragraph}>
          {
            "For now, we provide this Website for use only by persons located in the United States. We make no claims that the Website, or any of its content is accessible, appropriate or legal outside of the United States. If you access the Website, from outside the United States, you do so on your own initiative and are responsible for compliance with local laws. For now, we provide “Lien Library” for use only by persons 18 years of age or older. When using Lien Library, you agree that you are 18 years of age or older."
          }
        </Text>
        <Text className={styles.subTitle}>{"Governing Law and Jurisdiction"}</Text>
        <Text className={styles.paragraph}>
          {
            "These Terms of Service and any dispute or claim arising out of, or related to them, shall be governed by and construed in accordance with the internal laws of the State of Florida without giving effect to any choice or conflict of law provision or rule."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "Any legal suit, action or proceeding arising out of, or related to, these Terms of Service or the Website, shall be instituted exclusively in the federal courts of the United States or the courts of the State of Florida."
          }
        </Text>
        <Text className={styles.subTitle}>{"Waiver and Severability"}</Text>
        <Text className={styles.paragraph}>
          {
            "Our failure to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. The Terms of Service constitutes the entire agreement between you and Lien Library and govern your use of the service, superseding any prior agreements (including, but not limited to, any prior versions of the Terms of Service). If any provision of these Terms of Service is held by a court of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Service will continue in full force and effect."
          }
        </Text>
        <Text className={styles.subTitle}>{"Modifications to the Service and Prices"}</Text>
        <Text className={styles.paragraph}>
          {
            "Prices for our products are subject to change without notice. We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service."
          }
        </Text>
        <Text className={styles.subTitle}>{"Products or Services"}</Text>
        <Text className={styles.paragraph}>
          {
            "Certain products or services may be available exclusively online through the Website. These products or services may have limited quantities and are subject to refund or exchange only according to our Refund Policy. We have made every effort to display as accurately as possible the colors and images of our products that appear on our websites. We cannot guarantee that your computer monitor’s display of any color will be accurate."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited. We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected."
          }
        </Text>
        <Text className={styles.subTitle}>{"Accuracy of Billing and Account Information"}</Text>
        <Text className={styles.paragraph}>
          {
            "We reserve the right to refuse any order you place with us. We use paypal to process the payment, feel free to contact them for their own policy and term of use. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors."
          }
        </Text>
        <Text className={styles.paragraph}>
          {
            "You agree to provide current, complete and accurate purchase and account information for all purchases made at our site. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed."
          }
        </Text>
        <Text className={styles.subTitle}>{"Optional Tools"}</Text>
        <Text className={styles.paragraph}>
          {
            "We may provide you with access to third-party tools over which we neither monitor nor have any control nor input. You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools. Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s). We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service."
          }
        </Text>
        <Text className={styles.subTitle}>{"Feedback"}</Text>
        <Text className={styles.lastParagraph}>
          {"We welcome any comment, question and communication "}
          <Text as={"a"} className={styles.link} href={"mailto:info@lienlibrary.com"}>
            {"info@lienlibrary.com."}
          </Text>
        </Text>
      </div>
    </Screen>
  );
};

export default TermsAndConditions;
