import { filter, get } from "lodash";
import moment from "moment";

import { dateDiff } from "../constants/Utils";

export function computeLienInterest(item) {
  const hasComplied = item.dateComplied ? true : false;
  const endDate = hasComplied ? moment(item.dateComplied) : moment();
  const diffDays = dateDiff(moment(item.dateOfFineBegan), endDate);
  const originalFine = parseFloat(item.fineAmount);
  const interest = originalFine * diffDays;
  return interest;
}

export function computeLienAmount(item) {
  // const interest = computeLienInterest(item);
  const otherFees = parseFloat(item.adminFee) + parseFloat(item.otherFee);
  const originalFine = parseFloat(item.fineAmount);
  const amountDue = originalFine + otherFees;
  return amountDue;
}

export function getExcludingNulls(object, path, defaultValue) {
  const temp = get(object, path, defaultValue);
  return temp === null ? defaultValue : temp;
}

export function calculateInterest(lienObject) {
  const hasComplied = getExcludingNulls(lienObject, "dateComplied", false);
  const endDate = hasComplied
    ? moment(getExcludingNulls(lienObject, "dateComplied", undefined))
    : moment();
  const diffDays =
    dateDiff(moment(getExcludingNulls(lienObject, "dateOfFineBegan", undefined)), endDate) + 1;
  // const payOffDays = dateDiff(
  //   moment(lienObject.dateRecorded),
  //   moment(lienObject.payoffDate)
  // );
  const dailyFine = parseFloat(getExcludingNulls(lienObject, "originalFine", 0)) * diffDays;
  // const interest = payOffDays * ((dailyFine * 0.12) / 365);
  const total =
    parseFloat(getExcludingNulls(lienObject, "courtFee", 0)) +
    parseFloat(getExcludingNulls(lienObject, "recordingFee", 0)) +
    parseFloat(getExcludingNulls(lienObject, "adminFee", 0)) +
    parseFloat(getExcludingNulls(lienObject, "releaseOfLienFee", 0)) +
    dailyFine;
  // dailyFine +
  // interest;
  return total;
}

export function getLiens(liens, lienTypes) {
  return lienTypes.map((lienType) => {
    return filter(liens, function (lien) {
      if (lien.lienTypeId === lienType.id) {
        return lien;
      }
    });
  });
}
