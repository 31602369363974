/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter, Switch, useLocation } from "react-router-dom";
import { Dimmer } from "semantic-ui-react";
import { initNavigation } from "./utils/navigation";
import { isEqual, isEmpty } from "lodash";

//Redux
import { connect } from "react-redux";
import { dismissErrors } from "./redux/actions/errors";
import { rehydrate } from "./redux/actions/user";
import { clearMitigation, selectMitigation } from "./redux/actions/mitigations";

// Hooks
import usePrevious from "./hooks/usePrevious";

//Components
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";
import ErrorDialog from "./components/ErrorDialog";

//Screens
import Home from "./screens/Home";
import Search from "./screens/Search";
import Login from "./screens/Login";
import Register from "./screens/Register";
import ForgotPassword from "./screens/ForgotPassword";
import Property from "./screens/PropertyScreen";
import PaymentInfo from "./screens/PaymentInfo";
import Report from "./screens/Report";
import Mitigation from "./screens/Mitigation";
import LienMitigation from "./screens/LienMitigationRequest";

//Dashboard Screens
import Profile from "./screens/Profile";
import MyProperties from "./screens/MyProperties";
import Favorites from "./screens/Favorites";
import Reports from "./screens/Reports";
import Mitigations from "./screens/Mitigations";
import Payments from "./screens/Payments";
import AlarmRegistrations from "./screens/AlarmRegistrations";

//Static
import AboutUs from "./screens/AboutUs";
import TermsAndConditions from "./screens/TermsAndConditions";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import Browse from "./screens/Browse";
import Browsev2 from "./screens/Browsev2";
import NoMatch from "./screens/NoMatch";
import MitigationDetail from "./screens/MitigationDetail";
import Cart from "./screens/Cart";
import GovernmentScreen from "./screens/Government";
import AlarmRegistrationRequest from "./screens/AlarmRegistrationRequest";
//import Pricing from "./screens/Pricing";

const TRACKING_ID = "G-1P4L7SBK69";
ReactGA.initialize(TRACKING_ID);

const App = ({ dispatch, history, isLoggedIn, isFetching, isError, message, userId, currentMitigation }) => {
	const location = useLocation();
	const prevLocation = usePrevious(location);

	useEffect(() => {
		(async () => {
			await dispatch(rehydrate());
		})();
		initNavigation(history);
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	const handleDismissErrors = () => {
		dispatch(dismissErrors());
	};

	useEffect(() => {
		if (!isEqual(prevLocation, location) && !isEmpty(prevLocation) && userId) {
			if (prevLocation.pathname.includes("/mitigation/")) {
				dispatch(clearMitigation(currentMitigation, userId));
			}
			if (location.pathname.includes("/mitigation/")) {
				let mitigation = {};
				if (!isEmpty(currentMitigation)) {
					mitigation = currentMitigation;
				} else {
					mitigation = location.state;
				}
				if (!isEmpty(mitigation)) {
					dispatch(selectMitigation(mitigation, userId));
				}
			}
		}
	}, [location]);

	return (
		<Dimmer.Dimmable blurring={true} dimmed={isFetching || isError}>
			<Loading isFetching={isFetching} />
			<ErrorDialog isError={isError} text={message} onDismiss={handleDismissErrors} />
			<Switch>
				{/* Public Routes */}
				<PublicRoute exact path={"/"} component={Home} />
				<PublicRoute exact path={"/search"} component={Search} />
				<PublicRoute exact path={"/property"} component={Property} />

				{/* Restricted Routes */}
				<PublicRoute exact path={"/login"} component={Login} isLoggedIn={isLoggedIn} restricted={true} />
				<PublicRoute exact path={"/register"} component={Register} isLoggedIn={isLoggedIn} restricted={true} />
				<PublicRoute exact path={"/register/:token"} component={Register} isLoggedIn={isLoggedIn} restricted={true} />
				<PublicRoute exact path={"/forgot-password"} component={ForgotPassword} isLoggedIn={isLoggedIn} restricted={true} />

				{/* Static Pages */}
				<PublicRoute exact path={"/about-us"} component={AboutUs} />
				<PublicRoute exact path={"/for-government"} component={GovernmentScreen} />
				{/* <PublicRoute exact path={"/pricing"} component={Pricing} /> */}
				<PublicRoute exact path={"/terms-and-conditions"} component={TermsAndConditions} />
				<PublicRoute exact path={"/privacy-policy"} component={PrivacyPolicy} />
				<PublicRoute exact path={"/analyze"} component={Browse} />
				<PublicRoute exact path={"/browsev2"} component={Browsev2} />

				{/* Private Routes */}
				<PrivateRoute exact path={"/profile"} component={Profile} isLoggedIn={isLoggedIn} />
				<PrivateRoute exact path={"/my-properties"} component={MyProperties} isLoggedIn={isLoggedIn} />
				<PrivateRoute exact path={"/reports"} component={Reports} isLoggedIn={isLoggedIn} />
				<PrivateRoute path={"/reports/:id"} component={Report} isLoggedIn={isLoggedIn} />
				<PrivateRoute exact path={"/request-mitigation"} component={LienMitigation} isLoggedIn={isLoggedIn} />
				<PrivateRoute exact path={"/mitigations"} component={Mitigations} isLoggedIn={isLoggedIn} />
				<PrivateRoute path={"/mitigation/:id"} component={Mitigation} isLoggedIn={isLoggedIn} />
				<PrivateRoute exact path={"/mitigation-detail"} component={MitigationDetail} isLoggedIn={isLoggedIn} />
				<PrivateRoute exact path={"/favorites"} component={Favorites} isLoggedIn={isLoggedIn} />
				<PrivateRoute exact path={"/payment-info"} component={PaymentInfo} isLoggedIn={isLoggedIn} />
				<PrivateRoute exact path={"/payments"} component={Payments} isLoggedIn={isLoggedIn} />
				<PrivateRoute exact path={"/cart"} component={Cart} isLoggedIn={isLoggedIn} />
				<PrivateRoute exact path={"/alarm"} component={AlarmRegistrations} isLoggedIn={isLoggedIn} />
				<PrivateRoute exact path={"/alarmForm"} component={AlarmRegistrationRequest} isLoggedIn={isLoggedIn} />
				{/* <PrivateRoute path={"/payments/:id"} component={Report} isLoggedIn={isLoggedIn} /> */}
				{/* No Match Has To Be Last Item */}
				<PublicRoute path={"*"} component={NoMatch} />
			</Switch>
		</Dimmer.Dimmable>
	);
};

function mapStateToProps({ loading, errors, user, mitigations }) {
	return {
		isFetching: loading.isFetching,
		isError: errors.isError,
		message: errors.message,
		isLoggedIn: user.isLoggedIn,
		userId: user.id,
		currentMitigation: mitigations.currentMitigation,
	};
}

export default withRouter(connect(mapStateToProps)(App));
