import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

//Redux
import { Provider } from "react-redux";
import store from "./redux/store";

//App
import App from "./App";

//Styles
import "react-virtualized/styles.css";
import "semantic-ui-css/semantic.min.css";
import "./theme.css";
import "./index.css";
import "./responsive-selector.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PayPalScriptProvider
      options={{
        currency: "USD",
        components: "buttons,messages",
        "client-id": "AfCGDhaoh1L-mFuDT3tTTARrdnV6OIt-kjuWuCM9ZeANzuIcwcvPdch9VZkbZPX45gSZjapZa8jvHeDl",
        "merchant-id": "HY6SYMHMZ7H7E",
        "data-partner-attribution-id": "6f5e4d3c2b1a",
        "enable-funding": "paylater",
      }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PayPalScriptProvider>
  </Provider>
);

