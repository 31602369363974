import Api from "../../api";
import { apiStart, apiEnd, apiError, requestSuccess } from "./api";
import { SELECT_PAYMENT } from "./types";
import { navigate } from "../../utils/navigation";

export function selectPayment(payment) {
  return (dispatch) => {
    dispatch(requestSuccess(SELECT_PAYMENT, payment));
    navigate(`/payments/${payment.id}`);
  };
}

export function downloadRelease(url) {
  return async (dispatch) => {
    dispatch(apiStart());
    return await Api.get(`/getReleasesCityPdfByKeyFile/${url}`, { responseType: "blob" })
      .then((response) => {
        dispatch(apiEnd());
        const newPDF = new Blob([response.data], { type: "application/pdf" });
        const usableUrl = URL.createObjectURL(newPDF);
        return usableUrl;
      })
      .catch((error) => {
        dispatch(apiError(error.message));
        dispatch(apiEnd());
      });
  };
}
