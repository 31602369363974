/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { isEmpty, map } from "lodash";

//Redux
import { connect } from "react-redux";
import { getMitigations } from "../../redux/actions/mitigations";

//Components
import Screen from "../../components/Screen";
import MitigationCard from "../../components/MitigationCard";

//Styles
import styles from "./styles.module.css";

const Mitigations = ({ dispatch, mitigationList, userId }) => {
  useEffect(() => {
    dispatch(getMitigations(userId));
  }, []);

  const renderMitigations = (array) => {
    if (!isEmpty(array)) {
      const mitigations = map(array, (item, index) => {
        const stripped = index % 2 === 0;
        return <MitigationCard key={index} item={item} stripped={stripped} />;
      });
      return mitigations;
    } else {
      return null;
    }
  };

  return (
    <Screen
      searchBar
      fixedHeight
      title={"Mitigations"}
      meta="Connect with your local government to negotiate the lien on your house through mitigation: from code compliance lien, utility lien, assessment lien to permit ">
      <div className={styles.content}>{renderMitigations(mitigationList)}</div>
    </Screen>
  );
};

function mapStateToProps({ mitigations, user }) {
  return { mitigationList: mitigations.mitigationList, userId: user.id };
}

export default connect(mapStateToProps)(Mitigations);
