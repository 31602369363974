import { GET_TYPES, CHECK_SESSION } from "../actions/types";

export default function lienTypes(state = { lienTypes: [] }, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_TYPES:
      return { ...state, lienTypes: payload.data };

    case CHECK_SESSION:
      return { ...state, lienTypes: payload.data.lienTypes.lienTypes };

    default:
      return state;
  }
}
