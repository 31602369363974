/* eslint-disable react-hooks/exhaustive-deps */
// React
import React, { useState, useEffect } from "react";
import { Sidebar } from "semantic-ui-react";
import { isEmpty, filter, includes } from "lodash";

// Redux
import { connect } from "react-redux";
import genericAction from "../../redux/actions/generic";

// Components
import Screen from "../../components/Screen";
import Map from "../../components/Map";
import FilterBar from "./FilterBar";
import InfoWindow from "./InfoWindow";

const Browse = ({ dispatch }) => {
  const [isTaxDeeds, setIsTaxDeeds] = useState(false);
  const [endpointParams, setEndpointParams] = useState({ method: "POST", route: "/propertyLienCity" });
  const [allItems, setAllItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);

  useEffect(() => {
    (async () => {
      const promise = dispatch(genericAction(endpointParams.method, endpointParams.route));
      const [response] = await Promise.all([promise]);
      setAllItems(response);
      setFilteredItems(response);
    })();
  }, [isTaxDeeds]);

  const handleOpen = (props) => {
    setSelectedProperty(filteredItems[props?.index]);
  };

  const handleClose = () => {
    setSelectedProperty(null);
  };

  const handleFilter = (address) => {
    if (isEmpty(address)) {
      setFilteredItems(allItems);
    } else {
      const filtered = filter(allItems, (item) => {
        return includes(String(item.address).toLowerCase(), String(address).toLowerCase());
      });
      setFilteredItems(filtered);
      if (filtered.length === 1) {
        setSelectedProperty(filtered[0]);
      }
    }
  };

  const handleToggle = (value) => {
    setIsTaxDeeds(value);
    setEndpointParams(
      value
        ? { method: "GET", route: "/getPropertyWhereTaxDeedTrue" }
        : { method: "POST", route: "/propertyLienCity" }
    );
  };

  const visible = !isEmpty(selectedProperty);
  return (
    <Screen fixedHeight noContainer>
      <Sidebar.Pushable>
        {visible && <InfoWindow isTaxDeed={isTaxDeeds} property={selectedProperty} onClose={handleClose} />}
        <Sidebar.Pusher dimmed={visible}>
          <FilterBar handleFilter={handleFilter} handleToggle={handleToggle} />
          <Map items={filteredItems} onMarkerClick={handleOpen} />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Screen>
  );
};

export default connect()(Browse);

