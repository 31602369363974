import { LOGIN, CHECK_SESSION, LOGOUT, REHYDRATE } from "../actions/types";

export default function userProperties(state = { propertyList: [] }, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
      return { ...state, propertyList: payload.data.listProperty };

    case REHYDRATE:
      return { ...state, propertyList: payload.data.listProperty };

    case CHECK_SESSION:
      return {
        ...state,
        propertyList: payload.data.userProperties.propertyList,
      };

    case LOGOUT:
      return { ...state, propertyList: [] };

    default:
      return state;
  }
}
