import React from "react";
import { Button, Form, List } from "semantic-ui-react";
import { Formik, } from "formik";
import * as Yup from "yup";
import styles from "./styles.module.css";

const ProfileForm = ({ onSubmit, initialValues }) => {
  const initFormValues = initialValues
    ? initialValues
    : ProfileFormConfig.initialValues;

  return (
    <div>
        <Formik
          initialValues={initFormValues}
          validationSchema={ProfileFormConfig.schema}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form onSubmit={handleSubmit}>
              <List style={{width:'50%'}}>
                {ProfileFormConfig.fields.map((field, index) => (
                  <List.Item 
                  key={index}
                  style={{marginBottom:'0.25em'}}
                  content={
                  <Form.Input
                    fluid
                    icon={field.icon}
                    onChange={handleChange(field.name)}
                    value={values[field.name]}
                    iconPosition="left"
                    placeholder={field.placeholder}
                    type={field.type}
                    error={errors[field.name]}
                  />
                  } 
                />
                ))}
              </List>
              <Button className={styles.button} type="submit">
                Save
              </Button>
            </Form>
          )}
        </Formik>
    </div>
  );
};


const ProfileFormConfig = {
  initialValues: {
    name: "",
    email: "",
    phone:"",
    address:"",
  },
  fields: [
    {
      icon: "user",
      placeholder: "Full Name",
      type: "text",
      name: "name",
    },
    {
      icon: "mail",
      placeholder: "Email Address",
      type: "email",
      name: "email",
    },
    {
      icon: "phone",
      placeholder: "Phone Number",
      type: "text",
      name: "phone",
    },
    {
      icon: "home",
      placeholder: "Address",
      type: "text",
      name: "address",
    },
  ],
  schema: Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required().email("Not a valid email address"),
    phone: Yup.string(),
    address: Yup.string()
  }),
};

export default ProfileForm;
