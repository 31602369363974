let history = null;

export function initNavigation(routerHistory) {
  history = routerHistory;
}

export function navigate(route, replace = false, item) {
  if (history && history.location.pathname !== route) {
    replace ? history.replace(route, item) : history.push(route, item);
  }
}

export function goBack(number = null) {
  if (history) {
    if (number) {
      history.goBack();
    } else {
      history.go(-number);
    }
  }
}
