import React from "react";
import { get, isEmpty } from "lodash";
import { Label } from "semantic-ui-react";

//Redux
import { connect } from "react-redux";
import { downloadRelease } from "../../redux/actions/payments";

//Components
import Text from "../Text";

//Constants
import { getTimeLabel, currencyFormat } from "../../constants/Utils";

//Styles
import styles from "./styles.module.css";

const PaymentsCard = ({ dispatch, item, stripped }) => {
  const releaseKey = get(item, "release.url", "");
  const hasRelase = !isEmpty(releaseKey);
  const handlePayment = async () => {
    if (hasRelase) {
      const pdf = await dispatch(downloadRelease(releaseKey));
      console.log(pdf);
      window.open(pdf);
    }
  };

  return (
    <div className={stripped ? styles.container : styles.container2} onClick={handlePayment}>
      <div className={styles.row}>
        <div className={styles.infoLargeContainer}>
          <div className={styles.rowLabel}>
            <Text className={styles.text}>{`${get(item, "type", "")} ${get(item, "id", "")}`}</Text>
            {hasRelase && <Label className={styles.label} icon={"download"} content={"Lien Release"} size={"tiny"} />}
          </div>
          <Text className={styles.text}>{get(item, "description", "")}</Text>
        </div>
        <div className={styles.infoSmallContainer}>
          <Text className={styles.text}>{currencyFormat(get(item, "amount", ""))}</Text>
        </div>
        <div className={styles.lastContainer}>
          <Text className={styles.timeText}>{getTimeLabel(item.endedDate ? item.endedDate : item.createdAt)}</Text>
        </div>
      </div>
    </div>
  );
};

export default connect()(PaymentsCard);
