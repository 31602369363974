import { LOGIN, CHECK_SESSION, LOGOUT, REHYDRATE, ADD_CART, REMOVE_CART, PAY_CART } from "../actions/types";
import { filter, orderBy } from "lodash";

// const CART_MOCK = [
//   {
//     type: "LienSearch",
//     data: {
//       address: "19611 nw 41st ave, miami gardens, fl 33055, usa",
//       folio: "3411320030720",
//     },
//   },
// ];

export default function cart(state = { cartList: [] }, action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_CART:
			return { ...state, cartList: [...state.cartList, payload.data] };

		case LOGIN:
			const cartList = orderBy(payload.data.user.cart, ["createdAt"], "asc");
			console.log(cartList);
			return { ...state, cartList };

		case REHYDRATE: {
			const cartList = orderBy(payload.data.user.cart, ["createdAt"], "asc");
			console.log(cartList);
			return { ...state, cartList };
		}

		case CHECK_SESSION:
			return {
				...state,
				cartList: payload.data.cart.cartList,
			};

		case REMOVE_CART:
			const array = filter(state.cartList, ({ propertyId }) => propertyId !== payload.data);
			return { ...state, cartList: array };

		case PAY_CART:
			return { ...state, cartList: [] };

		case LOGOUT:
			return { ...state, cartList: [] };

		default:
			return state;
	}
}
