import React, { PureComponent } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * PrivateRoute Component
 * @augments {PureComponent<Props>}
 */
class PrivateRoute extends PureComponent {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
    component: PropTypes.any.isRequired,
  };

  static defaultProps = {
    isLoggedIn: false,
    exact: false,
  };

  render() {
    const { isLoggedIn } = this.props;
    if (isLoggedIn) {
      localStorage.removeItem("route");
      return <Route {...this.props} />;
    } else {
      localStorage.setItem("route", JSON.stringify(this.props));
      return <Redirect to={"/login"} />;
    }
  }
}

export default PrivateRoute;
