import React, { PureComponent } from "react";
import { Container } from "semantic-ui-react";

//Redux
import { connect } from "react-redux";

//Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Dashboard from "../../components/Dashboard";

//Styles
import styles from "./styles.module.css";

class PaymentInfo extends PureComponent {
  render() {
    return (
      <div className={styles.screen}>
        <Header searchBar={true} />
        <div className={styles.body}>
          <Container className={styles.container}>
            <Dashboard />
            <div className={styles.content}></div>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect()(PaymentInfo);
