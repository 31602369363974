import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import {
  LOGIN,
  LOGOUT,
  API_FAILURE,
  CHECK_SESSION,
  EDIT_PROFILE,
  SET_PROFILE_PIC,
  // SUBSCRIBE,
  PAY_LIEN,
  REHYDRATE,
} from "../actions/types";
import { navigate } from "../../utils/navigation";
import { socketInit, socketClose } from "../../socket";
import { isEmpty } from "lodash";

function requestSuccess(type, data = null) {
  return { type, payload: { data } };
}

function requestFail(errorCode) {
  errorCode = !isEmpty(errorCode)
    ? errorCode
    : "An unxexpected error has occured, please try again";
  return { type: API_FAILURE, payload: { data: errorCode } };
}

export function login(credentials, rememberMe) {
  return (dispatch) => {
    dispatch(apiStart());
    Api.post("/authenticateWeb", {
      email: credentials.email,
      password: credentials.password,
    })
      .then((response) => {
        if (response.data.success) {
          if (rememberMe) {
            localStorage.setItem("credentials", JSON.stringify(credentials));
            localStorage.setItem("rememberMe", JSON.stringify(rememberMe));
          } else {
            localStorage.removeItem("credentials");
            localStorage.removeItem("rememberMe");
          }
          const { token, user } = response.data.data;
          Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          Api.defaults.headers.common["userId"] = user.id;
          Api.defaults.headers.common["username"] = user.name;
          dispatch(requestSuccess(LOGIN, response.data.data));
          dispatch(apiEnd());
          socketInit(user.id, dispatch);
          navigate("/");
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(apiEnd());
        dispatch(apiError(error.message));
      });
  };
}

export function rehydrate(reset = false) {
  return (dispatch) => {
    if (reset) {
      dispatch(requestSuccess(LOGOUT));
      Api.defaults.headers.common["Authorization"] = `Bearer `;
      Api.defaults.headers.common["userId"] = "";
      Api.defaults.headers.common["username"] = "";
      localStorage.removeItem("store");
      socketClose();
    } else {
      const store = localStorage.getItem("store");
      if (store) {
        const parsedStore = JSON.parse(store);
        dispatch(requestSuccess(CHECK_SESSION, parsedStore));
        if (parsedStore.user.isLoggedIn) {
          Api.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${parsedStore.user.token}`;
          Api.defaults.headers.common["userId"] = parsedStore.user.id;
          Api.defaults.headers.common["username"] = parsedStore.user.name;
          socketInit(parsedStore.user.id, dispatch);
          dispatch(apiStart());
          Api.get("/rehydrateWeb")
            .then((response) => {
              if (response.data.success) {
                dispatch(requestSuccess(REHYDRATE, response.data.data));
                dispatch(apiEnd());
              } else {
                dispatch(apiEnd());
                dispatch(requestFail(response.data.message));
              }
            })
            .catch((error) => {
              dispatch(apiEnd());
              dispatch(apiError(error.message));
            });
        }
      }
    }
  };
}

export function logout() {
  return (dispatch) => {
    dispatch(requestSuccess(LOGOUT));
    Api.defaults.headers.common["Authorization"] = `Bearer `;
    Api.defaults.headers.common["userId"] = "";
    socketClose();
  };
}

export function register(user) {
  return (dispatch) => {
    dispatch(apiStart());
    Api.post("/createUsersCity", {
      name: user.name,
      address: user.address,
      phone: user.phone,
      email: user.email,
      password: user.password,
      role: "",
      type: "Web",
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(apiEnd());
          navigate("/login");
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(apiEnd());
        dispatch(apiError(error.message));
      });
  };
}

export function resetPassword(email) {
  console.log(email)
  return (dispatch) => {
    dispatch(apiStart());
    return Api.post("/forgetPassword", {
      email,
    })
      .then((response) => {
        console.log(response)
        if (response.data.success) {
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message));
        }
        return response.data.success;
      })
      .catch((error) => {
        dispatch(apiError(error.message));
        dispatch(apiEnd());
      });
  };
}

export function editProfile(fields, userID) {

  return (dispatch) => {
    dispatch(apiStart());
    Api.put(`/usersCity/${userID}`, fields)
      .then((response) => {
        console.log(response)
        if (response.data.success) {
          dispatch(requestSuccess(EDIT_PROFILE, response.data.data));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(apiError(error.message));
        dispatch(apiEnd());
      });
  };
}

export function setProfilePicture(uri, userID) {
  return (dispatch) => {
    // Storage.setItem(userID, uri);
    dispatch(requestSuccess(SET_PROFILE_PIC, uri));
  };
}

// export function subscribe(data) {
//   return (dispatch) => {
//     dispatch(apiStart());
//     Api.post("/subscription", data)
//       .then((response) => {
//         if (response.data.success) {
//           window.open(response.data.data, "_blank");
//           dispatch(requestSuccess(SUBSCRIBE));
//           dispatch(apiEnd());
//         } else {
//           dispatch(apiEnd());
//           dispatch(requestFail(response.data.message));
//         }
//       })
//       .catch((error) => {
//         dispatch(apiEnd());
//         dispatch(apiError(error.message));
//       });
//   };
// }

export function subscribe(data) {
  // return (dispatch) => {
  //   dispatch(apiStart());
  //   Api.post("/userSubscribeFavorite/:favoriteCityId", data)
  //     .then((response) => {
  //       if (response.data.success) {
  //         window.open(response.data.data, "_blank");
  //         dispatch(requestSuccess(SUBSCRIBE));
  //         dispatch(apiEnd());
  //       } else {
  //         dispatch(apiEnd());
  //         dispatch(requestFail(response.data.message));
  //       }
  //     })
  //     .catch((error) => {
  //       dispatch(apiEnd());
  //       dispatch(apiError(error.message));
  //     });
  // };
}

export function payLiens(data) {
  return (dispatch) => {
    dispatch(apiStart());
    Api.post("/paypalPaymentLien", data)
      .then((response) => {
        if (response.data.success) {
          window.open(response.data.data, "_blank");
          dispatch(requestSuccess(PAY_LIEN));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(apiEnd());
        dispatch(apiError(error.message));
      });
  };
}
