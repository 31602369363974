import { API_START, API_END, API_ERROR, API_FAILURE } from "./types";

export const apiStart = () => {
  return {
    type: API_START,
  };
};

export const apiEnd = () => {
  return {
    type: API_END,
  };
};

export const apiError = (data, type = API_ERROR) => {
  return {
    type,
    payload: { data },
  };
};

export function requestSuccess(type, data = null) {
  return { type, payload: { data } };
}

export function requestFail(errorCode) {
  return { type: API_FAILURE, payload: { data: errorCode } };
}
