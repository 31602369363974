import Api from "../../api";
import { apiStart, apiEnd, apiError, requestSuccess, requestFail } from "./api";
import {
  SELECT_MITIGATION,
  GET_MESSAGE,
  SEND_MESSAGE,
  SEND_MESSAGE_LOCAL,
  CLEAR_MITIGATION,
  ACCEPT_NEGOTIATION,
  CREATE_MITIGATION,
  GET_MITIGATIONS,
} from "./types";
import { get } from "lodash";
import { navigate } from "../../utils/navigation";

export function getMessage(message) {
  return (dispatch) => {
    dispatch(requestSuccess(GET_MESSAGE, message));
  };
}

export function sendMessage(message) {
  return (dispatch) => {
    dispatch(requestSuccess(SEND_MESSAGE_LOCAL, message));
    Api.post("/messagesCity", {
      id: message.id,
      senderId: message.senderId,
      mitigationId: message.mitigationId,
      lienId: message.lienId,
      timestamp: message.timestamp,
      type: message.type,
      text: message.text,
      url: message.url,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(SEND_MESSAGE, response.data.data));
        } else {
          dispatch(requestFail(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(apiError(error));
      });
  };
}

export function selectMitigation(mitigation, userId) {
  return (dispatch) => {
    if (get(mitigation, "status", "") === "Ready") {
      dispatch(requestSuccess(SELECT_MITIGATION, mitigation));
    } else {
      dispatch(apiStart());
      Api.post("/enterRoom", {
        userId,
        mitigationId: mitigation.id,
      })
        .then((response) => {
          if (response.data.success) {
            dispatch(requestSuccess(SELECT_MITIGATION, mitigation));
            dispatch(apiEnd());
          } else {
            dispatch(apiEnd());
            dispatch(requestFail(response.data.message));
          }
        })
        .catch((error) => {
          dispatch(apiEnd());
          dispatch(apiError(error));
        });
    }
  };
}

export function clearMitigation(mitigation, userId) {
  return (dispatch) => {
    if (get(mitigation, "status", "") === "Ready") {
      dispatch(requestSuccess(CLEAR_MITIGATION));
    } else {
      dispatch(apiStart());
      Api.post("/leaveRoom", {
        userId,
        mitigationId: mitigation.id,
      })
        .then((response) => {
          if (response.data.success) {
            dispatch(requestSuccess(CLEAR_MITIGATION));
            dispatch(apiEnd());
          } else {
            dispatch(apiEnd());
            dispatch(requestFail(response.data.message));
          }
        })
        .catch((error) => {
          dispatch(apiEnd());
          dispatch(apiError(error));
        });
    }
  };
}

export function completeNegotiation(oldMessageId, mitigationId, lienId, amount, type, userId) {
  return (dispatch) => {
    dispatch(apiStart());
    Api.post("/acceptNegotiation", {
      oldMessageId,
      mitigationId,
      lienId,
      amount,
      type,
      userId,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(ACCEPT_NEGOTIATION, response.data.data));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(apiEnd());
        dispatch(apiError(error));
      });
  };
}

export function createMitigation(payload) {
  return (dispatch) => {
    dispatch(apiStart());
    Api.post("/userPayments", payload)
      .then((response) => {
        console.log(response);
        console.log(response);
        if (response.data.success) {
          dispatch(requestSuccess(CREATE_MITIGATION, response.data.data));
          navigate("/mitigations", true);
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(apiEnd());
        dispatch(apiError(error));
      });
  };
}

export function completeNegotiationSocket(data) {
  return (dispatch) => {
    dispatch(requestSuccess(ACCEPT_NEGOTIATION, data));
  };
}

export function getMitigations(userId) {
  return (dispatch) => {
    dispatch(apiStart());
    Api.get(`/userMitigationCity/${userId}`)
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(GET_MITIGATIONS, response.data.data));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
        }
      })
      .catch((error) => {
        dispatch(apiEnd());
        dispatch(apiError(error));
      });
  };
}
