import Api from '../../api';
import { apiStart, apiEnd, apiError } from './api';

export function getDownloadedUrl(url) {
  return async (dispatch) => {
    try {
      dispatch(apiStart());
      return await Api.get(`/getReleasesCityPdfByKeyFile/${url}`, {
        responseType: 'blob',
      })
        .then((response) => {
          dispatch(apiEnd());
          const usableUrl = URL.createObjectURL(response.data);
          return [usableUrl, response.data];
        })
        .catch((error) => {
          dispatch(apiEnd());
          dispatch(apiError(error));
        });
    } catch (e) {
      console.log('ACTION ERROR', e);
    }
  };
}
