import React from "react";
import { Button, Icon } from "semantic-ui-react";

//Components
import Text from "../Text";

//Styles
import styles from "./styles.module.css";

const StatusButton = ({ status, disabled = false, handleClick }) => {
  const icon = status === "Ready" ? "checkmark" : status === "Pending" ? "stop circle" : "eye";
  const color = status === "Ready" ? "var(--primary)" : status === "Pending" ? "var(--red500)" : "var(--gray700)";

  return (
    <Button className={styles.button} disabled={disabled} onClick={handleClick} style={{ backgroundColor: color }}>
      <Icon className={styles.icon} name={icon} />
      <Text className={styles.text}>{status}</Text>
    </Button>
  );
};

export default StatusButton;
