// React
import React from "react";
import { Icon } from "semantic-ui-react";

// Components
import Text from "../../../components/Text";

// Utils
import { currencyFormat, dateFormat } from "../../../constants/Utils";

import styles from "./styles.module.css";

const InfoBit = ({ icon, category, value, uppercase = false, type = "string" }) => {
  let typedValue = value;
  switch (type) {
    case "currency":
      typedValue = currencyFormat(value, false);
      break;
    case "date":
      typedValue = dateFormat(value);
      break;
    case "link":
      typedValue = "Click Here";
      break;
    default:
      typedValue = value;
      break;
  }
  return (
    <div className={styles.infoBit}>
      <div className={styles.iconContainer}>
        <Icon className={styles.infoIcon} name={icon} />
      </div>
      <div className={styles.infoText}>
        <Text className={`${styles.title} ${uppercase ? styles.upper : styles.capital}`}>{category}</Text>
        <Text
          className={`${styles.text} ${uppercase ? styles.upper : styles.capital} ${
            type === "link" ? styles.hyperlink : ""
          }`}
          as={type === "link" ? "a" : "h5"}
          href={type === "link" ? value : null}
          target={"_blank"}>
          {typedValue}
        </Text>
      </div>
    </div>
  );
};

export default InfoBit;
