import React, { useEffect, useRef } from "react";
import { Container, Button, Image, Icon, Popup, Menu, Label } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter, faLinkedin, faInstagram, faTiktok, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { map, includes } from "lodash";

//Logo
import Logo from "../../assets/LienLibraryLogo.svg";
import Avatar from "../../assets/avatar.png";

//Redux
import { connect } from "react-redux";
import { logout } from "../../redux/actions/user";

//Hooks
import { useWindowSize } from "../../hooks/useWindowSize";

//Constants
import { headerLinks, dashboardLinks } from "../../constants/Menu";

//Components
import Text from "../Text";
import SearchBar from "../SearchBar";

//Styles
import styles from "./styles.module.css";

const Header = ({
	dispatch,
	isLoggedIn,
	userName,
	favoritesCount,
	reportsCount,
	mitigationsCount,
	paymentsCount,
	cartCount,
	alarmsCount,
	searchBar = false,
}) => {
	const activeButton = useRef(null);
	const windowSize = useWindowSize();
	const isMobile = windowSize.width <= 768;
	const currentWindow = window.location.pathname;
	const current = currentWindow === "/";

	useEffect(() => {
		if (activeButton.current) activeButton.current.ref.current.style.setProperty("opacity", "1", "important");
	}, []);

	const handleLogout = (e) => {
		e.preventDefault();
		dispatch(logout());
	};

	const renderButtons = (headerLinks, isLoggedIn) => {
		const array = isLoggedIn ? headerLinks.private : headerLinks.public;
		const buttons = map(array, (item, index) => {
			const current = currentWindow === item.path;
			// if (item.name === "For Government") {
			//   return (
			//     <Button
			//       key={index}
			//       as={"a"}
			//       href={item.path}
			//       className={current ? styles.hyperlinkActive : styles.hyperlink}
			//       active={current}
			//       disabled={current}
			//     >
			//       {item.name}
			//     </Button>
			//   );
			// }
			return (
				<Button
					key={index}
					ref={current ? activeButton : undefined}
					as={Link}
					to={item.path}
					className={current ? styles.hyperlinkActive : styles.hyperlink}
					active={current}
					disabled={current}>
					{item.name}
				</Button>
			);
		});
		return buttons;
	};

	const renderMenu = (headerLinks, dashboardLinks, isLoggedIn, isMobile) => {
		const array = isLoggedIn ? headerLinks.private : headerLinks.public;
		let menuItems = [];
		if (isMobile) {
			menuItems = [...menuItems, ...array];
		}
		if (isLoggedIn) {
			menuItems = [...menuItems, ...dashboardLinks];
		}

		const items = map(menuItems, (item, index) => {
			if (item.name !== "Log out") {
				const current = currentWindow === item.path;
				const withLabel = includes(["Cart", "Favorites", "Reports", "Mitigations", "Payments", "Alarm Registration"], item.name);
				let count = 0;
				if (withLabel) {
					switch (item.name) {
						case "Cart":
							count = cartCount;
							break;
						case "Favorites":
							count = favoritesCount;
							break;
						case "Reports":
							count = reportsCount;
							break;
						case "Mitigations":
							count = mitigationsCount;
							break;
						case "Payments":
							count = paymentsCount;
							break;
						case "Alarm Registration":
							count = alarmsCount;
							break;
						default:
							break;
					}
				}
				return (
					<Menu.Item
						key={index}
						ref={current ? activeButton : undefined}
						as={Link}
						to={item.path}
						className={styles.mobileMenuItem}
						disabled={current}>
						<div className={styles.item}>
							<Icon className={current ? styles.activeIcon : styles.inactiveIcon} name={item.icon} />
							<Text className={current ? styles.hyperlinkActive : styles.hyperlink}>{item.name}</Text>
						</div>
						{withLabel && (
							<Label className={styles.badge} color={"red"}>
								{count}
							</Label>
						)}
					</Menu.Item>
				);
			} else {
				return (
					<Menu.Item key={index} className={styles.mobileMenuItem} onClick={handleLogout}>
						<div className={styles.item}>
							<Icon className={styles.inactiveIcon} name={item.icon} />
							<Text className={styles.hyperlink}>{item.name}</Text>
						</div>
					</Menu.Item>
				);
			}
		});
		return items;
	};

	return (
		<div className={styles.topContainer}>
			<div className={styles.blackBar}>
				<Container className={styles.row}>
					<div className={styles.contactSide}>
						<a className={styles.blackBarContent} href={"tel:+17865304422"}>
							<Icon name={"phone"} className={styles.icon} />
							<Text className={styles.blackBarhyperLink}>{"+1 (786) 530-4422"}</Text>
						</a>
						<a className={styles.blackBarContent} href={"mailto:info@lienlibrary.com"}>
							<Icon name={"mail outline"} className={styles.icon} />
							<Text className={styles.blackBarhyperLink}>{"info@lienlibrary.com"}</Text>
						</a>
					</div>
					<div className={styles.socialMediaSide}>
						<Button as={"a"} href={"https://www.tiktok.com/@lienlibrary"} target={"_blank"} className={styles.button}>
							<FontAwesomeIcon icon={faTiktok} className={styles.socialIcon} />
						</Button>
						<Button as={"a"} href={"https://x.com/lienlibrary"} target={"_blank"} className={styles.button}>
							<FontAwesomeIcon icon={faXTwitter} className={styles.socialIcon} />
						</Button>
						<Button as={"a"} href={"https://www.instagram.com/lienlibrary"} target={"_blank"} className={styles.button}>
							<FontAwesomeIcon icon={faInstagram} className={styles.socialIcon} />
						</Button>
						<Button as={"a"} href={"https://www.facebook.com/lienlibrary"} target={"_blank"} className={styles.button}>
							<FontAwesomeIcon icon={faFacebook} className={styles.socialIcon} />
						</Button>
						<Button as={"a"} href={"https://www.linkedin.com/company/lienlibrary"} target={"_blank"} className={styles.button}>
							<FontAwesomeIcon icon={faLinkedin} className={styles.socialIcon} />
						</Button>
					</div>
				</Container>
			</div>
			<div className={styles.header}>
				<Container className={styles.row}>
					<div className={styles.logoContainer}>
						<Image as={Link} to={"/"} src={Logo} className={styles.logo} disabled={current} />
					</div>
					<div className={styles.hyperlinkRow}>
						{renderButtons(headerLinks, isLoggedIn)}
						{isLoggedIn && (
							<Popup
								hoverable
								basic
								position={"bottom right"}
								trigger={
									<div className={styles.profileHyperlink}>
										<Image avatar src={Avatar} className={styles.avatar} />
										<Button as={Link} to={"/profile"} className={styles.hyperlink}>
											{userName}
										</Button>
									</div>
								}>
								<Popup.Content>
									<Menu vertical>{renderMenu(headerLinks, dashboardLinks, isLoggedIn, isMobile)}</Menu>
								</Popup.Content>
							</Popup>
						)}
					</div>
					<Popup
						hoverable
						basic
						position={"bottom right"}
						positionFixed
						on={"click"}
						trigger={<Icon name={"bars"} className={styles.burgerMenu} />}>
						<Popup.Content>
							<Menu className={styles.mobileMenu} vertical>
								{renderMenu(headerLinks, dashboardLinks, isLoggedIn, isMobile)}
							</Menu>
						</Popup.Content>
					</Popup>
				</Container>
			</div>
			{searchBar && (
				<div className={styles.searchBar}>
					<Container>
						<SearchBar />
					</Container>
				</div>
			)}
		</div>
	);
};

function mapStateToProps({ user, favorites, reports, mitigations, payments, cart, alarms }) {
	return {
		isLoggedIn: user.isLoggedIn,
		userName: user.name,
		favoritesCount: favorites.favoriteList.length,
		reportsCount: reports.reportList.length,
		mitigationsCount: mitigations.mitigationList.length,
		paymentsCount: payments.paymentList.length,
		alarmsCount: alarms.alarmList.length,
		cartCount: cart.cartList.length,
	};
}

export default connect(mapStateToProps)(Header);
