/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { isEmpty, map } from "lodash";

//Redux
import { connect } from "react-redux";
// import { getAlarms } from "../../redux/actions/alarms";

//Components
import Screen from "../../components/Screen";
import AlarmRegistrationCard from "../../components/AlarmRegistrationCard";

//Styles
import styles from "./styles.module.css";

const AlarmRegistrations = ({ dispatch, alarmList, userId }) => {
  // useEffect(() => {
  //   dispatch(getAlarms(userId));
  // }, []);

  const renderItems = (array) => {
    if (!isEmpty(array)) {
      const items = map(array, (item, index) => {
        const stripped = index % 2 === 0;
        return <AlarmRegistrationCard key={index} item={item} stripped={stripped} />;
      });
      return items;
    } else {
      return null;
    }
  };

  return (
    <Screen
      searchBar
      fixedHeight
      title={"Alarm Registrations"}
      meta="Connect with your local government to negotiate the lien on your house through mitigation: from code compliance lien, utility lien, assessment lien to permit">
      <div className={styles.content}>{renderItems(alarmList)}</div>
    </Screen>
  );
};

function mapStateToProps({ alarms, user }) {
  return { alarmList: alarms.alarmList, userId: user.id };
}

export default connect(mapStateToProps)(AlarmRegistrations);
