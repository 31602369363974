import React, { PureComponent } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * PublicRoute Component
 * @augments {PureComponent<Props>}
 */
class PublicRoute extends PureComponent {
  static propTypes = {
    exact: PropTypes.bool,
    restricted: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    path: PropTypes.string.isRequired,
    component: PropTypes.any.isRequired,
  };

  static defaultProps = {
    exact: false,
    restricted: false,
    isLoggedIn: false,
  };

  render() {
    const { restricted, isLoggedIn } = this.props;
    if (isLoggedIn && restricted) {
      const storedRoute = localStorage.getItem("route");
      if (storedRoute) {
        const parsedProps = JSON.parse(storedRoute);
        return <Redirect to={parsedProps.path} />;
      } else {
        return <Redirect to={"/profile"} />;
      }
    } else {
      return <Route {...this.props} />;
    }
  }
}

export default PublicRoute;
