/* Data containing the images to use for the main section of the site */

import FloridaSuburb from "../../assets/FloridaSuburb.png";
import MiamiBeach from "../../assets/MiamiBeach.png";
import MiamiDowntown from "../../assets/MiamiDowntown.png";
import MiamiDowntown2 from "../../assets/MiamiDowntown2.png";

export const SliderData = [
    {
        image: FloridaSuburb
    },
    {
        image: MiamiBeach
    },
    {
        image: MiamiDowntown
    },
    {
        image: MiamiDowntown2
    }
]
