import { DISMISS_ERROR } from "./types";

function requestSuccess(type, data = {}) {
  return { type, payload: { data } };
}

export function dismissErrors() {
  return (dispatch) => {
    dispatch(requestSuccess(DISMISS_ERROR));
  };
}
