import {
  LOGIN,
  CHECK_SESSION,
  REHYDRATE,
  LOGOUT,
  SELECT_MITIGATION,
  CLEAR_MITIGATION,
  GET_MESSAGE,
  SEND_MESSAGE,
  SEND_MESSAGE_LOCAL,
  ACCEPT_NEGOTIATION,
  CREATE_MITIGATION,
  GET_MITIGATIONS,
} from "../actions/types";
import { map, orderBy } from "lodash";

export default function mitigations(state = { mitigationList: [], currentMitigation: {} }, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
      const ordered = orderBy(
        map(payload.data.user.mitigations, (item) => {
          return {
            ...item,
            messages: orderBy(item.messages, ["id"], ["asc"]),
          };
        }),
        ["createdAt"],
        ["desc"]
      );
      return { ...state, mitigationList: ordered, currentMitigation: {} };

    case CHECK_SESSION:
      return {
        ...state,
        mitigationList: payload.data.mitigations.mitigationList,
        currentMitigation: payload.data.mitigations.currentMitigation,
      };

    case REHYDRATE:
      const ordered2 = orderBy(
        map(payload.data.user.mitigations, (item) => {
          return {
            ...item,
            messages: orderBy(item.messages, ["id"], ["asc"]),
          };
        }),
        ["createdAt"],
        ["desc"]
      );
      return {
        ...state,
        mitigationList: ordered2,
      };

    case LOGOUT:
      return { ...state, mitigationList: [], currentMitigation: {} };

    case GET_MITIGATIONS: {
      const ordered2 = orderBy(
        map(payload.data, (item) => {
          return {
            ...item,
            messages: orderBy(item.messages, ["id"], ["asc"]),
          };
        }),
        ["createdAt"],
        ["desc"]
      );
      return {
        ...state,
        mitigationList: ordered2,
      };
    }

    case SELECT_MITIGATION:
      return { ...state, currentMitigation: payload.data };

    case CLEAR_MITIGATION:
      return {
        ...state,
        currentMitigation: {},
      };

    case SEND_MESSAGE_LOCAL:
      const mappedMitigations = map(state.mitigationList, (item) => {
        if (item.id === payload.data.mitigationId)
          return { ...item, messages: [...item.messages, payload.data] };
        return item;
      });
      const currentMitigation = {
        ...state.currentMitigation,
        messages: [...state.currentMitigation.messages, payload.data],
      };
      return {
        ...state,
        mitigationList: mappedMitigations,
        currentMitigation: currentMitigation,
      };

    case SEND_MESSAGE:
      const cleanMsgs = map(state.mitigationList, (item) => {
        if (item.id === payload.data.tempResult.mitigationId) {
          return {
            ...item,
            messages: orderBy(
              map(item.messages, (itemX) => {
                if (itemX.id === payload.data.id) {
                  return payload.data.tempResult;
                }
                return itemX;
              }),
              ["id"],
              ["asc"]
            ),
          };
        }
        return item;
      });
      const cleanCurrent = {
        ...state.currentMitigation,
        messages: orderBy(
          map(state.currentMitigation.messages, (item) => {
            if (item.id === payload.data.id) {
              return payload.data.tempResult;
            }
            return item;
          }),
          ["id"],
          ["asc"]
        ),
      };
      return {
        ...state,
        mitigationList: cleanMsgs,
        currentMitigation: cleanCurrent,
      };

    case GET_MESSAGE:
      const cleanMsgs2 = map(state.mitigationList, (item) => {
        if (item.id === payload.data.mitigationId) {
          return {
            ...item,
            messages: orderBy([...item.messages, payload.data], ["id"], ["asc"]),
          };
        }

        return item;
      });
      return {
        ...state,
        mitigationList: cleanMsgs2,
        currentMitigation: {
          ...state.currentMitigation,
          messages: orderBy([...state.currentMitigation.messages, payload.data], ["id"], ["asc"]),
        },
      };

    case ACCEPT_NEGOTIATION:
      const cleanMsgs3 = map(state.mitigationList, (item) => {
        if (item.id === payload.data.id) {
          return {
            ...payload.data,
            messages: orderBy(payload.data.messages, ["id"], ["asc"]),
          };
        }

        return item;
      });
      return {
        ...state,
        mitigationList: cleanMsgs3,
        currentMitigation: {
          ...payload.data,
          messages: orderBy(payload.data.messages, ["id"], ["asc"]),
        },
      };

    case CREATE_MITIGATION:
      return {
        ...state,
        mitigationList: orderBy([...state.mitigationList, payload.data], ["createdAt"], ["desc"]),
      };

    default:
      return state;
  }
}
