import React, { PureComponent } from "react";
import { Container, Grid } from "semantic-ui-react";

//Redux
import { connect } from "react-redux";
import { register } from "../../redux/actions/user";

//Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import RegisterForm from "../../Forms/Register";

//Styles
import styles from "./styles.module.css";

class Register extends PureComponent {
  componentDidMount = () => {};

  register = (user) => {
    this.props.dispatch(register(user));
  };
  render() {
    return (
      <div className={styles.screen}>
        <Header />
        <div className={styles.body}>
          <div className={styles.black}>
            <Container className={styles.content}>
              <Grid className={styles.gridContainer} textAlign="center">
                <Grid.Column style={{ maxWidth: 450 }}>
                  <RegisterForm onSubmit={this.register} />
                </Grid.Column>
              </Grid>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect()(Register);
