import React from "react";
import { Header } from "semantic-ui-react";

//Styles
import styles from "./styles.module.css";

const Text = ({ className = "", children, as, href = undefined, target }) => {
  return (
    <Header as={as || "h5"} className={`${styles.text} ${className}`} href={href} target={target}>
      {children}
    </Header>
  );
};

export default Text;
