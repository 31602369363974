import React, { PureComponent } from "react";
import { Icon, Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import { isEmpty, map, get } from "lodash";
import moment from "moment";

//Components
import Text from "../Text";

//Constants
import { currencyFormat, getDateLabel } from "../../constants/Utils";

//Styles
import styles from "./styles.module.css";

class BillBox extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    first: PropTypes.bool.isRequired,
    isGoodStanding: PropTypes.bool.isRequired,
    exclude: PropTypes.bool.isRequired,
    items: PropTypes.array,
    asOf: PropTypes.string,
  };

  renderRows = (items, title, asOf, exclude) => {
    if (exclude && isEmpty(items)) {
      return (
        <div className={styles.contentContainer}>
          <Text className={styles.emptyText}>{`We do not provide this service.`}</Text>
        </div>
      );
    }
    if (isEmpty(items)) {
      return (
        <div className={styles.contentContainer}>
          <Text className={styles.emptyText}>{`No ${title} found as of ${getDateLabel(
            asOf ? asOf : moment()
          )}`}</Text>
        </div>
      );
    }

    const rows = map(items, (item, index) => {
      const previousBalance = get(item, "previousBalance", 0);
      const currentBalance = get(item, "currentBalance", 0);
      const estimatedBalance = get(item, "estimatedAmount", 0);
      const totalBalance = previousBalance + currentBalance + estimatedBalance;
      return (
        <div key={index} className={styles.contentContainer}>
          <Grid stackable>
            <Grid.Row className={styles.gridRow} columns={3}>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Type: "}</Text>
                  <Text className={styles.text}>{item.type}</Text>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Account Type: "}</Text>
                  <Text className={styles.text}>{item.accountType}</Text>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Account Number: "}</Text>
                  <Text className={styles.text}>{item.accountNumber}</Text>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={styles.gridRow} columns={3}>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Billing Date: "}</Text>
                  <Text className={styles.text}>{getDateLabel(item.billingDate)}</Text>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Previous Balance: "}</Text>
                  <Text className={styles.text}>{currencyFormat(parseFloat(previousBalance))}</Text>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Current Balance: "}</Text>
                  <Text className={styles.text}>{currencyFormat(parseFloat(currentBalance))}</Text>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={styles.gridRow} columns={3}>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Estimated Date: "}</Text>
                  <Text className={styles.text}>{getDateLabel(item.estimatedDate)}</Text>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Estimated Amount: "}</Text>
                  <Text className={styles.text}>
                    {currencyFormat(parseFloat(estimatedBalance))}
                  </Text>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Total Due: "}</Text>
                  <Text className={styles.text}>{currencyFormat(parseFloat(totalBalance))}</Text>
                </div>
              </Grid.Column>
            </Grid.Row>
            {!isEmpty(item.attachments) && (
              <Grid.Row className={styles.gridRow} columns={1}>
                <Grid.Column>
                  <div className={styles.textRow}>
                    <Text className={styles.text}>{"This Bill has attachments below"}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </div>
      );
    });
    return rows;
  };

  render() {
    const { title, first, isGoodStanding, items, asOf, exclude } = this.props;
    return (
      <div className={first ? styles.firstContainer : styles.container}>
        <div className={styles.row}>
          <div className={styles.titleContainer}>
            <Text className={styles.titleText}>{title}</Text>
          </div>
          {!exclude && (
            <div className={styles.statusContainer}>
              <Icon
                className={styles.icon}
                name={isGoodStanding ? "check circle" : "warning sign"}
                size={"large"}
                color={isGoodStanding ? "green" : "red"}
              />
              <Text className={styles.boldText}>
                {isGoodStanding ? "Good Standing" : `${title} Found`}
              </Text>
            </div>
          )}
        </div>
        {this.renderRows(items, title, asOf, exclude)}
      </div>
    );
  }
}

export default BillBox;
