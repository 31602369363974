import React from "react";
import { Grid } from "semantic-ui-react";
import { get } from "lodash";

//Components
import Text from "../Text";

//Utils
import { currencyFormat } from "../../constants/Utils";
import { calculateInterest } from "../../utils/business";

//Styles
import styles from "./styles.module.css";

const LienProgressCard = ({ item }) => {
  return (
    <div className={styles.item}>
      <Grid className={styles.grid}>
        <Grid.Row columns={3} className={styles.gridRow}>
          <Grid.Column width={5}>
            <Text className={styles.boldText}>{"Case Number"}</Text>
            <Text className={styles.text}>{item.caseNumber}</Text>
          </Grid.Column>
          <Grid.Column width={6}>
            <Text className={styles.boldText}>{"Lien Type"}</Text>
            <Text className={styles.text}>{item.type}</Text>
          </Grid.Column>
          <Grid.Column width={5}>
            <Text className={styles.boldText}>{"Total Due"}</Text>
            <Text className={styles.text}>{currencyFormat(calculateInterest(item))}</Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className={styles.gridRow}>
          <Grid.Column width={5}>
            <Text className={styles.boldText}>{"Status"}</Text>
            <Text className={styles.text}>{item.resolution ? "Resolved" : "Pending"}</Text>
          </Grid.Column>
          {item.resolution && (
            <Grid.Column width={11}>
              <Text className={styles.boldText}>{"Resolution"}</Text>
              <Text className={styles.text}>{get(item, "resolution", "")}</Text>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default LienProgressCard;
