import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import { API_FAILURE, ADD_FAVORITE, REMOVE_FAVORITE } from "./types";

function requestSuccess(type, data = null) {
  return { type, payload: { data } };
}

function requestFail(errorCode) {
  return { type: API_FAILURE, payload: { data: errorCode } };
}

export function addFavorite(userId, propertyId) {
  // const payload = {
  //   usersCityId: userId,
  //   propertyCityId: propertyId,
  // };

  const payload = {
    usersCityId: 1,
    propertyCityId: 146,
  };
  return (dispatch) => {
    dispatch(apiStart());
    Api.post("/addFavoriteToUser", payload)
      .then((resp) => {
        if (resp.data.success) {
          dispatch(requestSuccess(ADD_FAVORITE, resp.data.data));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(resp.data.message));
        }
      })
      .catch((error) => {
        dispatch(apiError(error));
        dispatch(apiEnd());
      });
  };
}

export function removeFavorite(favoriteID) {
  return (dispatch) => {
    dispatch(apiStart());
    Api.post("/addFavoriteToUser", { _id: favoriteID })
      .then((resp) => {
        if (resp.data.success) {
          dispatch(requestSuccess(REMOVE_FAVORITE, favoriteID));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(resp.data.message));
        }
      })
      .catch((error) => {
        dispatch(apiError(error));
        dispatch(apiEnd());
      });
  };
}
