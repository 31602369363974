// React
import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import { get } from "lodash";

// Components
import Text from "../../../components/Text";
import InfoBit from "../InfoBit";

// Utils
import { calculateInterest } from "../../../utils/business";

import styles from "./styles.module.css";

const LienCard = ({ lien }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive((prev) => !prev);
  };

  return (
    <div className={styles.container}>
      <div className={styles.cardTop}>
        <div className={styles.iconContainer}>
          <Icon className={styles.icon} name="folder outline" />
          <Text className={styles.boldText}>Case No.</Text>
        </div>
        <Text className={styles.text}>{get(lien, "caseNumber", "")}</Text>
      </div>
      <Accordion className={styles.accordionContainer}>
        <Accordion.Title active={isActive} className={styles.accordionTitle} onClick={handleClick}>
          <Text className={styles.accordionText}>{isActive ? "Show Less" : "Show More"}</Text>
          <Icon className={styles.accordionIcon} name={isActive ? "angle up" : "angle down"} />
        </Accordion.Title>
        <Accordion.Content active={isActive} className={styles.lienCardAccordionContent}>
          <InfoBit
            icon={"question circle outline"}
            category={"Lien Type"}
            value={get(lien, "type", "")}
          />
          <InfoBit
            icon={"dollar"}
            category={"Fine"}
            type="currency"
            value={calculateInterest(lien)}
          />
          <InfoBit icon={"user outline"} category={"Lienor"} value={get(lien, "lienor", "")} />
          <InfoBit icon={"user outline"} category={"Lienee"} value={get(lien, "lienee", "")} />
          <InfoBit
            icon={"calendar alternate outline"}
            category={"Date Recorded"}
            type="date"
            value={get(lien, "dateRecorded", "")}
          />
          <InfoBit icon={"book"} category={"Book"} value={get(lien, "book", "")} />
          <InfoBit icon={"file outline"} category={"Page"} value={get(lien, "page", "")} />
          <InfoBit
            icon={"file archive outline"}
            category={"Instrument No."}
            value={get(lien, "instrumentNumber", "")}
          />
          <InfoBit
            icon={"comment outline"}
            category={"Description"}
            value={get(lien, "description", "")}
          />
          <InfoBit
            icon={"linkify"}
            category={"Link"}
            type="link"
            value={get(lien, "document", "")}
          />
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default LienCard;
