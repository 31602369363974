import { isEmpty } from "lodash";
import moment from "moment";

export function currencyFormat(num) {
  if (num !== undefined && typeof num === "string") {
    const newNum = parseFloat(num);
    return "$" + newNum.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else if (num === null) {
    return "";
  } else if (num !== undefined && num !== null) return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function dateFormat(date) {
  if (date !== undefined && date !== null) {
    const today = new Date(date);
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
  } else {
    return "";
  }
}

export function getDateLabel(date) {
  if (isEmpty(date)) return "";
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

export function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

export function formatDate(date, format = "MM/DD/YYYY") {
  if (date) {
    return moment(date).format(format);
  }
  return "";
}
