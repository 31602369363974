import React, { PureComponent } from "react";
import { Container } from "semantic-ui-react";
import { isEmpty, map } from "lodash";

//Redux
import { connect } from "react-redux";

//Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Dashboard from "../../components/Dashboard";
import ReportsCard from "../../components/ReportsCard";

//Styles
import styles from "./styles.module.css";

class MyProperties extends PureComponent {
  renderUserProperties = (array) => {
    if (!isEmpty(array)) {
      const reports = map(array, (item, index) => {
        const stripped = index % 2 === 0;
        return <ReportsCard key={index} item={item} stripped={stripped} />;
      });
      return reports;
    } else {
      return null;
    }
  };

  render() {
    return (
      <div className={styles.screen}>
        <Header searchBar={true} />
        <div className={styles.body}>
          <Container className={styles.container}>
            <Dashboard />
            <div className={styles.content}>
              {this.renderUserProperties(this.props.userProperties)}
              {/* <Button
                style={{
                  width: "35%",
                  margin: "2em auto",
                  color: "#fff",
                  backgroundColor: "#78c71f",
                }}
                size="medium"
              >
                Add New Property
              </Button> */}
            </div>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps({ userProperties }) {
  return { userProperties: userProperties.propertyList };
}

export default connect(mapStateToProps)(MyProperties);
