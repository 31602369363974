import { combineReducers } from "redux";

//Reducers
import loading from "./loading";
import errors from "./errors";
import user from "./user";
import favorites from "./favorites";
import properties from "./properties";
import lienTypes from "./lienTypes";
import reports from "./reports";
import userProperties from "./userProperties";
import mitigations from "./mitigations";
import payments from "./payments";
import cart from "./cart";
import alarms from "./alarms";

export default combineReducers({
  loading,
  errors,
  user,
  favorites,
  properties,
  lienTypes,
  reports,
  userProperties,
  mitigations,
  payments,
  cart,
  alarms,
});
