import { API_FAILURE, DISMISS_ERROR, API_ERROR } from "../actions/types";

export default function errors(
  state = { isError: false, message: "" },
  action
) {
  const { type, payload } = action;
  switch (type) {
    case API_FAILURE:
      return {
        ...state,
        isError: true,
        message: payload.data,
      };

    case API_ERROR:
      return {
        ...state,
        isError: true,
        message: payload.data,
      };

    case DISMISS_ERROR:
      return { ...state, isError: false, message: "" };

    default:
      return state;
  }
}
