/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Grid,
  Input,
  TextArea,
  Label,
  Form,
  Button,
  Icon,
  Divider,
} from "semantic-ui-react";
import { isEmpty, includes, filter, map, some, get } from "lodash";
import Api from "../../api";

//Redux
import { connect } from "react-redux";
import { addCart } from "../../redux/actions/cart";

//Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Text from "../../components/Text";
import FormInput from "../../components/FileInput";
import CityHeader from "../../components/CityHeader";
import MitigationBox from "../../components/MitigationBox";
import Box from "../../components/Box";
import PaypalButton from "../../components/PaypalButton";

//Utils
import { currencyFormat } from "../../constants/Utils";
import { navigate } from "../../utils/navigation";

//Styles
import styles from "./styles.module.css";
import { round } from "../../utils/format";

const LIEN_LIBRARY_SERVICE_FEE = 0;

const LienMitigationRequest = ({ dispatch, currentReport, user, cartList }) => {
  const [isHomestead, setIsHomestead] = useState(false);
  const [file, setFile] = useState(null);
  const [totalFees, setTotalFees] = useState(0);
  const [liens, setLiens] = useState([]);
  const liensRef = useRef();
  const fileRef = useRef();
  const homesteadRef = useRef();

  useEffect(() => {
    const validLiens = map(
      filter(currentReport.liens, (item) => {
        return item.type === "Code Lien" && item.dateComplied;
      }),
      (item) => {
        return { ...item, offeredAmount: 0, comment: "" };
      }
    );
    setLiens(validLiens);
    liensRef.current = validLiens;
    const cityFeesParsed = parseFloat(currentReport.fees);
    setTotalFees(LIEN_LIBRARY_SERVICE_FEE + cityFeesParsed);
  }, [currentReport.id]);

  const handleAddToCart = (total) => {
    const { cityId, propertyId, fees } = currentReport;
    const x = {
      type: "Mitigations",
      amount: total,
      userId: user.id,
      propertyId,
      cityId,
      serviceType: 3,
      reportId: currentReport.id,
      dataField: {
        isHomestead,
        fees,
        liens,
        file,
      },
    };

    dispatch(addCart(x));
  };

  const isAlreadyInCart = () => {
    if (isEmpty(cartList)) return false;
    const results = cartList.filter((item) => item.type === "Mitigations");
    const items = results.map((item) => item.reportId);
    const exist = includes(items, currentReport.id);
    return exist;
  };

  const onCreateOrder = async () => {
    const { fees, cityId, propertyId } = currentReport;
    const actualCart = [
      {
        cityId,
        propertyId,
        type: "Mitigations",
        dataField: {
          isHomestead: homesteadRef.current,
          fees,
          liens: liensRef.current,
          file: fileRef.current,
        },
      },
    ];
    const order = await Api.post("/createOrder", { cart: actualCart });
    return order.data.id;
  };

  const onApproveOrder = async (data) => {
    await Api.post("/capturePayment", { orderId: data.orderID });
    navigate("/mitigations");
  };

  const handleChange = (item, value, field) => {
    const { id } = item;

    let newLienItem = { ...item };
    switch (field) {
      case "offeredAmount":
        newLienItem = { ...newLienItem, offeredAmount: parseFloat(value) };
        break;
      case "comment":
        newLienItem = { ...newLienItem, comment: value };
        break;
      default:
        break;
    }
    const mappedLiens = map(liens, (item) => {
      if (item.id === id) {
        return { ...item, ...newLienItem };
      }
      return item;
    });
    setLiens(mappedLiens);
    liensRef.current = mappedLiens;
  };

  const renderCodeViolationPanel = (item) => {
    if (isEmpty(item)) return null;
    return (
      <>
        <Box title={`Case: ${item.caseNumber}`}>
          <MitigationBox item={item} />
          <Grid stackable columns="2">
            <Grid.Column width="8">
              <div className={styles.textRow}>
                <Text className={styles.boldText}>{"Amount Requested: "}</Text>
                <Input
                  labelPosition="right"
                  type="number"
                  placeholder="Amount"
                  value={item.offeredAmount}
                  onChange={(e) => handleChange(item, e.target.value, "offeredAmount")}>
                  <Label basic>$</Label>
                  <input />
                </Input>
              </div>
            </Grid.Column>
            <Grid.Column width="8">
              <Text className={styles.boldText}>{"Comment: "}</Text>
              <TextArea
                rows="3"
                style={{
                  width: "100%",

                  borderRadius: "5px",
                  borderColor: "#bdbdbd",
                }}
                value={item.comment}
                onChange={(e) => handleChange(item, e.target.value, "comment")}
              />
            </Grid.Column>
          </Grid>
        </Box>
      </>
    );
  };

  const renderPropertyPanel = (property) => {
    if (isEmpty(property)) return null;
    return (
      <Box title={"Order #"}>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <div className={styles.textRow}>
                <Text className={styles.boldText}>{"Folio: "}</Text>
                <Text className={styles.text}>{property.folio}</Text>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className={styles.textRow}>
                <Text className={styles.boldText}>{"Address: "}</Text>
                <Text className={styles.text}>{String(property.address).toUpperCase()}</Text>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Box>
    );
  };

  const handleHomesteadChange = () => {
    setIsHomestead((prev) => !prev);
    homesteadRef.current = !isHomestead;
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    fileRef.current = e.target.files[0];
  };

  const { property, data } = currentReport;
  //const paypalFees = (3.49 / 100) * totalMitigationFees + 0.49;
  // const total = round(paypalFees + totalMitigationFees, 2);
  const total = round(totalFees, 2);
  const paypalDisabled = some(liens, (item) => {
    const offeredAmount = get(item, "offeredAmount", 0);
    return offeredAmount <= 0 || offeredAmount === "";
  });
  return (
    <div className={styles.screen}>
      <Header searchBar={true} />
      <div className={styles.body}>
        <Container style={{ backgroundColor: "#fff", padding: "2em" }}>
          <div className={styles.headerContainer}>
            <Text className={styles.title}>{"Lien Mitigation Request"}</Text>
          </div>
          <CityHeader cityObj={!isEmpty(data) ? data : null} />
          {renderPropertyPanel(property)}

          {liens.map((lien, index) => {
            return <div key={index}>{renderCodeViolationPanel(lien)}</div>;
          })}

          <Form style={{ margin: "1em 0" }}>
            <Form.Group inline>
              <label>Is Homestead</label>
              <Form.Checkbox value="" onChange={handleHomesteadChange} />
            </Form.Group>
          </Form>
          {isHomestead && (
            <>
              <p>
                If yes submit proof of Homestead, including tax bill, utility bills, and copy of
                drivers license
              </p>
              <div style={{ textAlign: "center" }}>
                <FormInput label="Upload Document" size="small" onChange={handleFileChange} />
              </div>
            </>
          )}

          <div style={{ textAlign: "left", margin: "1em 0" }}>
            {/* <div className={styles.totalContainer}>
                <Text className={styles.text}>{"Cost:"}&nbsp;</Text>
                <Text className={styles.fees}>{currencyFormat(totalMitigationFees)}</Text>
              </div> */}
            {/* <div className={styles.totalContainer}>
                <Text className={styles.text}>{"Paypal Processing Fees:"}&nbsp;</Text>
                <Text className={styles.fees}>{currencyFormat(paypalFees)}</Text>
              </div> */}
            <div className={styles.totalContainer}>
              <Text className={styles.totalTitleText}>{"Total:"}</Text>
              <Text className={styles.totalText}>{currencyFormat(total)}</Text>
            </div>
            <div>
              <Divider horizontal>Payment Methods</Divider>
              <div className={styles.paymentContainer}>
                <div className={styles.payment}>
                  <PaypalButton
                    amount={total}
                    onCreateOrder={onCreateOrder}
                    onApproveOrder={onApproveOrder}
                    disabled={paypalDisabled}
                  />
                </div>
                <Divider vertical>OR</Divider>
                <div className={styles.payment}>
                  <Button
                    onClick={() => handleAddToCart(total)}
                    disabled={isAlreadyInCart()}
                    className={styles.button}>
                    <Icon name={"cart"} />
                    &nbsp; ADD TO CART
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* {notification.message && (
              <div className={styles.notifBox}>
                <Message
                  compact
                  header={notification.message}
                  error={notification.type === "error"}
                  success={!(notification.type === "error")}
                />
              </div>
            )} */}
        </Container>
      </div>
      <Footer />
    </div>
  );
};

function mapStateToProps({ reports, user, cart }) {
  return {
    currentReport: reports.currentReport,
    user,
    cartList: cart.cartList,
  };
}

export default connect(mapStateToProps)(LienMitigationRequest);
