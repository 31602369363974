import Api from "../../api";
import { apiStart, apiEnd, apiError, requestSuccess, requestFail } from "./api";

const genericAction = (method, route, body = undefined, emptyResponseType = [], withLoading = true) => {
  return async (dispatch) => {
    if (withLoading) {
      dispatch(apiStart());
    }
    let data = body;
    const promiseValue = await Api({
      method,
      url: route,
      data,
    })
      .then((response) => {
        if (response.data.success) {
          return response.data.data;
        }
        dispatch(requestFail(response.data.message));
        return emptyResponseType;
      })
      .catch((error) => {
        dispatch(apiError(error.message));
        return emptyResponseType;
      });
    dispatch(requestSuccess("GENERIC_ACTION", promiseValue));
    if (withLoading) {
      dispatch(apiEnd());
    }
    return promiseValue;
  };
};

export default genericAction;
