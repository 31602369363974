import React, { useState } from "react";
import { Divider, Button, Icon } from "semantic-ui-react";

//Redux
import { connect } from "react-redux";
import { editProfile } from "../../redux/actions/user";

//Components
import Avatar from "../../components/Avatar";
import Screen from "../../components/Screen";
import ProfileDetailList from "../../components/ProfileDetailList";
import ProfileForm from "../../Forms/Profile";

//Styles
import styles from "./styles.module.css";

// class Profile extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       editingModeOn: false,
//     };
//   }

// toggleEdit = () => {
//   this.setState((state) => ({
//     editingModeOn: !state.editingModeOn,
//   }));
// };

// saveUserProfile = (userInfos) => {
//   this.props.dispatch(editProfile(userInfos, this.props.user._id));
//   this.toggleEdit();
// };

//   changeProfilePicture = (userInfos) => {
//     const uri =
//       "https://p7.hiclipart.com/preview/980/304/8/computer-icons-user-profile-avatar.jpg";
//     this.props.dispatch(setProfilePicture(uri, this.props.user._id));
//   };

//   render() {
//     const { editingModeOn } = this.state;
//     const { name, email, phone, address, profilePic } = this.props.user;
//     const user = { name, email, phone, address };
//     return (
// <div className={styles.screen}>
//   <Header searchBar={true} />
//   <div className={styles.body}>
//     <Container className={styles.container}>
//       <div className={styles.navBar}>
//         <Dashboard />
//       </div>
//       <div className={styles.content}>
//         <div>
//           {/* <Image src={profilePic ? profilePic : Avatar} size={"small"} verticalAlign={"middle"} /> */}

//           <div
//             style={{
//               display: "flex",
//               verticalAlign: "baseline",
//               alignItems: "center",
//             }}
//           >
//             <div>
//               <Avatar
//                 inProgress={this.state.userAvatarInProgress}
//                 user={user}
//                 src={profileAvatar}
//                 onSubmit={this.handleProfileUpload}
//               />
//             </div>
//             <div>
//               <Button
//                 as={"a"}
//                 size="mini"
//                 className={styles.button}
//                 onClick={this.toggleEdit}
//               >
//                 {editingModeOn ? (
//                   <>
//                     <Icon name="cancel" />
//                     Cancel
//                   </>
//                 ) : (
//                   <>
//                     <Icon name="edit" />
//                     Edit Profile{" "}
//                   </>
//                 )}
//               </Button>
//             </div>
//           </div>

//           {/* <Grid.Row textAlign="center">
//             <Button
//             style={{boxShadow: 'none', marginTop:'1em', textAlign:'center'}}
//             size="mini"
//             onClick={this.changeProfilePicture}>
//               Change Profile Picture
//               </Button>

//             <FormInput />
//           </Grid.Row> */}
//           <Divider />
//         </div>
//         {this.state.editingModeOn ? (
//           <ProfileForm
//             onSubmit={(d) => this.saveUserProfile(d)}
//             initialValues={user}
//           />
//         ) : (
//           <ProfileDetailList user={user} />
//         )}
//       </div>
//     </Container>
//   </div>
//   <Footer />
//       </div>
//     );
//   }
// }

function Profile({ user: { id, name, email, phone, address }, dispatch }) {
  const [editingModeOn, setEditingModeOn] = useState(false);
  const currentUser = { name, email, phone, address };

  const saveUserProfile = (userInfos) => {
    dispatch(editProfile(userInfos, id));

    setEditingModeOn(false);
  };

  const renderEditButton = () => (
    <div>
      <Button as={"a"} size="mini" className={styles.button} onClick={() => setEditingModeOn(!editingModeOn)}>
        {editingModeOn ? (
          <>
            <Icon name="cancel" />
            Cancel
          </>
        ) : (
          <>
            <Icon name="edit" />
            Edit Profile{" "}
          </>
        )}
      </Button>
    </div>
  );

  const renderBody = () => {
    return (
      <>
        <div>
          <Avatar
            inProgress={true}
            user={currentUser}
            // onSubmit={this.handleProfileUpload}
          />
          {renderEditButton()}
          <Divider />
        </div>

        {editingModeOn ? <ProfileForm onSubmit={(d) => saveUserProfile(d)} initialValues={currentUser} /> : <ProfileDetailList user={currentUser} />}
      </>
    );
  };

  return (
    <Screen searchBar fixedHeight title={"Profile"} meta="Create a profile to start ordering your lien search and estoppel now before buying or selling your next home in your favorite municipality." >
      <div className={styles.content}>{renderBody()}</div>
    </Screen>
  );
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(Profile);
