import React, { PureComponent } from "react";
import { Container } from "semantic-ui-react";

//Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Text from "../../components/Text";

//Styles
import styles from "./styles.module.css";

class NoMatch extends PureComponent {
  render() {
    return (
      <div className={styles.screen}>
        <Header searchBar={true} />
        <div className={styles.body}>
          <Container>
            <Text className={styles.title}>{"Page Not Found"}</Text>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}

export default NoMatch;
