import React, { Component } from "react";
import { Container, Button, Header, Message, Icon, Divider } from "semantic-ui-react";
import { Marker, InfoWindow } from "edanmer-google-maps-react";
import { Link } from "react-router-dom";
import { isEmpty, includes, get, isEqual, filter } from "lodash";
import Api from "../../api";
import moment from "moment";

//Redux
import { connect } from "react-redux";
import { addFavorite, removeFavorite } from "../../redux/actions/favorites";
import { addCart } from "../../redux/actions/cart";

//Components
import NavigationHeader from "../../components/Header";
import PaypalButton from "../../components/PaypalButton";
import Footer from "../../components/Footer";
import Text from "../../components/Text";
import GoogleMap from "../../components/GoogleMap";

//Utils
import { currencyFormat, round } from "../../utils/format";
import { navigate } from "../../utils/navigation";

//Styles
import styles from "./styles.module.css";

class PropertyScreen extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showingInfoWindow: false,
			activeMarker: {},
			selectedPlace: {},
			cart: [],
			notification: { type: "", message: "" },
		};
	}

	clearNotification = () => this.setState({ notification: { type: "", message: "" } });

	handleMessageNotification = (message, type = "success") => {
		this.setState({ notification: { type, message } });
		setTimeout(() => this.clearNotification(), 5000);
	};

	componentDidMount = () => {
		window.scrollTo(0, 0);
		const { currentProperty } = this.props;
		this.setState({
			cart: [
				{
					cityId: currentProperty.cityId,
					propertyId: currentProperty.id,
					type: "lienSearch",
				},
			],
		});
	};

	componentDidUpdate = (prevProps) => {
		const { currentProperty } = this.props;
		if (!isEqual(prevProps.currentProperty, currentProperty)) {
			this.setState({
				cart: [
					{
						cityId: currentProperty.cityId,
						propertyId: currentProperty.id,
						type: "lienSearch",
					},
				],
			});
		}
	};

	onMarkerClick = (props, marker, e) =>
		this.setState({
			selectedPlace: props,
			activeMarker: marker,
			showingInfoWindow: true,
		});

	onMapClicked = (props) => {
		if (this.state.showingInfoWindow) {
			this.setState({
				showingInfoWindow: false,
				activeMarker: null,
			});
		}
	};

	handleAddToCart = async (total) => {
		const { currentProperty, dispatch, user } = this.props;

		const data = {
			type: "lienSearch",
			userId: user.id,
			propertyId: currentProperty.id,
			amount: total,
			cityId: currentProperty.cityId,
		};

		try {
			await dispatch(addCart(data));
		} catch (error) {
			console.log(error.response);
		}
		this.handleMessageNotification("This report was successfully added to your cart");
	};

	handleFavorite = async () => {
		const { currentProperty, dispatch, user } = this.props;
		await dispatch(addFavorite(user.id, currentProperty.id));
	};

	handleRemove = async () => {
		const { dispatch, currentProperty, user } = this.props;
		await dispatch(removeFavorite(currentProperty._id, user.id));
	};

	isAlreadyInCart = () => {
		const { cartList, currentProperty } = this.props;
		if (isEmpty(cartList)) return;
		const cartListProperties = cartList.map((property) => property.propertyId);
		const exist = includes(cartListProperties, currentProperty.id);
		return exist;
	};

	getReportCost = () => {
		const { currentProperty } = this.props;
		const fees = get(currentProperty, "city.costs.lienSearch", 0);
		return fees + 50;
	};

	onCreateOrder = async () => {
		const { cart } = this.state;
		const order = await Api.post("/createOrder", { cart });
		return order.data.id;
	};

	onApproveOrder = async (data) => {
		await Api.post("/capturePayment", { orderId: data.orderID });
		navigate("/reports");
	};

	onRequestReport = async () => {
		const { currentProperty, user } = this.props;
		await Api.post("/reportCity", { cityId: currentProperty.cityId, userId: user.id, propertyId: currentProperty.id });
		navigate("/reports");
	};

	onRequestAlarm = () => {
		navigate("/alarmForm", false, this.props.currentProperty);
	};

	render() {
		const { activeMarker, showingInfoWindow, selectedPlace, notification } = this.state;
		const { currentProperty, user, reportList } = this.props;
		const { id, latitud, longitud, address, folio, isAuction = false } = currentProperty;

		// const isSubscribed = get(user, "isSubscribed", false);
		const auctionReports = filter(reportList, (report) => {
			const firstDay = moment().startOf("month");
			const lastDay = moment().endOf("month");
			return get(report, "property.isAuction", false) === true && moment(report.createdAt).isBetween(firstDay, lastDay);
		});
		const isFreeReport = isAuction && auctionReports.length < 3;
		const center = { lat: parseFloat(latitud), lng: parseFloat(longitud) };
		const reportCost = this.getReportCost();
		const total = round(reportCost, 2);
		// const favorite = some(favoriteList, {
		//   propertyCityId: currentProperty._id,
		// });

		if (!isEmpty(currentProperty)) {
			return (
				<div className={styles.screen}>
					<NavigationHeader searchBar={true} />
					<div className={styles.body}>
						<Container>
							<GoogleMap center={center}>
								<Marker id={id} position={center} name={address} onClick={this.onMarkerClick} />
								<InfoWindow marker={activeMarker} visible={showingInfoWindow}>
									<div className={styles.infoWindow}>
										<Text>{selectedPlace.name}</Text>
									</div>
								</InfoWindow>
							</GoogleMap>
							<div className={styles.infoBar}>
								<div className={styles.row}>
									<Text className={styles.whiteText}>{address}</Text>
									<Text className={styles.whiteText}>{`Folio: ${folio}`}</Text>
								</div>
								{/* <Button className={styles.leftButton} onClick={favorite ? this.handleRemove : this.handleFavorite}>
                <Icon className={styles.buttonIcon} name={favorite ? "heart" : "heart outline"} />
              </Button> */}
							</div>
							<div className={styles.endContainer}>
								{user.isLoggedIn && (
									<>
										<div className={styles.row}>
											<div>
												<Text as="h2">Request Lien Report</Text>
												<div className={styles.totalContainer}>
													<Text className={styles.text}>{"Cost:"}&nbsp;</Text>
													<Text className={styles.fees}>{currencyFormat(total)}</Text>
												</div>
											</div>
											{/* {hasAlarmRegistration && (
                        <div>
                          <Text as="h2">Request Alarm Registration</Text>
                          <div className={styles.alarmContainer}>
                            <Button onClick={this.onRequestAlarm} className={styles.button}>
                              <Icon name={"paper plane"} />
                              &nbsp; REQUEST NOW
                            </Button>
                          </div>
                        </div>
                      )} */}
										</div>

										<div>
											<Divider horizontal>Payment Methods</Divider>
											<div className={styles.paymentContainer}>
												<div className={styles.payment}>
													{!isFreeReport && (
														<PaypalButton amount={total} onCreateOrder={this.onCreateOrder} onApproveOrder={this.onApproveOrder} />
													)}
													{isFreeReport && (
														<Button onClick={() => this.onRequestReport()} disabled={this.isAlreadyInCart()} className={styles.button}>
															<Icon name={"paper plane"} />
															&nbsp; REQUEST NOW
														</Button>
													)}
												</div>
												<Divider vertical>OR</Divider>
												<div className={styles.payment}>
													<Button
														onClick={() => this.handleAddToCart(reportCost)}
														disabled={this.isAlreadyInCart()}
														className={styles.button}>
														<Icon name={"cart"} />
														&nbsp; ADD TO CART
													</Button>
												</div>
											</div>
										</div>
										{notification.message && (
											<div className={styles.notifBox}>
												<Message
													compact
													header={notification.message}
													error={notification.type === "error"}
													success={!(notification.type === "error")}
												/>
											</div>
										)}
									</>
								)}
								{!user.isLoggedIn && (
									<div>
										<Header as="h4">You will need to login in order to request a lien search</Header>
										<Button.Group>
											<Button positive type="button" as={Link} to="/login">
												{"Login"}
											</Button>

											<Button.Or />

											<Button positive type="button" as={Link} to="/register">
												{"Register"}
											</Button>
										</Button.Group>
									</div>
								)}
							</div>
						</Container>
					</div>
					<Footer />
				</div>
			);
		}
		return null;
	}
}

function mapStateToProps({ properties, favorites, user, cart, reports }) {
	return {
		currentProperty: properties.currentProperty,
		favoriteList: favorites.favoriteList,
		user,
		cartList: cart.cartList,
		reportList: reports.reportList,
	};
}

export default connect(mapStateToProps)(PropertyScreen);
