import { LOGIN, LOGOUT, CHECK_SESSION, EDIT_PROFILE, SET_PROFILE_PIC, SUBSCRIBE, PAY_LIEN, REHYDRATE } from "../actions/types";

export default function user(
  state = {
    isLoggedIn: false,
    id: "",
    name: "",
    email: "",
    phone: "",
    token: "",
    address: "",
    isSubscribed: false,
    twoFaEnabled: false,
    twoFaQrcode: null,
    profilePic: null,
  },
  action
) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        id: payload.data.user.id,
        address: payload.data.user.address,
        name: payload.data.user.name,
        email: payload.data.user.email,
        phone: payload.data.user.phone,
        twoFaEnabled: payload.data.user.twoFaEnabled,
        twoFaQrcode: payload.data.user.twoFaQrcode,
        isSubscribed: payload.data.user.isSubscribed || false,
        token: payload.data.token,
      };

    case REHYDRATE:
      return {
        ...state,
        isLoggedIn: true,
        id: payload.data.user.id,
        name: payload.data.user.name,
        address: payload.data.user.address,
        email: payload.data.user.email,
        phone: payload.data.user.phone,
        twoFaEnabled: payload.data.user.twoFaEnabled,
        twoFaQrcode: payload.data.user.twoFaQrcode,
        isSubscribed: payload.data.user.isSubscribed,
        token: payload.data.token,
      };

    case CHECK_SESSION:
      return {
        ...state,
        isLoggedIn: payload.data.user.isLoggedIn,
        id: payload.data.user.id,
        name: payload.data.user.name,
        email: payload.data.user.email,
        address: payload.data.user.address,
        phone: payload.data.user.phone,
        profilePic: payload.data.user.profilePic,
        token: payload.data.user.token,
        twoFaEnabled: payload.data.user.twoFaEnabled,
        twoFaQrcode: payload.data.user.twoFaQrcode,
        isSubscribed: payload.data.user.isSubscribed,
      };

    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        id: "",
        name: "",
        email: "",
        phone: "",
        token: "",
        address: "",
        twoFaEnabled: false,
        twoFaQrcode: null,
        profilePic: null,
        isSubscribed: false,
      };

    case SUBSCRIBE:
      return {
        ...state,
      };

    case PAY_LIEN:
      return {
        ...state,
      };

    case EDIT_PROFILE:
      return {
        ...state,
        id: payload.data.id,
        name: payload.data.name,
        phone: payload.data.phone,
        email: payload.data.email,
        token: payload.data.token,
        address: payload.data.address,
      };

    case SET_PROFILE_PIC:
      return {
        ...state,
        profilePic: payload.data,
      };

    default:
      return state;
  }
}
