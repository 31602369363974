import React from "react";
import { get } from "lodash";

//Components
import Text from "../Text";
import StatusButton from "../StatusButton";

//Constants
import { navigate } from "../../utils/navigation";
import { getTimeLabel } from "../../constants/Utils";

//Styles
import styles from "./styles.module.css";

const MitigationCard = ({ item, stripped }) => {
  const handleButton = () => {
    navigate(`/mitigation/${item.id}`, false, item);
  };
  const address = get(item, "property.address", null);
  const folio = get(item, "property.folio", null);

  return (
    <div className={stripped ? styles.container : styles.container2}>
      <div className={styles.row}>
        <div className={styles.infoContainer}>
          <div className={styles.textContainer}>
            <Text className={styles.text}>{address}</Text>
          </div>
          {folio && (
            <div className={styles.textContainer}>
              <Text className={styles.text}>{`Folio: ${folio}`}</Text>
            </div>
          )}
        </div>
        <div className={styles.infoContainer}>
          <Text className={styles.timeText}>{getTimeLabel(item.endedDate ? item.endedDate : item.createdAt)}</Text>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <StatusButton status={item.status} handleClick={handleButton} />
      </div>
    </div>
  );
};

export default MitigationCard;
