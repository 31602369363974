import React, { useState } from "react";
import { Button, Input, Icon } from "semantic-ui-react";
import { usePlacesWidget } from "react-google-autocomplete";

//Redux
import { connect } from "react-redux";
import { searchProperty } from "../../redux/actions/properties";

//Styles
import styles from "./styles.module.css";

const SearchBar = ({ dispatch }) => {
  const [text, setText] = useState("");
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyD3y4gjRi9rL3JRthvlj59N0GqKUK2geT0",
    options: {
      fields: ["formatted_address"],
      strictBounds: false,
      types: ["address"],
    },
    onPlaceSelected: (place) => {
      setText(place.formatted_address);
    },
  });

  const onChange = (e, { value }) => {
    setText(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    const disabled = text === "";
    if (!disabled) {
      dispatch(searchProperty(text));
    }
  };

  return (
    <div className={styles.row}>
      <div className={styles.inputContainer}>
        <Input className={styles.input} iconPosition={"left"} placeholder={"Enter Full Address"} value={text} onChange={onChange} onKeyPress={handleKeyPress}>
          <Icon name={"search"} />
          <input ref={ref} />
        </Input>
      </div>
      <div className={styles.separator} />
      <Button className={styles.button} onClick={handleSearch}>
        {"Search"}
      </Button>
    </div>
  );
};

export default connect()(SearchBar);
