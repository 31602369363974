import React from "react";
import { get } from "lodash";
import moment from "moment";

//Components
import Text from "../Text";
import StatusButton from "../StatusButton";

//Constants
import { navigate } from "../../utils/navigation";

//Styles
import styles from "./styles.module.css";

const AlarmRegistrationCard = ({ item, stripped }) => {
  const handleButton = () => {
    navigate(`/alarm/${item.id}`, false, item);
  };

  const handleTimeText = (status, endedDate, renewDate) => {
    switch (status) {
      case "Active":
        return `${moment(renewDate).diff(moment(endedDate), "days")} days until renewal`;
      case "Renew Now":
        return `${moment(endedDate).diff(moment(renewDate), "days")} days past renewal`;
      case "False Alarm":
        return `In violation since ${moment(endedDate).format("MM/DD/YYYY")}`;
      default:
        return "Waiting for Approval";
    }
  };

  const address = get(item, "property.address", null);
  const folio = get(item, "property.folio", null);

  return (
    <div className={stripped ? styles.container : styles.container2}>
      <div className={styles.row}>
        <div className={styles.infoContainer}>
          <div className={styles.textContainer}>
            <Text className={styles.text}>{address}</Text>
          </div>
          {folio && (
            <div className={styles.textContainer}>
              <Text className={styles.text}>{`Folio: ${folio}`}</Text>
            </div>
          )}
        </div>
        <div className={styles.infoContainer}>
          <Text className={styles.timeText}>{handleTimeText(item.status, item.endedDate, item.renewDate)}</Text>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <StatusButton status={item.status} handleClick={handleButton} />
      </div>
    </div>
  );
};

export default AlarmRegistrationCard;
