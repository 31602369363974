import React, { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";

//Components
import Text from "../Text";

//Styles
import styles from "./styles.module.css";

const AccordionBox = ({ title = "", children, first = false }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Accordion>
      <Accordion.Title className={first ? styles.firstSectionRow : styles.sectionRow} active={isOpen} onClick={toggleAccordion}>
        <div className={styles.sectionContainer}>
          <Text className={styles.sectionText}>{title}</Text>
          <Icon className={styles.icon} name={isOpen ? "caret up" : "caret down"} size={"large"} />
        </div>
        <div className={styles.statusContainer} />
      </Accordion.Title>
      <Accordion.Content className={styles.contentContainer} active={isOpen}>
        {children}
      </Accordion.Content>
    </Accordion>
  );
};

export default AccordionBox;
