import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import { API_FAILURE, SEARCH, SELECT_PROPERTY } from "./types";
import { navigate } from "../../utils/navigation";

function requestSuccess(type, data = null) {
  return { type, payload: { data } };
}

function requestFail(errorCode) {
  return { type: API_FAILURE, payload: { data: errorCode } };
}

export function searchProperty(address) {
  return (dispatch) => {
    dispatch(apiStart());
    Api.post("/searchPropertyCity", {
      property: address,
    })
      .then((response) => {
        if (response.data.success) {
          if (response.data.data.length === 1) {
            dispatch(apiEnd());
            dispatch(requestSuccess(SEARCH, response.data.data));
            dispatch(selectProperty(response.data.data[0]));
          } else {
            dispatch(requestSuccess(SEARCH, response.data.data));
            dispatch(apiEnd());
            navigate("/search");
          }
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(apiError(error.message));
        dispatch(apiEnd());
      });
  };
}

export function selectProperty(property) {
  return (dispatch) => {
    dispatch(requestSuccess(SELECT_PROPERTY, property));
    navigate("/property");
  };
}
