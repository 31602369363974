import Alabre from "../../assets/headshot/amac.png";
import Rohansen from "../../assets/headshot/Rohansen.png";
import Sondley from "../../assets/headshot/sondley.png";
import Mejia from "../../assets/headshot/Mejia.png";
// import Saradia from "../../assets/headshot/Saradia.png";
// import Jovensky from "../../assets/headshot/Jovensky.png";
// import Krishna from "../../assets/headshot/krishna.png";
// import Berlandy from "../../assets/headshot/Berlandy.png";
// import Miguel from "../../assets/headshot/Miguel.png";
//import Placeholder from "../../assets/placeholder.png";

export const Teams = [
  {
    fullname: "Mac Alabre",
    title: "Founder & CEO",
    profile: Alabre,
  },
  {
    fullname: "Rohansen Joseph",
    title: "Co-Founder & COO",
    profile: Rohansen,
  },
  {
    fullname: "Sondley Northecide",
    title: "Chief Technology Officer",
    profile: Sondley,
  },
  {
    fullname: "Eduardo Mejia",
    title: "Chief Information Officer",
    profile: Mejia,
  },
  // {
  //   fullname: "Saradia Honore",
  //   title: "Marketing Specialist",
  //   profile: Saradia,
  // },
  // {
  //   fullname: "Jovensky St-Victor",
  //   title: "Client Relations Specialist",
  //   profile: Jovensky,
  // },
  // {
  //   fullname: "Krishna Farvil",
  //   title: "Senior Software Developer",
  //   profile: Krishna,
  // },
  // {
  //   fullname: "Berlandy Northecide",
  //   title: "Senior Software Developer",
  //   profile: Berlandy,
  // },
  // {
  //   fullname: "Miguel Saravia",
  //   title: "Software Developer",
  //   profile: Miguel,
  // },
];
