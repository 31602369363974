import React from "react";
import { PayPalButtons, PayPalMessages } from "@paypal/react-paypal-js";

import styles from "./styles.module.css";

const PaypalButton = ({
  amount,
  onCreateOrder,
  onApproveOrder,
  layout = "horizontal",
  disabled = false,
}) => {
  const buttonStyle = {
    layout,
    height: 40,
    tagline: false,
    shape: "rect",
    label: "paypal",
  };

  const messageStyle = {
    layout: "text",
  };

  return (
    <div>
      <PayPalMessages
        style={messageStyle}
        forceReRender={[messageStyle]}
        amount={amount}
        currency="USD"
      />
      <PayPalButtons
        style={buttonStyle}
        className={styles.button}
        createOrder={onCreateOrder}
        onApprove={onApproveOrder}
        disabled={disabled}
      />
    </div>
  );
};

export default PaypalButton;
