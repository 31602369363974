import React, { PureComponent } from "react";
import { Container, Grid } from "semantic-ui-react";
import { Marker, InfoWindow } from "edanmer-google-maps-react";
import { isEmpty, map, isEqual } from "lodash";

//Redux
import { connect } from "react-redux";

//Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Text from "../../components/Text";
import GoogleMap from "../../components/GoogleMap";
import PropertyCard from "../../components/PropertyCard";

//Utils
import { round } from "../../utils/format";

//Styles
import styles from "./styles.module.css";

class Search extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      center: {},
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  componentDidUpdate = (prevProps) => {
    if (!isEqual(prevProps.propertyList, this.props.propertyList)) {
      this.calculateCenterPosition(this.props.propertyList);
    }
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  calculateCenterPosition = (array) => {
    if (!isEmpty(array)) {
      let lat = 0;
      let lng = 0;

      for (const property of array) {
        lat += parseFloat(property.latitud);
        lng += parseFloat(property.longitud);
      }
      lat = lat / array.length;
      lng = lng / array.length;
      this.setState({ center: { lat: round(lat, 7), lng: round(lng, 7) } });
    }
  };

  renderPropertyMarkers = (array) => {
    if (!isEmpty(array)) {
      const markers = map(array, (item, index) => {
        return (
          <Marker
            key={index}
            id={index}
            position={{ lat: item.latitud, lng: item.longitud }}
            name={item.address}
            onClick={this.onMarkerClick}
          />
        );
      });
      return markers;
    } else {
      return null;
    }
  };

  renderPropertyCards = (array) => {
    if (!isEmpty(array)) {
      const propertyCards = map(array, (item, index) => {
        return (
          <Grid.Column key={index} mobile={16} tablet={8} computer={4}>
            <PropertyCard item={item} />
          </Grid.Column>
        );
      });
      return propertyCards;
    } else {
      return null;
    }
  };

  render() {
    const { propertyList } = this.props;
    const { center, activeMarker, showingInfoWindow, selectedPlace } = this.state;
    const count = propertyList.length;
    return (
      <div className={styles.screen}>
        <Header searchBar={true} />
        <div className={styles.body}>
          <Container>
            {count > 0 && (
              <GoogleMap center={center} zoom={5} mapType={"roadmap"} onClick={this.onMapClicked}>
                {this.renderPropertyMarkers(propertyList)}
                <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
                  <div className={styles.infoWindow}>
                    <Text>{selectedPlace.name}</Text>
                  </div>
                </InfoWindow>
              </GoogleMap>
            )}
            <Text className={styles.titleText}>
              {count}
              {" Results"}
            </Text>
            <Grid stackable>{this.renderPropertyCards(propertyList)}</Grid>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps({ properties }) {
  return { propertyList: properties.propertyList };
}

export default connect(mapStateToProps)(Search);
