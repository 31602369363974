/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, Icon, Segment, Grid, Label } from "semantic-ui-react";
import { isEmpty, map } from "lodash";
import { currencyFormat, round } from "../../utils/format";

//Redux
import { connect } from "react-redux";
import { removeToCart } from "../../redux/actions/cart";

//Components
import Screen from "../../components/Screen";
import PaypalButton from "../../components/PaypalButton";
import Text from "../../components/Text";
import Api from "../../api";

//Utils
import { navigate } from "../../utils/navigation";

//Styles
import styles from "./styles.module.css";

const CardItem = ({ id, item, stripped, onRemove, type, amount }) => {
  if (isEmpty(item)) return;
  let folio = "folio" in item ? item.folio : "";
  let address = "address" in item ? item.address : "";

  if (!folio && !address) return;

  return (
    <div className={stripped ? styles.container1 : styles.container2}>
      <div className={styles.row}>
        <div className={styles.infoContainer}>
          <div>
            <Label style={{ margin: "5px 0", textTransform: "capitalize" }} size="mini">
              {type}
            </Label>
            <Label style={{ margin: "5px 10px" }} size="mini" color="green">
              {currencyFormat(amount)}
            </Label>
          </div>
          {folio && (
            <div className={styles.textContainer}>
              <Text className={styles.boldedText}>{`Folio: ${folio}`}</Text>
            </div>
          )}
          {address && (
            <div className={styles.textContainer}>
              <Text className={styles.address}>{address}</Text>
            </div>
          )}
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button onClick={() => onRemove(id, item)} size="tiny" color="red">
          <Icon className={styles.icon} name={"trash alternate outline"} />
        </Button>
      </div>
    </div>
  );
};

function Cart({ dispatch, user, cartList }) {
  const [cartItems, setCartItems] = useState(null);

  function handleDeleteCartItem(id, property) {
    let filteredItems = cartItems.filter((item) => property.id !== item.property.id);
    setCartItems(filteredItems);
    dispatch(removeToCart(id, property.id));
  }

  function getTotalCartItems() {
    let total = 0;
    if (isEmpty(cartItems)) {
      return null;
    }
    cartItems.map(({ amount }) => (total += parseFloat(amount)));
    return total;
  }

  // function getPaypalProcessingFess() {
  //   const totalCartItems = getTotalCartItems();
  //   return (3.49 / 100) * totalCartItems + 0.49;
  // }

  function getCartBalance() {
    // const balance = getTotalCartItems() + getPaypalProcessingFess();
    const balance = getTotalCartItems();
    return round(balance, 2);
  }

  useEffect(() => {
    Api.get(`/cartUserProperty/${user.id}`)
      .then((resp) => {
        if (resp.data.success) {
          setCartItems(resp.data.data);
        } else {
          console.log(resp.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [cartList]);

  const renderCartItem = (array) => {
    if (isEmpty(array)) return;

    const items = map(array, (item, index) => {
      const { property, type, id, amount } = item ? item : {};
      const stripped = index % 2 === 0;
      return (
        <div className={styles.cartRowItem} key={index}>
          <CardItem
            id={id}
            type={type}
            key={index}
            item={property}
            stripped={stripped}
            amount={amount}
            onRemove={handleDeleteCartItem}
          />
        </div>
      );
    });
    return items;
  };

  const onCreateOrder = async () => {
    const order = await Api.post("/createOrder", { cart: cartItems });
    return order.data.id;
  };

  const onApproveOrder = async (data) => {
    await Api.post("/capturePayment", { orderId: data.orderID });
    navigate("/reports");
  };

  return (
    <Screen
      searchBar
      fixedHeight
      title={"Cart"}
      meta="Add all your lien search and estoppel order in one place to buy your favorite home">
      <div className={styles.content}>
        {isEmpty(cartItems) && (
          <Segment>
            <h1 className={styles.noOrders}>Your Cart Is Empty!</h1>
          </Segment>
        )}
        {!isEmpty(cartItems) && (
          <Grid verticalAlign="top" stackable>
            <Grid.Column width="9">
              <Segment size="small">{renderCartItem(cartItems)}</Segment>
            </Grid.Column>

            <Grid.Column width="5" floated="right">
              <Segment>
                <Text as="h3" className={styles.cartHeader}>
                  Checkout
                </Text>
                {/* <Grid>
                      <Grid.Column width={8}>Subtotal:</Grid.Column>
                      <Grid.Column width={8} textAlign="right">
                        $99.99
                      </Grid.Column>
                    </Grid> */}
                <Grid>
                  <Grid.Column width={8} className={styles.boldedText}>
                    Subtotal:
                  </Grid.Column>
                  <Grid.Column width={8} textAlign="right">
                    {currencyFormat(getTotalCartItems())}
                  </Grid.Column>
                </Grid>
                {/* <Grid>
                  <Grid.Column width={8} className={styles.boldedText}>
                    Paypal Processing Fees:
                  </Grid.Column>
                  <Grid.Column width={8} textAlign="right">
                    {currencyFormat(getPaypalProcessingFess())}
                  </Grid.Column>
                </Grid> */}
                <Grid>
                  <Grid.Column width={8} className={styles.boldedText}>
                    <span className={styles.balance}>Total:</span>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign="right">
                    {currencyFormat(getCartBalance())}
                  </Grid.Column>
                </Grid>
              </Segment>

              <div style={{ width: "100%", margin: "0 auto" }}>
                <PaypalButton
                  amount={getCartBalance()}
                  onCreateOrder={onCreateOrder}
                  onApproveOrder={onApproveOrder}
                  layout="vertical"
                />
              </div>
            </Grid.Column>
          </Grid>
        )}
      </div>
    </Screen>
  );
}

function mapStateToProps({ user, cart }) {
  return {
    user,
    cartList: cart.cartList,
  };
}

export default connect(mapStateToProps)(Cart);
