import React, { PureComponent } from "react";

//Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";

//Styles
import styles from "./styles.module.css";

class Browse extends PureComponent {
  render() {
    return (
      <div className={styles.screen}>
        <Header />
        <div className={styles.body}>
          <iframe title={"customMap"} src={"https://www.google.com/maps/d/embed?mid=1duUm3VIa9-ACQlSQKmeQfUJuKzQH5xRP&hl=en"} width={"100%"} height={"100%"} />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Browse;
