import { LOGIN, REHYDRATE, CHECK_SESSION, LOGOUT, SUBMIT_ALARM, SELECT_ALARM, GET_ALARMS } from "../actions/types";
import { get, orderBy } from "lodash";

export default function reducer(state = { alarmList: [], currentAlarm: {} }, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN: {
      const alarmList = orderBy(get(payload, "data.user.alarms", []), ["createdAt"], "desc");
      return { ...state, alarmList };
    }

    case REHYDRATE: {
      const alarmList = orderBy(get(payload, "data.user.alarms", []), ["createdAt"], "desc");
      return { ...state, alarmList };
    }

    case CHECK_SESSION:
      return {
        ...state,
        alarmList: get(payload, "data.alarms.alarms", []),
        currentAlarm: get(payload, "data.alarms.currentAlarm", {}),
      };

    case LOGOUT:
      return { ...state, alarmList: [], currentAlarm: {} };

    case GET_ALARMS: {
      const alarmList = orderBy(payload.data, ["createdAt"], "desc");
      return { ...state, alarmList };
    }

    case SUBMIT_ALARM: {
      const alarmList = orderBy([...state.alarmList, payload.data], ["createdAt"], "desc");
      return {
        ...state,
        alarmList,
      };
    }

    case SELECT_ALARM:
      return { ...state, currentAlarm: payload.data };

    default:
      return state;
  }
}
