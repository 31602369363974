import React, { useRef } from "react";

//Components
import Header from "../../components/Header";
// import Footer from "../../components/Footer";

import styles from "./styles.module.css";
import MainSection from "./components/main-section/MainSection";
import WebsiteBody from "./components/website-body/Body";
import WebsiteBody2 from "./components/body";
import Footer from "./components/footer/FinalLimas";

function GovernmentScreen() {
  const reqDemoRef = useRef(null);
  const aboutUsRef = useRef(null);
  return (
    <div>
      <Header searchBar={false} />

      <div style={{ fontSize: "10px" }}>
        <MainSection className={styles.mainSection} reqDemoRef={reqDemoRef} />
        <WebsiteBody className={styles.webBody} ref={aboutUsRef} />
        <WebsiteBody2 ref={reqDemoRef} />
        <Footer />
      </div>
    </div>
  );
}

export default GovernmentScreen;
