import React, { useEffect, useRef, forwardRef } from "react";
import { Form, Button, Icon, Select } from "semantic-ui-react";
import { isEmpty, omit } from "lodash";
import PropTypes from "prop-types";

//Styles
import styles from "./styles.module.css";

const Input = forwardRef((props, ref) => {
  const {
    name,
    value,
    className,
    icon,
    disabled,
    rounded,
    onChange,
    onKeyPress,
    dropdown,
    fluid = true,
  } = props;
  const input = useRef(null);

  useEffect(() => {
    if (rounded && isEmpty(value)) {
      (ref ? ref : input).current.style.removeProperty("border-top-left-radius");
      (ref ? ref : input).current.style.removeProperty("border-bottom-left-radius");
      (ref ? ref : input).current.style.removeProperty("border-top-right-radius");
      (ref ? ref : input).current.style.removeProperty("border-bottom-right-radius");
      (ref ? ref : input).current.style.removeProperty("border-right");
      (ref ? ref : input).current.style.setProperty("border-radius", "1rem", "important");
      (ref ? ref : input).current.style.setProperty(
        "border-right",
        "1px solid rgba(34,36,38,.15)",
        "important"
      );
    }
    if (rounded && !isEmpty(value)) {
      (ref ? ref : input).current.style.removeProperty("border-radius");
      (ref ? ref : input).current.style.setProperty("border-top-left-radius", "1rem", "important");
      (ref ? ref : input).current.style.setProperty(
        "border-bottom-left-radius",
        "1rem",
        "important"
      );
      (ref ? ref : input).current.style.setProperty("border-top-right-radius", "0", "important");
      (ref ? ref : input).current.style.setProperty("border-bottom-right-radius", "0", "important");
      (ref ? ref : input).current.style.setProperty("border-right", "0", "important");
    }
    if (!isEmpty(dropdown)) {
      (ref ? ref : input).current.style.setProperty("border-top-left-radius", "0", "important");
      (ref ? ref : input).current.style.setProperty("border-bottom-left-radius", "0", "important");
      (ref ? ref : input).current.style.setProperty(
        "border-left-color",
        "transparent",
        "important"
      );
    }
  }, [rounded, value, dropdown, ref]);

  const handleEnter = (e) => {
    const { key } = e;
    if (key === "Enter") {
      e.preventDefault();
    }
  };

  const clearInput = (e) => {
    const { name, onChange } = props;
    const data = { name, value: "" };
    onChange({ ...e, target: data }, data);
  };

  const validProps = omit(props, [
    "ref",
    "icon",
    "children",
    "onKeyPress",
    "name",
    "value",
    "onChange",
    "disabled",
    "className",
    "rounded",
  ]);

  return (
    <Form.Input
      {...validProps}
      className={`${styles.input} ${className}`}
      disabled={disabled}
      onChange={onChange}
      name={name}
      value={value}
      {...(isEmpty(dropdown) ? { iconPosition: "left" } : {})}
      autoComplete={"off"}
      fluid={fluid}
      onKeyPress={onKeyPress ? onKeyPress : handleEnter}
      action>
      {!isEmpty(dropdown) && <Select labeled {...dropdown} />}
      {isEmpty(dropdown) && !isEmpty(icon) && <Icon name={icon} />}
      <input ref={ref ? ref : input} />
      {!isEmpty(value) && !disabled && (
        <Button
          className={rounded ? styles.clearRoundedIconLast : styles.clearIconLast}
          icon={"cancel"}
          onClick={clearInput}
        />
      )}
    </Form.Input>
  );
});

Input.propTypes = {
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  children: PropTypes.node,
  rounded: PropTypes.bool,
};

Input.defaultProps = {
  disabled: false,
  rounded: false,
  // icon: "user",
};

export default Input;
