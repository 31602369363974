import React, { PureComponent } from "react";
import { Grid } from "semantic-ui-react";
import { isEmpty } from "lodash";
import moment from "moment";

//Components
import Text from "../Text";

//Constants
import { currencyFormat, getDateLabel, dateDiff } from "../../constants/Utils";

//Styles
import styles from "./styles.module.css";

/**
 * Report Component
 * @augments {PureComponent<Props>}
 */
class MitigationBox extends PureComponent {
  renderRows = (item) => {
    const hasComplied = item.dateComplied ? true : false;
    const endDate = hasComplied ? moment(item.dateComplied) : moment();
    const diffDays = dateDiff(moment(item.dateOfFineBegan), endDate) + 1;
    // const payOffDays = dateDiff(moment(item.dateRecorded), moment(item.payoffDate));
    const dailyFine = parseFloat(item.originalFine) * diffDays;
    // const interest = payOffDays * ((dailyFine * 0.12) / 365);
    const total =
      parseFloat(item.courtFee) +
      parseFloat(item.recordingFee) +
      parseFloat(item.adminFee) +
      parseFloat(item.releaseOfLienFee) +
      dailyFine;
    return (
      <div className={styles.contentContainer}>
        <Grid stackable>
          <Grid.Row className={styles.gridRow} columns={3}>
            <Grid.Column>
              <div className={styles.textRow}>
                <Text className={styles.boldText}>{"Case #: "}</Text>
                <Text className={styles.text}>{item.caseNumber}</Text>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className={styles.textRow}>
                <Text className={styles.boldText}>{"Original Fine: "}</Text>
                <Text className={styles.text}>{currencyFormat(parseFloat(item.originalFine))}</Text>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className={styles.textRow}>
                <Text className={styles.boldText}>{"Date of Violation: "}</Text>
                <Text className={styles.text}>{getDateLabel(item.dateOfViolation)}</Text>
              </div>
            </Grid.Column>
          </Grid.Row>
          {!isEmpty(item.dateComplied) && (
            <Grid.Row className={styles.gridRow} columns={3}>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Date Fine Began: "}</Text>
                  <Text className={styles.text}>{getDateLabel(item.dateOfFineBegan)}</Text>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Daily Fine Amount: "}</Text>
                  <Text className={styles.text}>{currencyFormat(dailyFine)}</Text>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Total Due: "}</Text>
                  <Text className={styles.text}>{currencyFormat(total)}</Text>
                </div>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row className={styles.gridRow} columns={3}>
            <Grid.Column>
              <div className={styles.textRow}>
                <Text className={styles.boldText}>{"Date Complied: "}</Text>
                <Text className={styles.text}>{getDateLabel(item.dateComplied)}</Text>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className={styles.textRow}>
                <Text className={styles.boldText}>{"Book: "}</Text>
                <Text className={styles.text}>{item.book}</Text>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className={styles.textRow}>
                <Text className={styles.boldText}>{"Page: "}</Text>
                <Text className={styles.text}>{item.page}</Text>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={styles.gridRow} columns={1}>
            <Grid.Column>
              <div className={styles.textRow}>
                <Text className={styles.boldText}>{"Description: "}</Text>
                <Text className={styles.text}>{item.description}</Text>
              </div>
            </Grid.Column>
          </Grid.Row>
          {!isEmpty(item.note) && (
            <Grid.Row className={styles.gridRow} columns={1}>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Note: "}</Text>
                  <Text className={styles.text}>{item.note}</Text>
                </div>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    );
  };

  render() {
    const { item } = this.props;
    return <>{this.renderRows(item)}</>;
  }
}

export default MitigationBox;
