import React from "react";
import { Modal, Icon, Button } from "semantic-ui-react";
import { isString } from "lodash";

//Components
import Text from "../Text";

//Styles
import styles from "./styles.module.css";

const ErrorDialog = ({ isError, text, onDismiss }) => {
  return (
    <Modal open={isError} closeOnDimmerClick={false} className={styles.modal}>
      <Modal.Content className={styles.content}>
        <Icon name={"warning sign"} size={"big"} color={"red"} />
        <Text className={styles.text}>{isString(text) ? text : ""}</Text>
      </Modal.Content>
      <Modal.Actions>
        <Button className={styles.button} onClick={onDismiss} color={"red"} fluid={true}>
          {"Dismiss"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ErrorDialog;
