import React from "react";
import { Form, Button} from "semantic-ui-react";
import { Formik } from "formik";
import PropTypes from "prop-types";

import _ from "lodash"

import styles from "./styles.module.css";


class Forms extends React.Component {
  constructor(props) {
    super(props);
    this.initializeForm();
  }

  initializeForm = () => {
    const formkeys = this.props.fields.map((field) => field.name);
    let initialFormValues = {};
    _.forEach(formkeys, (_key) => {
      initialFormValues[_key] = "";
    })


    return initialFormValues;
  };

  render() {
    const {
      onSubmit,
      fields,
      size,
      initialValues,
      schema,
      submitting,
      disabled,
    } = this.props;
    return (
      <div style={{margin:'1em 0'}}>
        <Formik
          initialValues={initialValues ? initialValues : this.initializeForm()}
          validationSchema={schema ? schema : null}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, handleChange, errors }) => (
            <Form size={size} onSubmit={handleSubmit}>
              {fields.map((field, index) => {
                return (
                  <div key={index} style={{margin:'1em 0'}}>
                    {field.type === "input" && (
                      <Form.Input
                        key={index}
                        fluid
                        icon={field.icon}
                        iconPosition="left"
                        placeholder={field.placeholder}
                        type={field.inputType}
                        onChange={handleChange(field.name)}
                        required={field.required}
                        error={errors[field.name]}
                      />
                    )}

                    {field.type === "textarea" && (
                      <Form.TextArea
                        placeholder="Message"
                        onChange={handleChange("message")}
                        required={field.required}
                        error={errors["message"]}
                        rows="3"
                        style={{ resize: "none" }}
                      />
                    )}
                  </div>
                );
              })}
              <Button
              loading={submitting}
              className={styles.button}
                size="medium"
                disabled={submitting ? true : disabled}
                content={
                  <>
                    <span>Submit</span>&nbsp;
                  </>
                }
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

Forms.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  size: PropTypes.string,
  schema: PropTypes.object,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
};

Forms.defaultProps = {
  onSubmit: null,
  fields: null,
  size: "small",
  schema: null,
  submitting: false,
  disabled: false,
};

export default Forms;
