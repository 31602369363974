/* Implementation of the image slideshow */

import React, { useState, useEffect } from "react";
import { SliderData } from "./SliderData";
import arrow from "../../assets/Arrow.svg";
import styles from "./ImageSlider.module.css";

// Image Slider component, needs a set of images to be passed to it
const ImageSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const quoteList = [
    // Text to use for testimonials
    '"Boosts efficiency and saves staff time"',
    '"Intuitive, well-crafted user interface"',
    '"\tEasy to learn, minimal training."',
    '"Makes unfamiliar concepts appealing"',
  ];

  const creditsList = [
    // Text to use for names in testimonials
    "-City Manager Staff",
    "-Code Compliance Staff",
    "-IT Staff",
    "-City Commissioner",
  ];

  let barString = "bar"; // Used for loading the CSS classes of progress bars

  // Variables for autoscroll feature
  const autoScroll = true;
  let slideInterval;
  let intervalTime = 8000;

  // Logic for moving to next image
  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
    renderActiveBar();
    renderQuotes(quoteList);
  };

  // Logic for moving to previous image
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
    renderActiveBar();
    renderQuotes(quoteList);
  };

  // Implementation of auto scrolling, activates when called and when autoScroll is set to true, will see later
  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  // When webpage loads, this function sets the first image in the set of slides to be the first one shown
  useEffect(() => {
    setCurrent(0);
  }, []);

  // Calls the auto function for auto scrolling, includes a clean-up function
  useEffect(() => {
    if (autoScroll === true) {
      auto();
    }
    return () => clearInterval(slideInterval);
  });

  // Renders image slider
  function renderSlides(slides) {
    return slides.map((slide, index) => {
      return (
        <div className={styles.slideActive} key={index}>
          {index === current && <img src={slide.image} alt="SLIDE" className={styles.image} />}
        </div>
      );
    });
  }

  // Function to render testimonials on slideshow
  function renderQuotes(quotes) {
    return quotes.map((quote, index) => {
      return (
        <div key={index}>
          {index === current && (
            <p className={styles.activeTestimonial}>
              <span className={styles.quote}>{quote}</span>
              <span className={styles.quoteCredit}>{creditsList[index]}</span>
            </p>
          )}
        </div>
      );
    });
  }

  // Render empty progress bars within the slideshow
  function renderBars() {
    return (
      <div className={styles.bars}>
        <div className={`${styles.progressBar} ${styles.bar1}`}></div>
        <div className={`${styles.progressBar} ${styles.bar2}`}></div>
        <div className={`${styles.progressBar} ${styles.bar3}`}></div>
        <div className={`${styles.progressBar} ${styles.bar4}`}></div>
      </div>
    );
  }

  // Render the active, highlighted bar of the four bars
  function renderActiveBar() {
    return (
      <div>
        <div className={`${styles[barString + (current + 1)]} ${styles.barActive}`}></div>
      </div>
    );
  }

  // Checks if the slides parameter that was passed to the component is an array or if its empty
  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <section className={styles.slider}>
      <img
        src={arrow}
        className={`${styles.leftArrow} ${styles.arrow}`}
        alt="LEFT ARROW"
        onClick={prevSlide}
      />
      <img
        src={arrow}
        className={`${styles.rightArrow} ${styles.arrow}`}
        alt="RIGHT ARROW"
        onClick={nextSlide}
      />

      {renderBars()}
      {renderActiveBar()}
      {renderSlides(SliderData)}

      <div className={styles.animationDelay}>{renderQuotes(quoteList)}</div>
    </section>
  );
};

export default ImageSlider;
