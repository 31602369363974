import { React } from "react";
import ImageSlider from "./ImageSlider";
import { SliderData } from "./SliderData";
import Button from "../buttons/Button";

// Assets
import blob1 from "../../assets/Blob.svg";
import blob2 from "../../assets/Blob-1.svg";
import blob3 from "../../assets/Blob-2.svg";
import blob4 from "../../assets/Blob-3.svg";

// Styles
import styles from "./MainSection.module.css";

const MainSection = () => {
  return (
    <div className={styles.mainSection}>
      <ImageSlider slides={SliderData} />

      {/* <p className={styles.text}>
                <span className={styles.regularText}>
                The smartest way for local governments to manage the municipal lien search requests and settlement process.
                Created by a former local government lien specialist
                </span>
             
            </p> */}
      <h3 className={styles.title}>
        The smartest way for local governments to manage the municipal lien search requests and
        settlement process.
      </h3>
      <h3 className={styles.small}>Created by a former local government lien specialist</h3>
      <div className={styles.blobs}>
        <img src={blob1} className={styles.blob1} alt="BLOB1" />
        <img src={blob2} className={styles.blob2} alt="BLOB2" />
        <img src={blob3} className={styles.blob3} alt="BLOB3" />
        <img src={blob4} className={styles.blob4} alt="BLOB4" />
      </div>

      <div className={styles.cta}>
        <Button type="body" specifyButton="requestDemoMain" name="Request Demo" />
      </div>
    </div>
  );
};

export default MainSection;
