import React, { PureComponent } from "react";
import { Map, GoogleApiWrapper } from "edanmer-google-maps-react";
import { Container } from "semantic-ui-react";
import PropTypes from "prop-types";

/**
 * GoogleMap Component
 * @augments {PureComponent<Props>}
 */
class GoogleMap extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    initialCenter: PropTypes.object,
    center: PropTypes.object,
    minZoom: PropTypes.number,
    maxZoom: PropTypes.number,
    zoom: PropTypes.number,
    mapType: PropTypes.oneOf(["satellite", "roadmap"]),
  };

  static defaultProps = {
    initialCenter: { lat: 25.761681, lng: -80.191788 },
    minZoom: 1,
    maxZoom: 20,
    zoom: 20,
    mapType: "satellite",
  };

  onReady = (_, map) => {
    map.setOptions({
      mapTypeId: this.props.mapType,
      center: this.props.center,
    });
  };

  render() {
    const { children } = this.props;

    return (
      <Container>
        <Map containerStyle={styles.container} {...this.props} onReady={this.onReady}>
          {children}
        </Map>
      </Container>
    );
  }
}

const styles = {
  container: {
    position: "relative",
    width: "100%",
    height: "30rem",
  },
};

export default GoogleApiWrapper({ apiKey: "AIzaSyD3y4gjRi9rL3JRthvlj59N0GqKUK2geT0" })(GoogleMap);
