import { LOGIN, CHECK_SESSION, LOGOUT, SELECT_PAYMENT, REHYDRATE } from "../actions/types";
import { get, orderBy } from "lodash";

export default function reducer(state = { paymentList: [], currentPayment: {} }, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN: {
      const paymentList = orderBy(get(payload, "data.user.payments", []), ["createdAt"], "desc");
      return { ...state, paymentList };
    }

    case REHYDRATE: {
      const paymentList = orderBy(get(payload, "data.user.payments", []), ["createdAt"], "desc");
      return { ...state, paymentList };
    }

    case CHECK_SESSION:
      return {
        ...state,
        paymentList: payload.data.payments.paymentList,
        currentPayment: payload.data.payments.currentPayment,
      };

    case LOGOUT:
      return { ...state, paymentList: [], currentPayment: {} };

    case SELECT_PAYMENT:
      return { ...state, currentPayment: payload.data };

    default:
      return state;
  }
}
