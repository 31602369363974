import React from "react";
import { Button, Form, Segment } from "semantic-ui-react";
import { Formik } from "formik";
import * as Yup from "yup";

import Text from "../../components/Text";

//Styles
import styles from "./styles.module.css";

const RegisterForm = ({ onSubmit }) => (
  <>
    <div className={styles.loginContainer}>
      <Text className={styles.headline} as={"h2"}>
        Register
      </Text>

      <Formik
        initialValues={RegisterFormConfig.initialValues}
        validationSchema={RegisterFormConfig.schema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}>
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form size="large" onSubmit={handleSubmit}>
            <Segment className={styles.segment}>
              {RegisterFormConfig.fields.map((field, index) => (
                <Form.Input
                  key={index}
                  fluid
                  icon={field.icon}
                  iconPosition="left"
                  placeholder={field.placeholder}
                  type={field.type}
                  onChange={handleChange(field.name)}
                  required={field.required}
                  error={errors[field.name]}
                />
              ))}
              <Button className={styles.button} type="submit">
                Submit
              </Button>
            </Segment>
          </Form>
        )}
      </Formik>
    </div>
  </>
);

const RegisterFormConfig = {
  initialValues: {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    website: "",
  },
  fields: [
    {
      icon: "user",
      placeholder: "Name",
      type: "text",
      name: "name",
      required: true,
    },
    {
      icon: "mail",
      placeholder: "Email Address",
      type: "email",
      name: "email",
      required: true,
    },
    {
      icon: "lock",
      placeholder: "Password",
      type: "password",
      name: "password",
      required: true,
    },
    {
      icon: "lock",
      placeholder: "Confirm Password",
      type: "password",
      name: "confirmPassword",
      required: true,
    },
    {
      icon: "phone",
      placeholder: "Phone Number",
      type: "tel",
      name: "phone",
      required: false,
    },
    {
      icon: "world",
      placeholder: "Website",
      type: "text",
      name: "website",
      required: false,
    },
  ],
  schema: Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required().email("Not a valid email address"),
    password: Yup.string().min(4).required(),
    confirmPassword: Yup.string()
      .required("Must Confirm Password")
      .oneOf([Yup.ref("password")], "Password Do Not Match"),

    phone: Yup.string(),
    website: Yup.string().matches(/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/, "Not a valid website address"),
  }),
};

export default RegisterForm;
