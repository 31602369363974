import React, { PureComponent } from "react";
import { Container, Grid } from "semantic-ui-react";

//Redux
import { connect } from "react-redux";
import { login } from "../../redux/actions/user";

//Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LoginForm from "../../Forms/Login";

//Styles
import styles from "./styles.module.css";

class Login extends PureComponent {
  constructor(props) {
    super(props);

    this.formInitialValues = null;
    const objRememberMe = localStorage.getItem("rememberMe");
    if (objRememberMe) {
      const rememberMe = JSON.parse(objRememberMe);
      if (rememberMe) {
        const credentials = JSON.parse(localStorage.getItem("credentials"));
        const { email, password } = credentials;
        this.formInitialValues = {
          email,
          password,
          rememberMe,
        };
      }
    }
  }

  login = ({ email, password, rememberMe }) => {
    this.props.dispatch(login({ email, password }, rememberMe));
  };

  render() {
    return (
      <div className={styles.screen}>
        <Header />
        <div className={styles.body}>
          <div className={styles.black}>
            <Container className={styles.content}>
              <Grid className={styles.gridContainer}>
                <Grid.Column style={{ maxWidth: 450 }}>
                  <LoginForm onSubmit={this.login} initialValues={this.formInitialValues} />
                </Grid.Column>
              </Grid>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect()(Login);
