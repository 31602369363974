import {
  LOGIN,
  REHYDRATE,
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  SEARCH,
  SELECT_PROPERTY,
  SUBMIT_ORDER,
  UPDATE_REPORT,
  GET_TYPES,
  SELECT_REPORT,
  EDIT_PROFILE,
  LOGOUT,
  SELECT_MITIGATION,
  GET_MESSAGE,
  SEND_MESSAGE,
  SEND_MESSAGE_LOCAL,
  CLEAR_MITIGATION,
  ACCEPT_NEGOTIATION,
  GET_REPORTS,
  GET_MITIGATIONS,
} from "../actions/types";
import { includes } from "lodash";

const persist = (store) => (next) => async (action) => {
  let result = next(action);
  let actions = [
    LOGIN,
    REHYDRATE,
    ADD_FAVORITE,
    REMOVE_FAVORITE,
    SEARCH,
    SELECT_PROPERTY,
    SUBMIT_ORDER,
    UPDATE_REPORT,
    GET_TYPES,
    SELECT_REPORT,
    EDIT_PROFILE,
    LOGOUT,
    SELECT_MITIGATION,
    GET_MESSAGE,
    SEND_MESSAGE,
    SEND_MESSAGE_LOCAL,
    CLEAR_MITIGATION,
    ACCEPT_NEGOTIATION,
    GET_REPORTS,
    GET_MITIGATIONS,
  ];
  if (includes(actions, action.type)) {
    localStorage.setItem("store", JSON.stringify(store.getState()));
  }

  return result;
};

export default persist;
