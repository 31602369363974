import React from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";

export default function Section({
  size,
  as,
  padding,
  widthColumn,
  verticalAlign,
  textAlign,
  children,
  backgroundColor,
  ...rest
}) {
  let styles = { padding: "8em 1em 8em 1em", background: backgroundColor };
  return (
    <Grid
      as={as}
      stackable
      verticalAlign={verticalAlign}
      textAlign={textAlign}
      style={styles}
      {...rest}
    >
      <Grid.Column width={widthColumn}>{children}</Grid.Column>
    </Grid>
  );
}

Section.propTypes = {
  as: PropTypes.string,
  padding: PropTypes.bool,
  widthColumn: PropTypes.string,
  verticalAlign: PropTypes.string,
  textAlign: PropTypes.string,
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
};

Section.defaultProps = {
  as: null,
  padding: "medium",
  widthColumn: "12",
  verticalAlign: "middle",
  textAlign: "center",
  children: undefined,
  backgroundColor: null,
};
