import React, { useEffect, useRef } from "react";
import { Button, Icon, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { isEmpty, map, includes } from "lodash";

//Redux
import { connect } from "react-redux";
import { logout } from "../../redux/actions/user";

//Hooks
import { useWindowSize } from "../../hooks/useWindowSize";

//Constants
import { dashboardLinks } from "../../constants/Menu";

//Components
import Text from "../Text";

//Styles
import styles from "./styles.module.css";

const Dashboard = ({
  dispatch,
  favoritesCount,
  reportsCount,
  mitigationsCount,
  paymentsCount,
  cartCount,
}) => {
  const activeButton = useRef(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (activeButton.current) {
      activeButton.current.ref.current.style.setProperty(
        "opacity",
        "1",
        "important"
      );
    }
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  const renderLinks = (array) => {
    if (!isEmpty(array)) {
      const currentWindow = window.location.pathname;
      const items = map(dashboardLinks, (item, index) => {
        if (item.name !== "Log out") {
          const current = currentWindow === item.path;
          const withLabel = includes(
            ["Cart", "Favorites", "Reports", "Mitigations", "Payments"],
            item.name
          );
          let count = 0;
          if (withLabel) {
            switch (item.name) {
              case "Cart":
                count = cartCount;
                break;
              case "Favorites":
                count = favoritesCount;
                break;
              case "Reports":
                count = reportsCount;
                break;
              case "Mitigations":
                count = mitigationsCount;
                break;
              case "Payments":
                count = paymentsCount;
                break;
              default:
                break;
            }
          }
          return (
            <Button
              key={index}
              ref={current ? activeButton : undefined}
              as={Link}
              to={item.path}
              className={current ? styles.activeMenuItem : styles.menuItem}
              disabled={current}
              fluid
            >
              <div className={styles.item}>
                <Icon
                  className={current ? styles.activeIcon : styles.inactiveIcon}
                  name={item.icon}
                />
                <Text
                  className={
                    current ? styles.hyperlinkActive : styles.hyperlink
                  }
                >
                  {item.name}
                </Text>
              </div>
              {withLabel && (
                <Label className={styles.badge} color={"red"}>
                  {count}
                </Label>
              )}
            </Button>
          );
        } else {
          return (
            <Button
              key={index}
              className={styles.menuItemLast}
              onClick={handleLogout}
              fluid
            >
              <div className={styles.item}>
                <Icon className={styles.inactiveIcon} name={item.icon} />
                <Text className={styles.hyperlink}>{item.name}</Text>
              </div>
            </Button>
          );
        }
      });
      return items;
    } else {
      return null;
    }
  };

  if (windowSize.width <= 768) return null;

  return (
    <div className={styles.navBar}>
      <div className={styles.container}>{renderLinks(dashboardLinks)}</div>
    </div>
  );
};

function mapStateToProps({ favorites, reports, mitigations, payments, cart }) {
  return {
    favoritesCount: favorites.favoriteList.length,
    reportsCount: reports.reportList.length,
    mitigationsCount: mitigations.mitigationList.length,
    paymentsCount: payments.paymentList.length,
    cartCount: cart.cartList.length,
  };
}

export default connect(mapStateToProps)(Dashboard);
