import React from "react";
import { Container, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { map, isEmpty } from "lodash";

//Components
import Text from "../Text";

//Constants
import { footerLinks } from "../../constants/Menu";

//Styles
import styles from "./styles.module.css";

const Footer = () => {
  const renderButtons = (array) => {
    if (!isEmpty(array)) {
      const currentWindow = window.location.pathname;
      const buttons = map(array, (item, index) => {
        return (
          <Button
            key={index}
            as={Link}
            to={item.path}
            className={currentWindow === item.path ? styles.hyperlinkActive : styles.hyperlink}
            active={currentWindow === item.path}
            replace={currentWindow === item.path}>
            {item.name}
          </Button>
        );
      });
      return buttons;
    } else {
      return null;
    }
  };

  const year = new Date().getFullYear();
  return (
    <div className={styles.footer}>
      <Container className={styles.row}>
        <Text className={styles.text}>{`© Copyright @ ${year} Lien Library, Inc. All Rights Reserved.`}</Text>
        <div className={styles.hyperlinkRow}>{renderButtons(footerLinks)}</div>
      </Container>
    </div>
  );
};

export default Footer;
