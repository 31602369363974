import React from "react";
import { isEmpty, map } from "lodash";

//Redux
import { connect } from "react-redux";

//Components
import Screen from "../../components/Screen";
import PaymentsCard from "../../components/PaymentsCard";

//Styles
import styles from "./styles.module.css";

const Payments = ({ dispatch, paymentList }) => {
  const renderPayments = (array) => {
    if (!isEmpty(array)) {
      const payments = map(array, (item, index) => {
        const stripped = index % 2 === 0;
        return <PaymentsCard key={index} item={item} stripped={stripped} />;
      });
      return payments;
    } else {
      return null;
    }
  };

  return (
    <Screen searchBar fixedHeight title={"Payments"} meta="Making a lien payment has never gotten more easy in the real estate industry when you want to buy or sell a home." >
      <div className={styles.content}>{renderPayments(paymentList)}</div>
    </Screen>
  );
};

function mapStateToProps({ payments }) {
  return { paymentList: payments.paymentList };
}

export default connect(mapStateToProps)(Payments);
