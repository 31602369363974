import React, { PureComponent } from "react";
import { Button, Icon } from "semantic-ui-react";
import { isEmpty } from "lodash";

//Redux
import { connect } from "react-redux";
import { selectProperty } from "../../redux/actions/properties";
import { removeFavorite } from "../../redux/actions/favorites";

//Components
import Text from "../Text";

//Styles
import styles from "./styles.module.css";

class FavoritesCard extends PureComponent {
  handleRemove = async () => {
    const { dispatch, item } = this.props;
    await dispatch(removeFavorite(item._id));
  };

  handleRequest = async () => {
    const { dispatch, item } = this.props;
    await dispatch(selectProperty(item));
  };

  render() {
    const { item, stripped } = this.props;
    return (
      <div className={stripped ? styles.container : styles.container2}>
        <div className={styles.infoContainer}>
          <Text className={styles.text}>{item.address}</Text>
          {!isEmpty(item.folio) && (
            <Text className={styles.text}>{item.folio}</Text>
          )}
        </div>
        <div className={styles.buttonsContainer}>
          <Button className={styles.leftButton} onClick={this.handleRemove}>
            <Icon className={styles.icon} name={"heart"} />
          </Button>
          <Button className={styles.rightButton} onClick={this.handleRequest}>
            {"Request"}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect()(FavoritesCard);
