import React from "react";
import { Icon } from "semantic-ui-react";

//Components
import Text from "../Text";

//Styles
import styles from "./styles.module.css";

const Box = ({ title = "", children, first = false, withVerified = false, verified = false }) => {
  return (
    <div>
      <div className={first ? styles.firstSectionRow : styles.sectionRow}>
        <div className={styles.sectionContainer}>
          <Text className={styles.sectionText}>{title}</Text>
        </div>
        <div className={styles.statusContainer}>
          {withVerified && (
            <>
              <Icon className={styles.icon} name={verified ? "thumbs up outline" : "thumbs down outline"} size={"large"} color={verified ? "blue" : "grey"} />
              <Text className={styles.boldText}>{verified ? `Verified` : "Not Verified"}</Text>
            </>
          )}
        </div>
      </div>
      <div className={styles.contentContainer}>{children}</div>
    </div>
  );
};

export default Box;
