import React, { useRef, useState } from "react";
// import { Image, Header, Form, Button, Loader, Dimmer } from "semantic-ui-react";
import { Image, Button } from "semantic-ui-react";
import profileAvatar from "../../assets/avatar.png";

function Avatar({ name, src, onSubmit, inProgress }) {
  // const [selectedFile, setSelectedFile] = useState(null);
  const [uri, setUri] = useState(null);
  //const [dim, setDim] = useState(true);
  let reader = new FileReader();
  return (
    <>
      <Image
        src={uri ? uri : profileAvatar}
        circular
        style={{
          maxHeight: "150px",
          overflow: "hidden",
          margin: "0 1em 1em 0",
        }}
        verticalAlign="middle"
      />
      <FileInput
        size="mini"
        label="Change Picture"
        onChange={(file) => {
          if (file) {
            reader.onload = function (ev) {
              setUri(ev.target.result);
            };
            reader.readAsDataURL(file);
          }
        }}
      />

      {/* <Header
        as="h2"
        textAlign="center"
        style={{ margin: "0.9em 0", textTransform: "capitalize" }}
        inverted
      >
        {name}
      </Header> */}
      {/* 
      <FileUpload
        style={{ textAlign: "left", marginTop: "1em" }}
        onSubmit={onSubmit}
        onClose={() => setUri(null)}
        onChange={(file) => {
          if (file) {
            reader.onload = function (ev) {
              setUri(ev.target.result);
            };
            reader.readAsDataURL(file);
          }
        }}
      /> */}
    </>
  );
}

// const FileUpload = ({ style, onChange, onSubmit, onClose }) => {
//   const [selectedFile, setSelectedFile] = useState(null);
//   return (
//     <Form style={style}>
//       {!selectedFile && (
//         <FileInput
//           onFileSelectSuccess={(file) => {
//             setSelectedFile(file);
//             onChange(file);
//           }}
//           onFileSelectError={({ error }) => alert(error)}
//         />
//       )}

//       {selectedFile && (
//         <div style={{ margin: "1em 0" }}>
//           <Button
//             content={"Save"}
//             primary
//             size="mini"
//             type="submit"
//             onClick={(e) => {
//               e.preventDefault();
//               onSubmit(selectedFile);
//             }}
//           />
//           <Button
//             content={"Close"}
//             color="red"
//             onClick={(e) => {
//               e.preventDefault();
//               setSelectedFile(null);
//               onClose();
//             }}
//           />
//         </div>
//       )}
//     </Form>
//   );
// };

// const FileInputT = ({ onFileSelectSuccess, onFileSelectError }) => {
//   const fileInput = useRef(null);

//   const handleFileInput = (e) => {
//     const file = e.target.files[0];
//     console.log(file);
//     if (file.size > 5 * 1024 * 1024)
//       onFileSelectError({ error: "File size cannot exceed more than 5MB" });
//     else onFileSelectSuccess(file);
//   };

//   return (
//     <>
//       {/* <Button
//             as={"a"}
//             href={"https://forms.gle/iiJrPhM6Q34pSZdm6"}
//             target={"_blank"}

//             style={{ backgroundColor: "#c13939" }}
//           >
//             Submit Your Content
//             <Icon name="upload" className="right" />
//           </Button> */}

//       <Button
//         secondary
//         size="mini"
//         style={{
//           boxShadow: "none",
//           textAlign: "center",
//           backgroundColor: "#c13939",
//         }}
//         content={"Change Profile Picture"}
//         onClick={(e) => {
//           e.preventDefault();
//           fileInput.current && fileInput.current.click();
//         }}
//       />

//       <input ref={fileInput} type="file" hidden onChange={handleFileInput} />
//     </>
//   );
// };

const FileInput = ({ onChange, onError, label, ...rest }) => {
  const fileInput = useRef(null);

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    onChange(file);
  };

  return (
    <>
      <Button
        {...rest}
        content={label}
        onClick={(e) => {
          e.preventDefault();
          fileInput.current.click();
        }}
      />
      <input ref={fileInput} type="file" hidden onChange={handleFileInput} />
    </>
  );
};

export default Avatar;
