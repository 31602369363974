import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

//Styles
import styles from "./styles.module.css";

const Loading = ({ isFetching, message = "Loading..." }) => {
	return (
		<Dimmer page={true} active={isFetching}>
			<Loader className={styles.loading} size={"large"}>
				{message}
			</Loader>
		</Dimmer>
	);
};

export default Loading;
