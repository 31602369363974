/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { isEmpty, map } from "lodash";

//Redux
import { connect } from "react-redux";
import { getReports } from "../../redux/actions/reports";

//Components
import Screen from "../../components/Screen";
import ReportsCard from "../../components/ReportsCard";

//Styles
import styles from "./styles.module.css";

const Reports = ({ dispatch, reportList, userId }) => {
  useEffect(() => {
    dispatch(getReports(userId));
  }, []);

  const renderReports = (array) => {
    if (!isEmpty(array)) {
      const reports = map(array, (item, index) => {
        const stripped = index % 2 === 0;
        return <ReportsCard key={index} item={item} stripped={stripped} />;
      });
      return reports;
    } else {
      return null;
    }
  };

  return (
    <Screen
      searchBar
      fixedHeight
      title={"Reports"}
      meta="Getting a lien search report is not only fast but very easy to understand from the municipality">
      <div className={styles.content}>{renderReports(reportList)}</div>
    </Screen>
  );
};

function mapStateToProps({ reports, user }) {
  return { reportList: reports.reportList, userId: user.id };
}

export default connect(mapStateToProps)(Reports);
