import React, { PureComponent } from "react";
import { Container, Segment } from "semantic-ui-react";
import MessageCard from "../../components/MessageCard";

//Redux
import { connect } from "react-redux";

//Components
import Header from "../../components/Header";
// import Footer from "../../components/Footer";

//Styles
import styles from "./styles.module.css";

class Report extends PureComponent {
  render() {
    return (
      <div className={styles.screen}>
        <Header searchBar={true} />
        <div className={styles.body}>
          <Container>
            {/* <div className="largescreen widescreen computer only">
              <div className={styles.wrapper}>
                <main style={{ flex: "6" }}>
                  <Segment basic style={{ width: "95%" }}>
                    <div style={{ borderBottom: "1px solid #ddd" }}>
                      <MessageCard
                        item={{
                          type: "Message",
                          text: "Mitigation current amount: $200.00",
                        }}
                      />
                    </div>
                    <div style={{ borderBottom: "1px solid #ddd" }}>
                      <MessageCard
                        item={{
                          type: "Message",
                          text: "You have requested $250.00",
                        }}
                      />
                    </div>
                    <div style={{ borderBottom: "1px solid #ddd" }}>
                      <MessageCard
                        item={{
                          type: "Message",
                          text: "Excepteur sint occaecat cupidatat non proident",
                        }}
                      />
                    </div>
                    <div style={{ borderBottom: "1px solid #ddd" }}>
                      <MessageCard
                        item={{
                          type: "Negotiation",
                          text: "eiusmod tempor incididunt ut labore et dolore magna",
                        }}
                      />
                    </div>
                  </Segment>
                </main>

                <div style={{ flex: "2", position: "fixed" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </div>
              </div>
            </div> */}

            <div className={styles.left}>
              <Segment basic style={{ width: "95%" }}>
                <div style={{ borderBottom: "1px solid #ddd" }}>
                  <MessageCard
                    item={{
                      type: "Message",
                      text: "Mitigation current amount: $200.00",
                    }}
                  />
                </div>
                <div style={{ borderBottom: "1px solid #ddd" }}>
                  <MessageCard
                    item={{
                      type: "Message",
                      text: "You have requested $250.00",
                    }}
                  />
                </div>
                <div style={{ borderBottom: "1px solid #ddd" }}>
                  <MessageCard
                    item={{
                      type: "Message",
                      text: "Excepteur sint occaecat cupidatat non proident",
                    }}
                  />
                </div>
                <div style={{ borderBottom: "1px solid #ddd" }}>
                  <MessageCard
                    item={{
                      type: "Negotiation",
                      text: "eiusmod tempor incididunt ut labore et dolore magna",
                    }}
                  />
                </div>

                <div style={{ borderBottom: "1px solid #ddd" }}>
                  <MessageCard
                    item={{
                      type: "Message",
                      text: "Mitigation current amount: $200.00",
                    }}
                  />
                </div>
              </Segment>
            </div>
            <div className={styles.right}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
              pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </div>
          </Container>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

function mapStateToProps({ reports }) {
  return { currentReport: reports.currentReport };
}

export default connect(mapStateToProps)(Report);
