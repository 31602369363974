/* Body of the website */

// Components and Hooks
import React, { useRef, forwardRef } from "react";
import useIntersection from "../../hooks/useIntersection";
import styles from "./Body.module.css";

// Assets
import leaf1 from "../../assets/SplitLeaf.svg";
import leaf2 from "../../assets/SplitLeaf-1.svg";
import leaf3 from "../../assets/Evergreen.svg";
import leaf4 from "../../assets/Evergreen-1.svg";
import blob1 from "../../assets/BodyBlob.svg";
import blob2 from "../../assets/BodyBlob-1.svg";
import skyscraper from "../../assets/Skyscraper.png";
import house from "../../assets/House.png";
import search from "../../assets/search.svg";
import briefcase from "../../assets/case.svg";
import file from "../../assets/file.svg";
import chip from "../../assets/chip.svg";
import dollar from "../../assets/dollar.svg";

export const WebsiteBody = forwardRef((props, aboutUsRef) => {
  const ref = useRef();

  const inViewport = useIntersection(ref, "-350px");

  function renderAnimations() {
    if (inViewport) {
      return (
        <div className={styles.center}>
          <div className={styles.cardSet}>
            <div className={styles.card}>
              <img src={search} className={styles.icon} alt=""></img>
              <h3 className={styles.cardTitle}>Automate lien searches</h3>
            </div>
            <div className={styles.card}>
              <img src={briefcase} className={styles.icon} alt=""></img>
              <h3 className={styles.cardTitle}>Support lien settlement</h3>
            </div>
            <div className={styles.card}>
              <img src={file} className={styles.icon} alt=""></img>
              <h3 className={styles.cardTitle}>Generate lien release</h3>
            </div>
          </div>
          <div className={styles.cardSet}>
            <div className={styles.card}>
              <img src={chip} className={styles.icon} alt=""></img>
              <h3 className={styles.cardTitle}>Digitize lien data</h3>
            </div>
            <div className={styles.card}>
              <img src={dollar} className={styles.icon} alt=""></img>
              <h3 className={styles.cardTitle}>Support lien mitigation</h3>
            </div>
          </div>
          <div className={styles.blobSet1}>
            <img src={blob1} className={styles.blob1} alt=""></img>
            <img src={skyscraper} className={styles.skyscraper} alt=""></img>
          </div>
          <div className={styles.blobSet2}>
            <img src={blob2} className={styles.blob2} alt=""></img>
            <img src={house} className={styles.house} alt=""></img>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={styles.canvas} ref={aboutUsRef}>
      <h1 className={styles.manageLiens}>What we offer</h1>
      <img src={leaf1} className={styles.leaf1} alt="LEAF" />
      <img src={leaf2} className={styles.leaf2} alt="LEAF" />
      <img src={leaf3} className={styles.leaf3} alt="LEAF" />
      <img src={leaf4} className={styles.leaf4} alt="LEAF" />
      <div ref={ref} className={styles.animationBody}>
        {renderAnimations()}
      </div>
    </div>
  );
});

export default WebsiteBody;
