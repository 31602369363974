/* eslint-disable react-hooks/exhaustive-deps */
// React
import React, { useEffect, useState } from "react";
import { Sidebar, Segment, Icon, Button, Image } from "semantic-ui-react";
import { get, isEmpty, map, reduce } from "lodash";
import axios from "axios";

// Redux
import { connect } from "react-redux";
import { selectProperty } from "../../../redux/actions/properties";
import genericAction from "../../../redux/actions/generic";

// Components
import Text from "../../../components/Text";
import InfoBit from "../InfoBit";
import LienCard from "../LienCard";

// Utils
import { currencyFormat } from "../../../constants/Utils";
import { calculateInterest } from "../../../utils/business";
import { navigate } from "../../../utils/navigation";

import styles from "./styles.module.css";

const InfoWindow = ({ dispatch, isLoggedIn, isTaxDeed, property, onClose }) => {
  const [fullData, setFullData] = useState({});
  const [previewImg, setPreviewImg] = useState(null);
  const { address } = property;
  const partialAddress = address.split(",");
  const street = partialAddress[0];
  const city = `${partialAddress[1]}, ${partialAddress[2]}`;

  useEffect(() => {
    if (!isEmpty(property)) {
      (async () => {
        const promise = dispatch(
          genericAction("POST", "/lienByAddressProperty", {
            address: property?.address,
          })
        );
        const googlePromise = axios({
          method: "GET",
          url: "https://maps.googleapis.com/maps/api/streetview",
          params: {
            size: "267x128",
            location: property?.address,
            key: "AIzaSyD3y4gjRi9rL3JRthvlj59N0GqKUK2geT0",
          },
          responseType: "blob",
        });
        const [response, googleResponse] = await Promise.all([promise, googlePromise]);
        const calculatedAmount = reduce(
          response,
          (acum, item) => {
            return (acum += calculateInterest(item));
          },
          0
        );
        const data = {
          ...property,
          ...response[0]?.property,
          amount: calculatedAmount,
          liens: response,
        };
        const googleImg = URL.createObjectURL(googleResponse.data);
        setFullData(data);
        setPreviewImg(googleImg);
      })();
    }
  }, []);

  const handleSignUp = () => {
    navigate("/register");
  };

  const handleOrderNow = () => {
    dispatch(selectProperty(fullData));
  };

  const handleBidNow = () => {
    window.open(fullData?.linkToBid, "_blank");
  };

  const renderLienCards = (array) => {
    if (isEmpty(array)) return null;
    const lienCards = map(array, (item, index) => {
      return <LienCard key={index} lien={item} />;
    });
    return lienCards;
  };

  const renderAuctionInfo = () => {
    return (
      <>
        <InfoBit icon="hashtag" category="Tax Deed No." value={get(fullData, "taxDeedNumber", "")} />
        <InfoBit
          type="date"
          icon="calendar outline"
          category="Auction Date"
          value={get(fullData, "auctionDate", "")}
        />
        <InfoBit
          type="currency"
          icon="dollar sign"
          category="Opening Bid"
          value={get(fullData, "openingBidAmount", "")}
        />
        <InfoBit
          type="currency"
          icon="dollar sign"
          category="Assessed Value"
          value={get(fullData, "assessedValue", "")}
        />
      </>
    );
  };

  const doubleWindow = isTaxDeed || !isEmpty(fullData?.liens);
  return (
    <>
      <Sidebar
        className={`${styles.sidebar} ${doubleWindow ? styles.double : styles.single}`}
        as={Segment}
        animation="overlay"
        vertical
        visible={true}
        direction="right">
        {doubleWindow && (
          <div className={styles.leftSection}>
            <div className={styles.sectionTitle}>
              <Text className={styles.title}>{isTaxDeed ? "Auction Info" : "Liens"}</Text>
            </div>
            <div className={styles.liensContainer}>
              {isTaxDeed ? renderAuctionInfo() : renderLienCards(fullData?.liens)}
            </div>
          </div>
        )}
        <div className={styles.rightSection}>
          <div className={styles.buttonsContainer}>
            <div />
            {/* <div className={styles.row}>
              <Icon className={`${styles.icon} ${styles.heartIcon}`} name="heart outline" />
              <Text className={styles.text}>Save</Text>
            </div>
            <div className={styles.row}>
              <Icon className={styles.icon} name="bell slash outline" />
              <Text className={styles.text}>Notify Via Email</Text>
            </div> */}
            <Icon className={styles.icon} name="close" onClick={onClose} />
          </div>
          <InfoBit icon="map marker alternate" category={street} value={city} uppercase />
          <Image className={styles.preview} src={previewImg} />
          <InfoBit icon="building outline" category="County" value={get(fullData, "city.county.name", "")} />
          <InfoBit icon="folder outline" category="Folio" value={get(fullData, "folio", "")} />
          <InfoBit
            icon="file alternate outline"
            category="Description"
            value={get(fullData, "legalDescription", "")}
          />
          {!isTaxDeed && (
            <div className={styles.columnContainer}>
              <Text className={styles.amountText}>
                <span className={styles.symbol}>$</span>
                {currencyFormat(fullData?.amount, false)}
              </Text>
              <Text>{`in total recorded fine(s)`}</Text>
            </div>
          )}
          {!isLoggedIn && (
            <div className={styles.columnContainer}>
              <Text className={styles.titleText}>Want To See More?</Text>
              <Button className={styles.button} onClick={handleSignUp}>
                <Text className={styles.buttonText}>Sign Up For Free</Text>
              </Button>
            </div>
          )}
          {isLoggedIn && (
            <div className={styles.columnContainer}>
              <Text className={styles.titleText}>Order a Full Lien Report</Text>
              {!isTaxDeed && (
                <Button className={styles.button} onClick={handleOrderNow}>
                  <Text className={styles.buttonText}>Order Now</Text>
                </Button>
              )}
              {isTaxDeed && (
                <Button.Group>
                  <Button className={styles.button} onClick={handleOrderNow}>
                    <Text className={styles.buttonText}>Order Now</Text>
                  </Button>
                  <Button.Or />
                  <Button className={styles.button} onClick={handleBidNow}>
                    <Text className={styles.buttonText}>Bid Now</Text>
                  </Button>
                </Button.Group>
              )}
            </div>
          )}
        </div>
      </Sidebar>
    </>
  );
};

function mapStateToProps({ user }) {
  return {
    isLoggedIn: user.isLoggedIn,
  };
}

export default connect(mapStateToProps)(InfoWindow);
