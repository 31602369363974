import React from "react";
import { isEmpty } from "lodash";

//Redux
import { connect } from "react-redux";
import { selectProperty } from "../../redux/actions/properties";

//Components
import Text from "../Text";

//Styles
import styles from "./styles.module.css";

const PropertyCard = ({ dispatch, item }) => {
  const handleClick = async (e) => {
    e.preventDefault();
    await dispatch(selectProperty(item));
  };

  const { address, folio } = item;
  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={isEmpty(folio) ? styles.black : styles.black2}>
        {!isEmpty(folio) && <Text className={styles.text}>{folio}</Text>}
        <Text className={styles.text}>{address}</Text>
      </div>
    </div>
  );
};

export default connect()(PropertyCard);
