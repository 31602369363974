import { SEARCH, SELECT_PROPERTY, CHECK_SESSION } from "../actions/types";

export default function properties(
  state = { propertyList: [], currentProperty: {} },
  action
) {
  const { type, payload } = action;
  switch (type) {
    case SEARCH:
      return { ...state, propertyList: payload.data };

    case SELECT_PROPERTY:
      return { ...state, currentProperty: payload.data };

    case CHECK_SESSION:
      return {
        ...state,
        propertyList: payload.data.properties.propertyList,
        currentProperty: payload.data.properties.currentProperty,
      };

    default:
      return state;
  }
}
