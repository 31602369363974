import io from "socket.io-client";
import config from "./config";

//Redux
import { updateReport } from "./redux/actions/reports";
import { getMessage, completeNegotiationSocket } from "./redux/actions/mitigations";

let socket = null;

export function socketInit(userId = 0, dispatch) {
  socket = io.connect(config.baseURL, {
    jsonp: false,
    secure: true,
    transports: ["websocket"],
    query: {
      userId,
      platform: "web",
    },
  });

  socket.on("connect", () => {
    console.log("Connected To Socket");
  });

  socket.on("updateReport", (report) => {
    //console.log("updateReport", report);
    dispatch(updateReport(report));
  });

  socket.on("createReport", (report) => {
    //dispatch(updateReport(report));
  });

  socket.on("mitigationMessage", (data) => {
    dispatch(getMessage(data));
  });

  socket.on("completeNegotiation", (data) => {
    dispatch(completeNegotiationSocket(data));
  });
}

export function socketClose() {
  if (socket) {
    socket.close();
  }
}
