import React, { useState } from "react";
import { Container, Grid } from "semantic-ui-react";
import { Message } from "semantic-ui-react";
import Api from "../../api";
import * as Yup from "yup";
import Text from "../../components/Text";
import Form from "../../components/Form";

//Redux
import { connect } from "react-redux";
import { resetPassword } from "../../redux/actions/user";

//Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";

//Styles
import styles from "./styles.module.css";

function ForgotPassword({ dispatch }) {
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("interns@lienlibrary.com");
  const [codeValid, setCodeValid] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  function handleEmailVerification({ email }) {
    dispatch(resetPassword(email)).then((emailVerified) => {
      setSuccess(emailVerified);
      setEmail(email);
    });
  }

  function handleCodeVerification({ code }) {
    console.log(code);
    Api.post("/verifyCode", { email, code })
      .then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          setCodeValid(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handlePasswordUpdate({ password }) {
    Api.post("/resetPassword", { email, password })
      .then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          setPasswordUpdated(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className={styles.screen}>
      <Header />
      <div className={styles.body}>
        <div className={styles.black}>
          <Container className={styles.content}>
            <Grid className={styles.gridContainer} textAlign="center">
              <Grid.Column style={{ maxWidth: 450 }}>
                {!success && <EmailInput onSubmit={handleEmailVerification} />}
                {success && !codeValid && <CodeInput onSubmit={handleCodeVerification} />}
                {success && codeValid && (
                  <UpdatePasswordInput onSubmit={handlePasswordUpdate} success={passwordUpdated} disabled={passwordUpdated} />
                )}
              </Grid.Column>
            </Grid>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const EmailInput = ({ onSubmit }) => (
  <div className={styles.loginContainer}>
    <Text className={styles.headline} as={"h2"}>
      Forgot Password ?
    </Text>
    <Text className={null} as={"p"}>
      Please enter your email address and we'll send you a link to reset your password
    </Text>

    <Form
      fields={[
        {
          icon: "mail",
          placeholder: "Email Address",
          type: "input",
          inputType: "email",
          name: "email",
        },
      ]}
      schema={Yup.object().shape({
        email: Yup.string().required().email("must be a valid email"),
      })}
      onSubmit={onSubmit}
    />
  </div>
);

const CodeInput = ({ onSubmit }) => (
  <div className={styles.loginContainer}>
    <Text className={styles.headline} as={"h2"}>
      Code Verification
    </Text>
    <Text className={null} as={"p"}>
      A 6 digit code has been sent to your email, enter the code below to complete the verification
    </Text>

    <Form
      fields={[
        {
          icon: "lock",
          placeholder: "6 Digits Code",
          type: "input",
          name: "code",
        },
      ]}
      schema={Yup.object().shape({
        code: Yup.string().required("A 6 digits code is required"),
      })}
      onSubmit={onSubmit}
    />
  </div>
);

const UpdatePasswordInput = ({ onSubmit, success }) => (
  <div className={styles.loginContainer}>
    <Text className={styles.headline} as={"h2"}>
      Reset Your Password
    </Text>

    <Form
      fields={[
        {
          icon: "lock",
          placeholder: "New Password",
          type: "input",
          name: "password",
          inputType: "password",
        },
        {
          icon: "lock",
          placeholder: "Confirm Password",
          type: "input",
          name: "retypePassword",
          inputType: "password",
        },
      ]}
      schema={Yup.object().shape({
        password: Yup.string().min(6).required("Password is required"),
        retypePassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
      })}
      onSubmit={onSubmit}
      disabled={success}
    />
    {success && <Message success header="Your Password Has Been Updated Successfully" content="" />}
  </div>
);

export default connect()(ForgotPassword);
