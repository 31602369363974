import React from "react";
import { Container } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { Helmet } from "react-helmet";

//Hooks
import { useWindowSize } from "../../hooks/useWindowSize";

//Components
import Header from "../Header";
import Footer from "../Footer";
import Text from "../Text";

//Styles
import styles from "./styles.module.css";

const Screen = ({
  children,
  withBackground = false,
  searchBar = false,
  flexRow = false,
  fixedHeight = false,
  noContainer = false,
  title = "",
  meta = "",
}) => {
  const windowSize = useWindowSize();
  const footerSize = windowSize.width <= 768 ? 6 : 3;
  const headerSize = searchBar ? 9.5 : 6;

  const renderContent = (children, withBackground, flexRow, fixedHeight, title) => {
    if (withBackground) {
      return (
        <div
          style={{
            height: fixedHeight ? `calc(100vh - ${headerSize + footerSize}rem - 2px)` : "auto",
          }}
          className={styles.bodyWithBG}>
          <div className={styles.black}>
            {noContainer && (
              <div className={styles.noContainer}>
                {!isEmpty(title) && <Text className={styles.title}>{title}</Text>}
                {renderChildren(fixedHeight, flexRow, title, children)}
              </div>
            )}
            {!noContainer && (
              <Container className={styles.container}>
                {!isEmpty(title) && <Text className={styles.title}>{title}</Text>}
                {renderChildren(fixedHeight, flexRow, title, children)}
              </Container>
            )}
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          height: fixedHeight ? `calc(100vh - ${headerSize + footerSize}rem - 2px)` : "auto",
        }}
        className={styles.body}>
        {noContainer && (
          <div className={styles.noContainer}>
            {!isEmpty(title) && <Text className={styles.title}>{title}</Text>}
            {renderChildren(fixedHeight, flexRow, title, children)}
          </div>
        )}
        {!noContainer && (
          <Container className={styles.container}>
            {!isEmpty(title) && <Text className={styles.title}>{title}</Text>}
            {renderChildren(fixedHeight, flexRow, title, children)}
          </Container>
        )}
      </div>
    );
  };

  const renderChildren = (fixedHeight, flexRow, title, children) => {
    if (fixedHeight) {
      return (
        <div
          className={flexRow ? styles.rowContent : styles.columnContent}
          style={{ height: isEmpty(title) ? "100%" : "calc(100% - 3.5rem" }}>
          {children}
        </div>
      );
    }
    return children;
  };

  return (
    <div className={styles.screen}>
      <Helmet>
        <title>{`Lien Library ${!isEmpty(title) ? `: ${title}` : ""}`}</title>
        <meta name={"description"} content={meta} />
      </Helmet>
      <Header searchBar={searchBar} />
      {renderContent(children, withBackground, flexRow, fixedHeight, title)}
      <Footer />
    </div>
  );
};

export default Screen;
