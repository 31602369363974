import React, { PureComponent } from "react";
import { Icon, Grid } from "semantic-ui-react";
import PropTypes from "prop-types";
import { isEmpty, map, includes, get } from "lodash";
import moment from "moment";

//Components
import Text from "../Text";

//Constants
import { currencyFormat, getDateLabel, dateDiff } from "../../constants/Utils";

//Styles
import styles from "./styles.module.css";

/**
 * ReportBox Component
 * @augments {PureComponent<Props>}
 */
class ReportBox extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    first: PropTypes.bool.isRequired,
    canMitigate: PropTypes.bool,
    isGoodStanding: PropTypes.bool.isRequired,
    exclude: PropTypes.bool.isRequired,
    items: PropTypes.array,
    asOf: PropTypes.string,
  };

  renderRows = (items, title, asOf, exclude) => {
    if (exclude && isEmpty(items)) {
      return (
        <div className={styles.contentContainer}>
          <Text className={styles.emptyText}>{`We do not provide this service.`}</Text>
        </div>
      );
    }

    if (isEmpty(items)) {
      return (
        <div className={styles.contentContainer}>
          <Text className={styles.emptyText}>{`No ${title} found as of ${getDateLabel(asOf ? asOf : moment())}`}</Text>
        </div>
      );
    }

    const isPermit = includes(title, "Permit");
    const rows = map(items, (item, index) => {
      const { canMitigate } = this.props;
      const hasComplied = item.dateComplied ? true : false;
      const endDate = hasComplied ? moment(item.dateComplied) : moment();
      const diffDays = dateDiff(moment(item.dateOfFineBegan), endDate) + 1;
      // const payOffDays = dateDiff(moment(item.dateRecorded), moment(item.payoffDate));
      const dailyFine = parseFloat(item.originalFine) * diffDays;
      // const interest = payOffDays * ((dailyFine * 0.12) / 365);
      const total =
        parseFloat(item.courtFee) +
        parseFloat(item.recordingFee) +
        parseFloat(item.adminFee) +
        parseFloat(item.releaseOfLienFee) +
        dailyFine;
      return (
        <div key={index} className={styles.contentContainer}>
          <Grid stackable>
            <Grid.Row className={styles.gridRow} columns={3}>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{`${isPermit ? "Permit" : "Case"} #: `}</Text>
                  <Text className={styles.text}>{item.caseNumber}</Text>
                </div>
              </Grid.Column>
              {(canMitigate || title !== "Code Lien") && (
                <Grid.Column>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Original Fine: "}</Text>
                    <Text className={styles.text}>{currencyFormat(parseFloat(item.originalFine))}</Text>
                  </div>
                </Grid.Column>
              )}
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Date of Violation: "}</Text>
                  <Text className={styles.text}>{getDateLabel(item.dateOfViolation)}</Text>
                </div>
              </Grid.Column>
              {!canMitigate && title === "Code Lien" && (
                <Grid.Column>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Date of Hearing: "}</Text>
                    <Text className={styles.text}>{getDateLabel(item.dateOfHearing)}</Text>
                  </div>
                </Grid.Column>
              )}
            </Grid.Row>
            {(canMitigate || title !== "Code Lien") && (
              <>
                {!isEmpty(item.dateComplied) && (
                  <Grid.Row className={styles.gridRow} columns={3}>
                    <Grid.Column>
                      <div className={styles.textRow}>
                        <Text className={styles.boldText}>{"Date Fine Began: "}</Text>
                        <Text className={styles.text}>{getDateLabel(item.dateOfFineBegan)}</Text>
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <div className={styles.textRow}>
                        <Text className={styles.boldText}>{"Daily Fine Amount: "}</Text>
                        <Text className={styles.text}>{currencyFormat(dailyFine)}</Text>
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <div className={styles.textRow}>
                        <Text className={styles.boldText}>{"Total Due: "}</Text>
                        <Text className={styles.text}>{currencyFormat(total)}</Text>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                )}
                <Grid.Row className={styles.gridRow} columns={isPermit ? 4 : 3}>
                  {isPermit && (
                    <Grid.Column width={isPermit ? 4 : undefined}>
                      <div className={styles.textRow}>
                        <Text className={styles.boldText}>{"Status: "}</Text>
                        <Text className={styles.text}>{get(item, "permitStatus", "")}</Text>
                      </div>
                    </Grid.Column>
                  )}
                  <Grid.Column width={isPermit ? 6 : undefined}>
                    <div className={styles.textRow}>
                      <Text className={styles.boldText}>{"Date Complied: "}</Text>
                      <Text className={styles.text}>{getDateLabel(item.dateComplied)}</Text>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={isPermit ? 3 : undefined}>
                    <div className={styles.textRow}>
                      <Text className={styles.boldText}>{"Book: "}</Text>
                      <Text className={styles.text}>{item.book}</Text>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={isPermit ? 3 : undefined}>
                    <div className={styles.textRow}>
                      <Text className={styles.boldText}>{"Page: "}</Text>
                      <Text className={styles.text}>{item.page}</Text>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
            <Grid.Row className={styles.gridRow} columns={1}>
              <Grid.Column>
                <div className={styles.textRow}>
                  <Text className={styles.boldText}>{"Description: "}</Text>
                  <Text className={styles.text}>{item.description}</Text>
                </div>
              </Grid.Column>
            </Grid.Row>
            {!isEmpty(item.note) && (
              <Grid.Row className={styles.gridRow} columns={1}>
                <Grid.Column>
                  <div className={styles.textRow}>
                    <Text className={styles.boldText}>{"Note: "}</Text>
                    <Text className={styles.text}>{item.note}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}
            {!isEmpty(item.attachments) && (
              <Grid.Row className={styles.gridRow} columns={1}>
                <Grid.Column>
                  <div className={styles.textRow}>
                    <Text className={styles.text}>{"This Lien has attachments below"}</Text>
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </div>
      );
    });
    return rows;
  };

  render() {
    const { title, first, isGoodStanding, items, asOf, exclude } = this.props;
    return (
      <div className={first ? styles.firstContainer : styles.container}>
        <div className={styles.row}>
          <div className={styles.titleContainer}>
            <Text className={styles.titleText}>{title}</Text>
          </div>
          <div className={styles.statusContainer}>
            <Icon
              className={styles.icon}
              name={isGoodStanding ? "check circle" : "warning sign"}
              size={"large"}
              color={isGoodStanding ? "green" : "red"}
            />
            <Text className={styles.boldText}>{isGoodStanding ? "Good Standing" : `${title} Found`}</Text>
          </div>
        </div>
        {this.renderRows(items, title, asOf, exclude)}
      </div>
    );
  }
}

export default ReportBox;
