import Api from "../../api";
import { apiStart, apiEnd, apiError, requestSuccess, requestFail } from "./api";
import {
  START_ORDER,
  CANCEL_ORDER,
  SUBMIT_ORDER,
  SELECT_REPORT,
  UPDATE_REPORT,
  GET_REPORTS,
} from "./types";
import { navigate } from "../../utils/navigation";

export function startOrder(property, liens, requestPeriod) {
  return (dispatch) => {
    const order = { property, liens, requestPeriod };
    dispatch(requestSuccess(START_ORDER, order));
  };
}

export function cancelOrder() {
  return (dispatch) => {
    dispatch(requestSuccess(CANCEL_ORDER));
  };
}

export function updateReport(report) {
  return (dispatch) => {
    dispatch(requestSuccess(UPDATE_REPORT, report));
  };
}

export function selectReport(report) {
  return (dispatch) => {
    dispatch(requestSuccess(SELECT_REPORT, report));
    navigate(`/reports/${report.id}`);
  };
}

// export function submitOrder(order) {
//   return (dispatch) => {
//     dispatch(apiStart());
//     Api.post("/reportCity", order)
//       .then((response) => {
//         if (response.data.success) {
//           console.log(response.data);
//           dispatch(requestSuccess(SUBMIT_ORDER, response.data.data));
//           dispatch(apiEnd());
//           navigate("/reports");
//         } else {
//           dispatch(apiEnd());
//           dispatch(requestFail(response.data.message));
//         }
//       })
//       .catch((error) => {
//         dispatch(apiError(error.message));
//         dispatch(apiEnd());
//       });
//   };
// }

//Full Paypal
// export function submitOrder(order, description, amount, type) {
//   return (dispatch) => {
//     dispatch(apiStart());
//     Api.post("/paymentLiens", {
//       obj: order,
//       description,
//       amount: total,
//       type,
//     })
//       .then((response) => {
//         if (response.data.success) {
//           window.open(response.data.data, "_blank");
//           //dispatch(requestSuccess(SUBMIT_ORDER, response.data.data));
//           dispatch(apiEnd());
//           // navigate("/reports");
//         } else {
//           dispatch(apiEnd());
//           dispatch(requestFail(response.data.message));
//         }
//       })
//       .catch((error) => {
//         dispatch(apiError(error.message));
//         dispatch(apiEnd());
//       });
//   };
// }

export function submitOrder(payload) {
  return (dispatch) => {
    dispatch(apiStart());
    Api.post("/userPayments", payload)
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(SUBMIT_ORDER, response.data.data));
          dispatch(apiEnd());
          navigate("/reports");
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message));
        }
      })
      .catch((error) => {
        dispatch(apiError(error.message));
        dispatch(apiEnd());
      });
  };
}

export function payReport(data, order) {
  return (dispatch) => {
    dispatch(apiStart());
    return Api.post("/paymentLiens", {})
      .then((response) => {
        if (response.data.success) {
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
        }
      })
      .catch((error) => {
        dispatch(apiEnd());
        dispatch(apiError(error));
      });
  };
}

export function getReports(userId) {
  return (dispatch) => {
    dispatch(apiStart());
    Api.get(`/getAllUserReports/${userId}`)
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(GET_REPORTS, response.data.data));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
        }
      })
      .catch((error) => {
        dispatch(apiEnd());
        dispatch(apiError(error));
      });
  };
}
