import React from "react";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import Text from "../../components/Text";

//Styles
import styles from "./styles.module.css";
import { Link } from "react-router-dom";

function Checkbox({ field, checked, label }) {
  return (
    <div className="ui checkbox">
      <input type="checkbox" {...field} />
      <label>{label}</label>
    </div>
  );
}

const LoginForm = ({ onSubmit, initialValues }) => {
  const initFormValues = initialValues ? initialValues : LoginFormConfig.initialValues;
  return (
    <>
      <div className={styles.loginContainer}>
        <Text className={styles.headline} as={"h2"}>
          Login
        </Text>

        <Formik initialValues={initFormValues} validationSchema={LoginFormConfig.schema} onSubmit={onSubmit} validateOnChange={false} validateOnBlur={false}>
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form size="large" onSubmit={handleSubmit}>
              <Segment className={styles.segment}>
                {LoginFormConfig.fields.map((field, index) => (
                  <Form.Input
                    fluid
                    key={index}
                    icon={field.icon}
                    onChange={handleChange(field.name)}
                    value={values[field.name]}
                    iconPosition="left"
                    placeholder={field.placeholder}
                    type={field.type}
                    error={errors[field.name]}
                  />
                ))}

                <div style={{ margin: "1.2em 0" }}>
                  <Field name="rememberMe" label="Remember Me" type="checkbox" checked={values.rememberMe} component={Checkbox} />
                </div>

                <div>
                  <Grid className={styles.bottomContainer}>
                    <Grid.Column floated="left" width={4}>
                      <Button className={styles.button} type="submit">
                        Submit
                      </Button>
                    </Grid.Column>
                    <Grid.Column floated="right" width={8}>
                      <Link to={"/forgot-password"}>Forgot Password</Link>
                    </Grid.Column>
                  </Grid>
                </div>
              </Segment>
            </Form>
          )}
        </Formik>
      </div>
      <Text as="h3" className={styles.signUpLabel}>
        New to us?&nbsp;<Link to={"/register"}> Sign Up!</Link>
      </Text>
    </>
  );
};

const LoginFormConfig = {
  initialValues: {
    email: "",
    password: "",
    rememberMe: false,
  },
  fields: [
    {
      icon: "mail",
      placeholder: "Email Address",
      type: "email",
      name: "email",
    },
    {
      icon: "lock",
      placeholder: "Password",
      type: "password",
      name: "password",
    },
  ],
  schema: Yup.object().shape({
    email: Yup.string().required().email("must be a valid email"),
    password: Yup.string().required().min(4),
  }),
};

export default LoginForm;
