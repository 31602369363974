import {
  LOGIN,
  CHECK_SESSION,
  LOGOUT,
  SUBMIT_ORDER,
  SELECT_REPORT,
  UPDATE_REPORT,
  REHYDRATE,
  GET_REPORTS,
} from "../actions/types";
import { orderBy } from "lodash";

export default function reducer(state = { reportList: [], currentReport: {} }, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN: {
      const reportList = orderBy(payload.data.user.reports, ["createdAt"], "desc");
      return { ...state, reportList };
    }

    case REHYDRATE: {
      const reportList = orderBy(payload.data.user.reports, ["createdAt"], "desc");
      return { ...state, reportList };
    }

    case CHECK_SESSION:
      return {
        ...state,
        reportList: payload.data.reports.reportList,
        currentReport: payload.data.reports.currentReport,
      };

    case LOGOUT:
      return { ...state, reportList: [], currentReport: {} };

    case GET_REPORTS: {
      const reportList = orderBy(payload.data, ["createdAt"], "desc");
      return { ...state, reportList };
    }

    case SUBMIT_ORDER: {
      const reportList = orderBy([...state.reportList, payload.data], ["createdAt"], "desc");
      return {
        ...state,
        reportList,
      };
    }
    case SELECT_REPORT:
      return { ...state, currentReport: payload.data };

    case UPDATE_REPORT: {
      const array = state.reportList.map((item) => {
        if (item.id !== payload.data.id) {
          return item;
        }
        return { ...item, ...payload.data };
      });
      const reportList = orderBy(array, ["createdAt"], "desc");
      return { ...state, reportList };
    }

    default:
      return state;
  }
}
