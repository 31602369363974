// Api
export const API_START = "API_START";
export const API_END = "API_END";
export const API_ERROR = "API_ERROR";
export const API_FAILURE = "API_FAILURE";

// Error Dialog
export const DISMISS_ERROR = "DISMISS_ERROR";

// User
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const CHECK_SESSION = "CHECK_SESSION";
export const REHYDRATE = "REHYDRATE";
export const SUBSCRIBE = "SUBSCRIBE";
export const PAY_LIEN = "PAY_LIEN";
export const SET_PROFILE_PIC = "SET_PROFILE_PIC";

// Favorites
export const ADD_FAVORITE = "ADD_FAVORITE";
export const REMOVE_FAVORITE = "REMOVE_FAVORITE";

// Cart
export const ADD_CART = "ADD_CART";
export const PAY_CART = "PAY_CART";
export const REMOVE_CART = "REMOVE_CART";

// Properties
export const SEARCH = "SEARCH";
export const SELECT_PROPERTY = "SELECT_PROPERTY";

// Reports
export const START_ORDER = "START_ORDER";
export const SUBMIT_ORDER = "SUBMIT_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const SELECT_REPORT = "SELECT_REPORT";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const GET_REPORTS = "GET_REPORTS";

// LienTypes
export const GET_TYPES = "GET_TYPES";

// Mitigation
export const SELECT_MITIGATION = "SELECT_MITIGATION";
export const CLEAR_MITIGATION = "CLEAR_MITIGATION";
export const START_MITIGATION = "START_MITIGATION";
export const SEND_MESSAGE_LOCAL = "SEND_MESSAGE_LOCAL";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const GET_MESSAGE = "GET_MESSAGE";
export const ACCEPT_NEGOTIATION = "ACCEPT_NEGOTIATION";
export const CREATE_MITIGATION = "CREATE_MITIGATION";
export const GET_MITIGATIONS = "GET_MITIGATIONS";

// Settings
export const EDIT_PROFILE = "EDIT_PROFILE";

// Payments
export const SELECT_PAYMENT = "SELECT_PAYMENT";

// Alarms
export const GET_ALARMS = "GET_ALARMS";
export const SUBMIT_ALARM = "SUBMIT_ALARM";
export const SELECT_ALARM = "SELECT_ALARM";
