import React from "react";
import { get } from "lodash";

//Redux
import { connect } from "react-redux";
import { selectReport } from "../../redux/actions/reports";

//Components
import Text from "../Text";
import StatusButton from "../StatusButton";

//Constants
import { getTimeLabel } from "../../constants/Utils";

//Styles
import styles from "./styles.module.css";

const ReportsCard = ({ dispatch, item, stripped }) => {
  const disabled = item.status !== "Ready";

  const handleReport = async () => {
    await dispatch(selectReport(item));
  };

  return (
    <div className={stripped ? styles.container : styles.container2}>
      <div className={styles.row}>
        <div className={styles.infoContainer}>
          <Text className={styles.text}>{get(item, "property.address", "")}</Text>
          <Text className={styles.text}>{get(item, "property.folio", "")}</Text>
        </div>
        <div className={styles.infoContainer}>
          <Text className={styles.timeText}>{getTimeLabel(item.endedDate ? item.endedDate : item.createdAt)}</Text>
        </div>
      </div>

      <div className={styles.buttonsContainer}>
        <StatusButton disabled={disabled} status={item.status} handleClick={handleReport} />
      </div>
    </div>
  );
};

export default connect()(ReportsCard);
