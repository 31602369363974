import React from "react";
import { Container, Grid, Image, Icon, Header as SemanticHeader } from "semantic-ui-react";

//Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TeamMemberCard from "../../components/TeamMemberCard";

//Images
import Logo from "../../assets/logo2.png";

//Styles
import styles from "./styles.module.css";
import Section from "../../components/Section/Section";
import { Teams } from "./AboutUs.constants";

import fiuLogo from "../../assets/community/fiu.png";
import endeavorLogo from "../../assets/community/endeavor.png";
import miamiLogo from "../../assets/community/miami.jpg";
import vmtLogo from "../../assets/community/vmt.png";

function AboutUs() {
  return (
    <div>
      <Header searchBar={true} />

      <div className={styles.section1}>
        <div className={styles.black}>
          <Container className={styles.content}>
            <Image src={Logo} centered style={{ width: "180px" }} />
            <SemanticHeader as="h1" inverted>
              YOU CAN LIEN ON US
            </SemanticHeader>
          </Container>
        </div>
      </div>

      <Section widthColumn="10" backgroundColor="#212121">
        <div>
          {/* <SemanticHeader
            as="h1"
            inverted
            style={{
              fontSize: "2.5em",
              color: "#FFF",
            }}
          >
            OUR STORY
          </SemanticHeader> */}
          <SemanticHeader
            as="h2"
            inverted
            icon
            style={{
              fontSize: "1.75em",
            }}>
            <Icon name="group" style={{ color: "var(--primary)" }} />
            OUR STORY
          </SemanticHeader>
          <p
            style={{
              fontFamily: "Lato",
              fontSize: "1.75em",
              color: "#E0E0E0 ",
              lineHeight: "30px",
              letterSpacing: "1.5px",
            }}>
            We are a diverse group of motivated problem-solvers with backgrounds in local government, business, real estate, and information technology who are committed to improving the process that local governments, property owners, title companies, and others go through to provide and obtain property lien information.  Since our days together at Florida International University (FIU), where the idea for Lien Library was first conceived, we have worked hard to create a great company that provides exceptional services to our customers.
          </p>
        </div>
      </Section>

      <Section widthColumn="16" backgroundColor="#fff">
        <Container>
          {/* <SemanticHeader as="h1" icon>
            <Icon name="group" style={{ color: "var(--primary)" }} />
            OUR TEAM
          </SemanticHeader> */}
          <SemanticHeader
            as="h2"
            icon
            style={{
              fontSize: "1.75em",
            }}>
            <Icon name="heart" style={{ color: "var(--primary)" }} />
            OUR TEAM
          </SemanticHeader>
          <Grid columns={4} stackable stretched relaxed padded centered>
            {Teams.map((member, index) => (
              <Grid.Column large="4" computer="4" mobile="8" tablet="5" key={index} textAlign="center">
                <TeamMemberCard basic color="red" raised fullname={member.fullname} title={member.title} profile={member.profile} />
              </Grid.Column>
            ))}
          </Grid>
        </Container>
      </Section>

      <Grid columns={2} padded="vertically" stackable textAlign="center" style={{ backgroundColor: "#FAFAFA" }}>
        <Grid.Row>
          <Grid.Column className={styles.col}>
            <SemanticHeader
              as="h2"
              icon
              style={{
                fontSize: "1.75em",
              }}>
              <Icon name="eye" style={{ color: "var(--primary)" }} />
              OUR VISION
            </SemanticHeader>
            <p
              style={{
                fontSize: "1.3em",
                lineHeight: "30px",
                letterSpacing: "1.5px",
              }}>
              We envision a world where obtaining property debt or lien information before buying or selling a house is as easy as getting an email or a cup of coffee.
              A property lien is an important community tool to ensure citizens honor their community commitments for the benefit of everyone.  The process for a property owner, business person, or local government to obtain a lien report and/or settle an outstanding lien should not be frustrating or time-consuming; rather, it should be quick, easy, and easily accessible.
            </p>
          </Grid.Column>
          <Grid.Column className={styles.col}>
            <div/>
            <SemanticHeader
              as="h2"
              icon
              style={{
                fontSize: "1.75em",
              }}>
              <Icon name="rocket" style={{ color: "var(--primary)" }} />
              OUR MISSION
            </SemanticHeader>
            <p
              style={{
                fontSize: "1.3em",
                lineHeight: "30px",
                letterSpacing: "1.5px",
              }}>
             <p>
             Our mission is to provide an intuitive, accessible, and user-friendly interface that streamlines a user’s lien search and settlement experience using the most advanced technology.
             </p>
             <p>
             We continually strive to improve the user experience by building and constantly monitoring a robust software platform and by adding new functionality to address our customers' needs.
             </p>
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Section widthColumn="16" backgroundColor="#fff">
        <SemanticHeader
          as="h2"
          icon
          style={{
            fontSize: "1.75em",
          }}>
          <Icon name="world" style={{ color: "var(--primary)" }} />
          COMMUNITY &amp; NEWS
        </SemanticHeader>

        <Grid stackable verticalAlign="middle" textAlign="center" centered columns="4">
          <Grid.Column>
            <Image centered src={fiuLogo} size="small" />
          </Grid.Column>
          <Grid.Column>
            <Image centered src={endeavorLogo} size="small" />
          </Grid.Column>
          <Grid.Column>
            <Image centered src={miamiLogo} size="small" />
          </Grid.Column>
          <Grid.Column>
            <Image centered src={vmtLogo} size="small" />
          </Grid.Column>
        </Grid>
      </Section>

      <Footer />
    </div>
  );
}

export default AboutUs;
